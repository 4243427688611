import { useEffect } from "react";

export const use2WayBinding = <TValue = unknown>(
    value1: TValue,
    setValue1: (val: TValue) => void,
    value2: TValue,
    setValue2: (val: TValue) => void
) => {
    useEffect(() => {
        if (String(value1) !== String(value2)) {
            setValue2(value1);
        }
    }, [value1]);
    useEffect(() => {
        if (String(value1) !== String(value2)) {
            setValue1(value2);
        }
    }, [value2]);
};
