import { BadgeGreen, CellIdentity, DumbTable, TableFlexCell, TableFlexRow } from "feature/table";
import { EventContext } from "hooks/useEventEmitter";
import { CSSProperties, useContext, useMemo } from "react";
import { NavLink } from "react-router-dom";
import {
    useCounterpartyLimitsSnapshot,
    useHasMakerRoleUser,
    useUserType,
} from "store/hooks";
import { Book, CounterpartyLimitIndex } from "types";
import { formatValue } from "utils/EfxTypes";
import { kFeedNotAvailable } from "utils/ErrorCodes";
import { EMPTY_BOOK } from "./consts";
import { BannerContent, MarketDepthContainer } from "./style";
import { columnsInstrumentBook } from "./table";
import { getWidths, mapper } from "./utils";

export const MarketDepth = ({
    selectedInstrument,
    selectedBook,
}: {
    selectedInstrument?: string;
    selectedBook: {
        data: Book | null;
        isLoading: boolean;
        errorCode: number | undefined;
    };
}) => {
    const { emit } = useContext(EventContext);
    const base = useMemo(() => selectedInstrument?.split("-")[0], [selectedInstrument]);

    const columns = useMemo(() => {
        return columnsInstrumentBook.map((column) => {
            const { minWidth } = column;
            const cellStyle: CSSProperties = {
                boxSizing: "border-box",
                flex: `${minWidth} 0 auto`,
                minWidth: minWidth,
                width: minWidth,
            };
            return { ...column, cellStyle };
        });
    }, [columnsInstrumentBook]);
    const { data: counterpartyLimits } = useCounterpartyLimitsSnapshot();
    const counterPartyLimit = useMemo(
        () => counterpartyLimits.find(([, asset]) => asset === base),
        [counterpartyLimits],
    );
    const hasMakerRoleUser = useHasMakerRoleUser();

    const preparedData = useMemo(() => {
        const data = mapper(selectedBook.data ?? EMPTY_BOOK);
        const askWidths = getWidths(data, "askSize");
        const bidWidths = getWidths(data, "bidSize");
        return data.map((item, itemIndex) => ({
            ...item,
            style: {
                "--bid-percent": askWidths[itemIndex],
                "--ask-percent": bidWidths[itemIndex],
            } as CSSProperties,
            onRowClick: (e: React.MouseEvent) => {
                let price;
                e.currentTarget.childNodes.forEach((node, nodeIndex) => {
                    if (node.contains(e.target as Node)) {
                        const { bidPrice, askPrice } = item;
                        price = formatValue(nodeIndex < 2 ? bidPrice : askPrice, "price", true);
                    }
                });
                if (price) {
                    emit?.({
                        instrument: selectedInstrument,
                        type: "setPrice",
                        value: price,
                    });
                }
            },
        }));
    }, [selectedBook]);
    const headers = useMemo(
        () =>
            columnsInstrumentBook.map(({ Header, minWidth, id }) => (
                <TableFlexCell
                    key={id}
                    style={{
                        boxSizing: "border-box",
                        flex: `${minWidth} 0 auto`,
                        width: minWidth,
                        minWidth,
                    }}
                >
                    {Header}
                </TableFlexCell>
            )),
        [],
    );
    const effectiveWidth = useMemo(() => {
        return columnsInstrumentBook.reduce((acc, item) => {
            if (item.minWidth) return acc + item.minWidth;
            return acc;
        }, 0);
    }, [columnsInstrumentBook]);
    const userType = useUserType();
    const emptyMessage = useMemo(() => {
        if (selectedBook.isLoading) {
            return <BannerContent>Loading...</BannerContent>;
        }
        if (selectedBook.errorCode === kFeedNotAvailable) {
            return <BannerContent>Instrument is not whitelisted</BannerContent>;
        }
        if (selectedBook.data === null) {
            return;
        }
        if (selectedBook.data[0]?.length > 0 || selectedBook.data[1]?.length > 0) {
            return undefined;
        }

        if (!selectedInstrument) {
            return (
                <BannerContent>
                    To see the quotes you need
                    <br />
                    to select an instrument.
                </BannerContent>
            );
        }

        if (!counterPartyLimit) {
            switch (userType) {
                case "subaccountTaker":
                    return (
                        <BannerContent>
                            To see the quotes ensure that your Master set CP limits
                            <br />
                            in Risk Management and streams {selectedInstrument} quotes.
                        </BannerContent>
                    );
                default:
                    return (
                        <BannerContent>
                            To see the quotes set CP limits
                            <br />
                            in <NavLink to="/riskmanagement">Risk management</NavLink> and ensure
                            <br />
                            that your LPs stream {selectedInstrument} quotes.
                        </BannerContent>
                    );
            }
        }

        if ((BigInt(counterPartyLimit[CounterpartyLimitIndex.grossLimit]) - BigInt(counterPartyLimit[CounterpartyLimitIndex.grossLimitUtilization])) < 0) {
            return (
                <BannerContent>
                    You’ve exceeded your limit.
                    <br />
                    You need to either settle your position
                    <br />
                    or increase your limits to continue trading.
                </BannerContent>
            );
        }

        if (selectedBook.data[0]?.length === 0 && selectedBook.data[1]?.length === 0) {
            return <BannerContent>It seems no one is quoting right now.</BannerContent>;
        }
    }, [
        counterPartyLimit,
        selectedBook,
        hasMakerRoleUser,
        selectedInstrument,
        userType,
    ]);

    const titleBadge = useMemo(() => {
        if (!selectedInstrument) return null;
        return (
            <>
                <BadgeGreen>{selectedInstrument}</BadgeGreen>
            </>
        );
    }, [selectedInstrument]);
    return (
        <MarketDepthContainer>
            <DumbTable
                disableItemBorders
                title="Market Depth"
                titleBadge={titleBadge}
                headers={headers}
                minWidth={effectiveWidth}
                emptyMessage={emptyMessage}
                showBanner={Boolean(emptyMessage)}
                bannerType="marketdepth"
                data-test-id="market-depth"
            >
                {preparedData.map((data, index) => {
                    return (
                        <TableFlexRow key={index} onClick={data.onRowClick} style={data.style}>
                            {columns.map(({ id, Cell = CellIdentity, accessor, cellStyle }) => {
                                const value = accessor(data);
                                return (
                                    <TableFlexCell key={id} style={cellStyle}>
                                        <Cell value={value} />
                                    </TableFlexCell>
                                );
                            })}
                        </TableFlexRow>
                    );
                })}
            </DumbTable>
        </MarketDepthContainer>
    );
};
