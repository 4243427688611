import React from "react";
import { IS_TEST_ENV } from "../../const";
import { ForceDropdownDirection } from "../../hooks/useDropdownDirection";
import { Spacer } from "../../style";
import { when } from "../../utils";
import {
    Banner,
    GlobalActions,
    StyledTableFlex,
    TableContainer,
    TableFlexBody,
    TableFlexHeader,
    TableFlexRow,
    TableHeader,
    TitleBadgeContainer,
} from "./style";

export function DumbTable({
    title,
    children,
    headers,
    globalActions,
    emptyMessage,
    titleSlot,
    disableItemBorders,
    titleBadge,
    minWidth,
    showBanner,
    bannerType,
    "data-test-id": testId,
}: {
    headers: JSX.Element[];
    title?: string;
    titleSlot?: React.ReactNode;
    titleBadge?: React.ReactNode;
    children?: React.ReactNode;
    globalActions?: React.ReactNode;
    emptyMessage?: React.ReactNode;
    disableItemBorders?: boolean;
    minWidth?: number;
    showBanner?: boolean;
    bannerType?: "marketdepth";
    "data-test-id"?: string;
}) {
    return (
        <ForceDropdownDirection horizontal="center" vertical="top">
            <TableContainer>
                {when(
                    title,
                    <TableHeader>
                        {title}
                        {titleBadge ? <Spacer /> : null}
                        {titleBadge ? <TitleBadgeContainer>{titleBadge}</TitleBadgeContainer> : null}
                    </TableHeader>,
                )}
                {when(titleSlot, <GlobalActions>{titleSlot}</GlobalActions>)}
                {showBanner || (Array.isArray(children) && children.length === 0) ? (
                    <Banner type={bannerType}>{emptyMessage || "No data yet"}</Banner>
                ) : (
                    <StyledTableFlex
                        data-test-id={IS_TEST_ENV ? `table-${testId}` : undefined}
                        {...{ disableItemBorders, minWidth }}
                    >
                        <TableFlexHeader>
                            <TableFlexRow>{headers}</TableFlexRow>
                        </TableFlexHeader>
                        <TableFlexBody>{children}</TableFlexBody>
                    </StyledTableFlex>
                )}
                {globalActions && <GlobalActions>{globalActions}</GlobalActions>}
            </TableContainer>
        </ForceDropdownDirection>
    );
}
