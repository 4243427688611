import { Box, P } from "@fm-frontend/uikit";
import { EmDash } from "@fm-frontend/utils";
import React from "react";

export const PublicDetailsCell: React.FC<{ publicDetails: string | undefined }> = ({ publicDetails }) => {
    return (
        <Box ellipsis width="476px">
            <P>{publicDetails ?? EmDash}</P>
        </Box>
    );
};
