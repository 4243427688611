import { BasicInput, Form, FormAttention, H2, P, PrimaryButton, VStack } from "@fm-frontend/uikit";
import { DropdownOption, SingleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { post } from "api";
import { IconError } from "components/icons";
import { createNotification } from "feature/app";
import { useFormHelpers } from "hooks";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
import { startUserOnboarding } from "store/onboardingActions";
import {
    setIsTermsAndConditionsDelegated,
    setIsTermsAndConditionsRequired,
} from "store/onboardingSlice";
import { signTAndCSchema } from "./schemas";

type Positions = "Director" | "Authorised person with POA";

const POSITION_OPTIONS: DropdownOption<Positions>[] = [
    { text: "Director", value: "Director" },
    { text: "Authorised person with POA", value: "Authorised person with POA" },
];

export interface SignTAndCInputs {
    fullName: string;
    position: Positions;
}

type SignTAndCProps = {
    agreementNumber: string;
    token?: string | null;
    onClose?: () => void;
    isAutoFocus?: boolean;
    onSingedComplete?: () => void;
};

export const SignTAndC: React.FC<SignTAndCProps> = ({
    agreementNumber,
    token,
    onClose,
    isAutoFocus,
    onSingedComplete,
}) => {
    const dispatch = useDispatch();
    const { colors } = useTheme();
    const { error, setError } = useFormHelpers();
    const {
        control,
        register,
        handleSubmit,
        setFocus,
        formState: { errors, isSubmitting },
    } = useForm<SignTAndCInputs>({
        defaultValues: {
            position: "Director",
        },
        resolver: yupResolver(signTAndCSchema),
    });

    useEffect(() => {
        if (isAutoFocus) {
            setFocus("fullName");
        }
    }, [setFocus, isAutoFocus]);

    const onSubmit = async ({ fullName, position }: SignTAndCInputs) => {
        const isUnauthorized = Boolean(token);
        const method = isUnauthorized ? "signAgreementWithLink" : "signAgreement";
        const params = {
            signatory: fullName,
            signatoryPosition: position,
            agreementNumber,
            token,
        };

        try {
            await post(method, params, isUnauthorized);
            dispatch(setIsTermsAndConditionsRequired(false));
            dispatch(setIsTermsAndConditionsDelegated(false));
            dispatch(startUserOnboarding());
            dispatch(
                createNotification({
                    type: "success",
                    content: "Terms & Conditions with Finery Markets was successfully signed",
                }),
            );

            onSingedComplete?.();
            onClose?.();
        } catch (e) {
            setError(String(e));
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <VStack asCard padding={12}>
                <VStack paddingBottom={16} spacing={12}>
                    <H2>Sign T&C</H2>
                    <P color={colors.ui52}>
                        Enter your name and sign if only you are a director or an authorised person with power of
                        attorney
                    </P>
                    <BasicInput
                        data-1pignore
                        label="Full name"
                        placeholder="Tom Richard Johnson"
                        {...register("fullName")}
                        error={errors.fullName?.message}
                    />
                    <Controller
                        control={control}
                        render={({ field }) => (
                            <SingleDropdown
                                value={field.value}
                                onChange={field.onChange}
                                renderTrigger={(trigger) => (
                                    <SingleDropdown.Trigger {...trigger} size="large" variant="basic">
                                        <SingleDropdown.TriggerEssence
                                            {...trigger}
                                            option={trigger.selectedOption}
                                            size="large"
                                        />
                                    </SingleDropdown.Trigger>
                                )}
                                options={POSITION_OPTIONS}
                                caption="Position"
                                error={errors.position?.message}
                                fullWidth
                            >
                                <SingleDropdown.BasicSheet size="large" options={POSITION_OPTIONS} />
                            </SingleDropdown>
                        )}
                        name="position"
                    />
                    {error && (
                        <FormAttention>
                            <IconError />
                            <P>{error}</P>
                        </FormAttention>
                    )}
                </VStack>
                <VStack>
                    <PrimaryButton size="large" fullWidth type="submit" loading={isSubmitting}>
                        Sign agreement
                    </PrimaryButton>
                </VStack>
            </VStack>
        </Form>
    );
};
