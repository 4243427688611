import { useEffect, useRef, useState, VFC } from "react";
import { useHistory } from "react-router";
import { actionChannel } from "store/actionChannel";
import { P, PBold } from "../styled";
import type { NotificationType } from "../wsNotifications";
import { wsNotificationRead } from "../wsNotifications";
import { ReactComponent as CheckIcon } from "./check.svg";
import { Container, ContentWrapper, Status, StatusWrapper, Timestamp } from "./styled";
import { getTimeAgoInfo } from "./utils";

const NOTIFICATION_DETAILS_URLS: { [type in NotificationType]: string } = {
    Limit: "/limits",
    Request: "/transactions",
    Transaction: "/transactions",
    System: "",
};
// It's needed to guarantee that timeAgoInfo will be new
const CORRELATION_DELTA = 100;

const markNotificationAsRead = (id: number, isRead: boolean) => {
    if (!isRead) {
        actionChannel.put(wsNotificationRead(id));
    }
};

export const Notification: VFC<{
    id: number;
    isRead: boolean;
    message: string;
    timestamp: number;
    type: NotificationType;
    closePopover: () => void;
}> = ({ id, isRead, message, timestamp, type, closePopover }) => {
    const history = useHistory();
    const [, forceUpdate] = useState(0);
    const { label: timeAgoString, updateIn } = getTimeAgoInfo(timestamp);
    const timeoutId = useRef<NodeJS.Timeout | undefined>(undefined);

    useEffect(() => {
        clearTimeout(timeoutId.current);

        setTimeout(() => {
            forceUpdate((i) => i + 1);
        }, updateIn + CORRELATION_DELTA);
    }, [timeAgoString]);
    useEffect(() => {
        return () => clearTimeout(timeoutId.current);
    }, []);

    const Message = isRead ? P : PBold;
    const handleContentClick = () => {
        markNotificationAsRead(id, isRead);
        const detailsPageUrl = NOTIFICATION_DETAILS_URLS[type];

        if (detailsPageUrl) {
            history.push(detailsPageUrl);
            closePopover();
        }
    };

    return (
        <Container isRead={isRead}>
            <StatusWrapper>
                {!isRead && (
                    <Status onClick={() => markNotificationAsRead(id, isRead)}>
                        <CheckIcon style={{ opacity: 0 }} />
                    </Status>
                )}
            </StatusWrapper>
            <ContentWrapper onClick={handleContentClick}>
                <Message>{message} </Message>
                <Timestamp>{timeAgoString}</Timestamp>
            </ContentWrapper>
        </Container>
    );
};
