import { useSelectState } from "@react-stately/select";
import { Key, useEffect, useRef } from "react";
import { HiddenSelect, useButton, useSelect } from "react-aria";
import styled from "styled-components";
import { DummyButton } from "../../feature/form/Buttons";
import { useItemTransition } from "../../hooks/useTransition";
import { SelectableBadge } from "../Badge";
import { IconTriangle } from "../icons";
import { Popover } from "../Popover";
import { ListBoxPopup } from "./ListBox";

const OptionSelectContainer = styled.div`
    position: relative;
    cursor: pointer;
    z-index: 3;
    svg {
        margin-left: 2px;
    }
    li {
        display: flex;
        justify-content: space-between;
    }
`;

export const OptionSelect: React.FC<{
    title?: string;
    label?: string;
    name?: string;
    unselectable?: boolean;
    "aria-label": string;
    onSelectionChange?: (key: Key) => void;
    children: any;
    value?: string;
    showValue?: boolean;
    selectedKey?: Key;
}> = (props) => {
    const ref = useRef(null);
    const selectState = useSelectState(props);
    useEffect(() => {
        if (props.unselectable) selectState.setSelectedKey("");
    }, [selectState.selectedKey]);
    const { isActive, transitionState } = useItemTransition(selectState.isOpen, { enterDelay: 100, exitDelay: 100 });
    const selectProps = useSelect(props, selectState, ref);
    const button = useButton(selectProps.triggerProps, ref);
    return (
        <OptionSelectContainer>
            <HiddenSelect state={selectState} triggerRef={ref} label={props.label} name={props.name} />
            <DummyButton {...button.buttonProps} ref={ref}>
                <SelectableBadge>
                    <header>
                        {props.showValue ? selectState.selectedItem?.rendered : props.title}
                        {!props.showValue && !props.unselectable && selectState.selectedKey && ":"}
                    </header>
                    {!props.showValue && !props.unselectable && selectState.selectedKey}
                    <IconTriangle />
                </SelectableBadge>
            </DummyButton>
            {isActive && (
                <Popover containerRef={ref}>
                    <ListBoxPopup
                        {...selectProps.menuProps}
                        compact
                        horizontal="left"
                        vertical="bottom"
                        state={selectState}
                        transitionState={transitionState}
                    />
                </Popover>
            )}
        </OptionSelectContainer>
    );
};
