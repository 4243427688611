import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { useTheme } from "styled-components";
import { AssetRow } from "../../types";
import {
    AssetNameCell,
    AssetRefPriceCell,
    AssetRefPriceHeaderCell,
    CpBanCell,
    CpLimitCell,
    CpOvernightCell,
    TwoRowsHeader,
} from "../cells";

export const subaccountColumnHelper = createColumnHelper<AssetRow>();

const nameColumn = subaccountColumnHelper.accessor((row) => row, {
    id: "name",
    cell: (subject) => {
        const value = subject.getValue();
        return <AssetNameCell asset={value.currency} />;
    },
    header: "Name",
    sortingFn: (rowA, rowB) => {
        const currency1 = rowA.original.currency;
        const currency2 = rowB.original.currency;

        return currency2.localeCompare(currency1);
    },
    meta: {
        headerStyleProps: {
            width: "17%",
        },
    },
});

const refPriceColumn = subaccountColumnHelper.accessor((row) => row, {
    id: "refPrice",
    cell: (subject) => {
        const value = subject.getValue();
        return <AssetRefPriceCell asset={value.currency} />;
    },
    header: () => <AssetRefPriceHeaderCell />,
    enableSorting: false,
    meta: {
        cellStyleProps: {
            width: "88px",
            textAlign: "right",
            padding: [0, 12, 0, 0],
        },
        headerStyleProps: {
            width: "88px",
            textAlign: "right",
            padding: [0, 12, 6, 0],
        },
    },
});

export const useSubaccountColumns = () => {
    const theme = useTheme();

    return useMemo(() => {
        const borderLeftStyle = `1px solid ${theme.colors.ui4}`;
        const shortSalesStatusColumn = subaccountColumnHelper.accessor((row) => row, {
            id: "shortSales",
            header: () => <TwoRowsHeader title="Short sales" subtitle="by Master" />,
            cell: (subject) => {
                const rowData = subject.getValue();
                return (
                    <CpBanCell
                        isBanned={rowData.counterparties[0].isCpSetShortSalesBan}
                        cellType="cp"
                        cpType="primeBroker"
                    />
                );
            },
            enableSorting: false,
            meta: {
                cellStyleProps: {
                    padding: [0, 0, 0, 0],
                    width: "80px",
                    borderLeft: borderLeftStyle,
                },
                headerStyleProps: {
                    width: "80px",
                    padding: [0, 0, 6, 0],
                },
            },
        });

        const longOvernightsColumn = subaccountColumnHelper.accessor((row) => row, {
            id: "longOvernights",
            header: () => <TwoRowsHeader title="Overnights" subtitle="Long" />,
            cell: (subject) => {
                const rowData = subject.getValue();
                return <CpOvernightCell value={rowData.counterparties[0].positiveRate} />;
            },
            enableSorting: false,
            meta: {
                cellStyleProps: {
                    width: "88px",
                    borderLeft: borderLeftStyle,
                    padding: [0, 0, 0, 0],
                },
                headerStyleProps: {
                    width: "88px",
                    padding: [0, 0, 6, 0],
                },
            },
        });

        const shortOvernightsColumn = subaccountColumnHelper.accessor((row) => row, {
            id: "shortOvernights",
            header: () => <TwoRowsHeader subtitle="Short" />,
            cell: (subject) => {
                const rowData = subject.getValue();
                return <CpOvernightCell value={rowData.counterparties[0].negativeRate} />;
            },
            enableSorting: false,
            meta: {
                cellStyleProps: {
                    width: "88px",
                    borderLeft: borderLeftStyle,
                    padding: [0, 0, 0, 0],
                },
                headerStyleProps: {
                    width: "88px",
                    padding: [0, 0, 6, 0],
                },
            },
        });

        const assetLimit = subaccountColumnHelper.accessor((row) => row, {
            id: "assetLimit",
            header: () => <TwoRowsHeader title="Asset limit" subtitle="by Master" />,
            cell: (subject) => {
                const rowData = subject.getValue();
                return <CpLimitCell limitInfo={rowData.counterparties[0].cpLimit} />;
            },
            enableSorting: false,
            meta: {
                cellStyleProps: {
                    padding: [0, 0, 0, 0],
                    borderLeft: borderLeftStyle,
                },
                headerStyleProps: {
                    padding: [0, 0, 6, 0],
                },
            },
        });

        return [
            nameColumn,
            refPriceColumn,
            shortSalesStatusColumn,
            longOvernightsColumn,
            shortOvernightsColumn,
            assetLimit,
        ];
    }, [theme]);
};
