import { useSelectState } from "@react-stately/select";
import { ReactText, useEffect, useRef } from "react";
import { HiddenSelect, useButton, useSelect } from "react-aria";
import styled from "styled-components";
import { IS_TEST_ENV, onHover } from "../../const";
import { useDropdownDirection } from "../../hooks/useDropdownDirection";
import { useItemTransition } from "../../hooks/useTransition";
import { CompactSelectContainer } from "../../style";
import { selectors } from "../../style/theme";
import { IconTriangle } from "../icons";
import { ListBoxPopup } from "./ListBox";

export const CompactSelectActivator = styled.button`
    ${selectors.fontMedium}
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.005em;

    display: flex;
    align-items: center;

    padding: 11px 8px 12px 11px;
    height: 40px;
    border: none;
    border-radius: 8px;
    color: ${selectors.colorSelectFg};
    background-color: ${selectors.colorSelectBg};
    transition: background-color ${(p) => p.theme.transitions.default};
    z-index: 1;
    position: relative;
    cursor: pointer;
    && {
        ${onHover} {
            background-color: ${({ theme: { colors } }) => colors.selectBgHover};
        }
    }
    span {
        flex: 1;
    }
    svg {
        vertical-align: middle;
        margin-left: 8px;
    }
    img {
        margin-bottom: -3px;
        margin-right: 4px;
    }
`;

export const CompactSelect = (props: {
    id?: string;
    label: string;
    name: string;
    children: any;
    errorValue?: string;
    onSelectionChange: (key: ReactText) => any;
    selectedKey?: string;
    extra?: React.ReactNode;
    emptyTitle?: string;
    unselectable?: boolean;
    "aria-label"?: string;
    "data-test-id"?: string;
}) => {
    // Create state based on the incoming props
    const state = useSelectState(props);
    useEffect(() => {
        if (props.unselectable) state.setSelectedKey("");
    }, [state.selectedKey]);
    // Get props for child elements from useSelect
    const ref = useRef(null);
    const { labelProps, triggerProps, valueProps, menuProps } = useSelect(props, state, ref);

    const { horizontal, vertical } = useDropdownDirection(ref);
    // Get props for the button based on the trigger props from useSelect
    const { buttonProps } = useButton(triggerProps, ref);
    const { isActive, transitionState } = useItemTransition(state.isOpen, {
        enterDelay: 100,
        exitDelay: 100,
    });

    return (
        <CompactSelectContainer data-test-id={IS_TEST_ENV ? `select-${props["data-test-id"]}` : undefined}>
            <div {...labelProps}>{props.label}</div>
            <HiddenSelect state={state} triggerRef={ref} label={props.label} name={props.name} />
            <CompactSelectActivator {...buttonProps} ref={ref}>
                <span {...valueProps}>
                    {props.extra}
                    {state.selectedItem ? state.selectedItem.rendered : props.emptyTitle || ""}
                </span>
                <IconTriangle />
            </CompactSelectActivator>
            {isActive && (
                <ListBoxPopup
                    {...menuProps}
                    horizontal={horizontal}
                    vertical={vertical}
                    state={state}
                    transitionState={transitionState}
                    compact
                />
            )}
        </CompactSelectContainer>
    );
};
