import { IconSwap } from "../../../components/icons";
import { PillContainer } from "./style";

export const Pill: React.FC<{ isActive?: boolean; canReverse?: boolean; isReversed?: boolean }> = ({
    isActive,
    canReverse,
    isReversed,
    children,
}) => {
    return (
        <PillContainer isActive={isActive}>
            {children} {canReverse && isActive && <IconSwap isReversed={isReversed} />}
        </PillContainer>
    );
};
