import { postViaWS } from "api/methods/postViaWS";

export type BulkUpdateResponse = {
    error: string | null;
    requestBody: BulkUpdateRequest;
};

type UpdateWhitelistingRequestBody = {
    instruments: string[];
};

export type BulkUpdateRequest = {
    apiMethod: string;
    body: UpdateWhitelistingRequestBody;
};

const mapInstrumentsToRequestBody = (instruments: string[]): BulkUpdateRequest => {
    return {
        apiMethod: "setInstrumentsWhitelist",
        body: {
            instruments,
        },
    };
};

const bulkUpdateWhitelisting = async (
    whitelistingRequest: BulkUpdateRequest,
): Promise<BulkUpdateResponse> => {
    try {
        await postViaWS(whitelistingRequest.apiMethod, { ...whitelistingRequest.body });

        return {
            error: null,
            requestBody: whitelistingRequest,
        };
    } catch (error) {
        return {
            error: String(error),
            requestBody: whitelistingRequest,
        };
    }
};

export const BulkService = {
    mapInstrumentsToRequestBody,
    bulkUpdateWhitelisting,
};
