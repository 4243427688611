import { Copyright } from "components/Copyright";
import { LinkFAQ, LinkLegal, LinkPulse, LinkStatus } from "components/Links";
import { DEFAULT_HEIGHT, Logo } from "components/Logo";
import { useSelector } from "hooks/useSelector";
import { useSettings } from "hooks/useSettings";
import { memo } from "react";
import { NavLink } from "react-router-dom";
import { useIsPrimeBrokerUser, usePrimeBrokerViewType, useViewTypeBackground } from "store/hooks";
import {
    FooterContent,
    FooterCopyright,
    FooterEmail,
    FooterLinks,
    MenuContainer,
} from "style/menu";
import styled from "styled-components";
import { when } from "utils";
import { SubaccountsMenuButton } from "../subaccounts";
import { CommonMenuContent } from "./CommonMenuContent";
import { PrimeBrokerMenuContent } from "./PrimeBrokerMenuContent";

const calculateTopMargin = (logoHeight: number) => Math.max(12 - (logoHeight - DEFAULT_HEIGHT), 0);

const LogoLink = styled(NavLink)<{ $height: number }>`
    margin: ${(p) => calculateTopMargin(p.$height)}px 0 0 9px;
`;

export const MainMenu = memo(({ isOpen }: { isOpen: boolean }) => {
    const settings = useSettings();
    const pinned = useSelector((state) => state.settings.favoriteInstruments);
    const isMobile = useSelector((state) => state.app.isMobile);
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();
    const viewTypeBackground = useViewTypeBackground();
    const tradingUrl = pinned.length ? "/trading/pinned" : "/trading/BTC-/BTC-EUR";
    const homeUrl = isPrimeBrokerUser ? "/positions" : tradingUrl;

    return (
        <MenuContainer isOpen={isOpen}>
            {when(
                !isMobile,
                <LogoLink to={homeUrl} $height={settings.logo_height ?? DEFAULT_HEIGHT}>
                    <Logo height={settings.logo_height} />
                </LogoLink>,
            )}
            {when(isPrimeBrokerUser, <PrimeBrokerMenuContent />)}
            {when(!isPrimeBrokerUser, <CommonMenuContent />)}
            <FooterContent $background={viewTypeBackground}>
                {when(
                    isPrimeBrokerUser && primeBrokerViewType === "subaccounts",
                    <SubaccountsMenuButton />,
                )}
                <FooterEmail>
                    <a href={`mailto:${settings.support_email}`}>{settings.support_email}</a>
                </FooterEmail>
                {settings.footer_links && (
                    <FooterLinks>
                        <LinkFAQ />
                        <LinkStatus />
                        <LinkLegal />
                        <LinkPulse />
                    </FooterLinks>
                )}
                <FooterCopyright>
                    <Copyright />
                </FooterCopyright>
            </FooterContent>
        </MenuContainer>
    );
});

MainMenu.displayName = "Menu";
