import { TUserTextAdoptionDictionary } from "hooks/useTextAdaptor";

export const tradesHistoryTextAdoptionDictionary: TUserTextAdoptionDictionary = {
    tradesHistoryTitle: {
        default: "Counterparties",
        primeBroker: {
            subaccounts: "Sub-accounts",
        },
    },
};
