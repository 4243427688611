import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { settingSlice } from "../feature/settings";
import { useSelector } from "./useSelector";

export const useIsStarred = (instrument: string) => {
    const dispatch = useDispatch();
    const favoriteInstruments = useSelector((state) => state.settings.favoriteInstruments);
    const isStarred = useMemo(() => favoriteInstruments.includes(instrument), [favoriteInstruments, instrument]);
    const starredHandler = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(
                settingSlice.actions.setFavorites(
                    isStarred
                        ? favoriteInstruments.filter((item) => item !== instrument)
                        : [...favoriteInstruments, instrument],
                ),
            );
        },
        [favoriteInstruments, instrument],
    );
    return { isStarred, starredHandler };
};
