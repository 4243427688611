import {
    AssetPill,
    BasicButton,
    CardItem,
    ClientId,
    CounterpartyIcon,
    Flex,
    FormProgress,
    FormTab,
    FormTabList,
    H2,
    Header,
    HeaderTitle,
    Mark,
    P,
    PBold,
    PrimaryButton,
    PSmall,
    ShadowCard,
    ShadowItem,
    TabContext,
    TabPanel,
    TextSmall,
} from "@fm-frontend/uikit";
import { createNotification } from "feature/app";
import { ROUTES } from "feature/app/router/routes";
import { useSelector } from "hooks";
import { mapCurrencies } from "hooks/useGroupedCurrencies";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
    fetchStepsKYB,
    startVerification,
    cancelVerification,
} from "store/onboardingActions";
import {
    setMakerInfoDetailed,
    setStepsKYB,
    setVerificationStatus,
    StatusKYBVariant,
} from "store/onboardingSlice";
import styled, { useTheme } from "styled-components";
import { MakerInfo } from "types";
import { OnboardingInfoBanner } from "../../common";
import { StatusKVB } from "../StatusKVB";
import { getBanksData, getJurisdictionData, getRegulationData, useMakerEmails } from "../utils";
import {
    ContactProvider,
    Container,
    FinishedContainer,
    NotificationContainer,
    PropertiesContainer,
    SettlementContainer,
    VerificationContainer,
} from "./styled";
import { handleContactProvider } from "./utils";
import { Verification } from "./Verification";

const FormProgressContainer = styled.div`
    margin-bottom: 12px;
`;

const ONBOARDING_TABS = {
    verification: "1",
    assets: "2",
    settlement: "3",
};

const DEFAULT_TABS_ORDER = [
    {
        key: ONBOARDING_TABS.verification,
        title: "Verification",
    },
    {
        key: ONBOARDING_TABS.assets,
        title: "Assets",
    },
    {
        key: ONBOARDING_TABS.settlement,
        title: "Settlement",
    },
];

const STATUSES_FOR_STEPS = [StatusKYBVariant.Started, StatusKYBVariant.Finished];

export const LiquidityProviderDetails: React.VFC<{
    makerInfo: MakerInfo;
}> = ({ makerInfo }) => {
    const dispatch = useDispatch();
    const { colors } = useTheme();
    const history = useHistory();
    const {
        id,
        name,
        currency,
        legal_name,
        settlements,
        settlements_auto,
        overnight_allowed,
        fastest_time,
        required_time,
    } = makerInfo;

    const { isMobile } = useSelector((state) => state.app);
    const {
        statusKYB: statusObj,
        isStatusKYBLoading,
        restartVerificationMakerId,
        verificationStatuses,
    } = useSelector((state) => state.onboarding);

    const { status, isCancelLoading, isStartLoading } = verificationStatuses[id] ?? {};
    const statusKYB = statusObj?.[id] ?? StatusKYBVariant.NotStarted;

    const isVerificationActive = STATUSES_FOR_STEPS.includes(statusKYB);
    const [tab, setTab] = useState(ONBOARDING_TABS.assets);

    const jurisdictionData = useMemo(() => getJurisdictionData(makerInfo), [makerInfo]);
    const regulationData = useMemo(() => getRegulationData(makerInfo), [makerInfo]);
    const banksData = useMemo(() => getBanksData(makerInfo), [makerInfo]);

    const { emails: makerEmails } = useMakerEmails(makerInfo.id, isVerificationActive);

    useEffect(() => {
        if (isVerificationActive && !isStatusKYBLoading) {
            dispatch(fetchStepsKYB(id));
            setTab(ONBOARDING_TABS.verification);
        }
    }, [isVerificationActive, isStatusKYBLoading]);

    const mappedAssets = useMemo(() => mapCurrencies(currency), [makerInfo]);

    const [tabsOrder, setTabsOrder] = useState([...DEFAULT_TABS_ORDER]);

    useEffect(() => {
        setTabsOrder((prevOrder) => {
            if (!isVerificationActive) {
                return prevOrder.filter(({ key }) => key !== ONBOARDING_TABS.verification);
            }

            if (statusKYB === StatusKYBVariant.Finished) {
                return [...prevOrder.slice(1), prevOrder[0]];
            }

            return [...DEFAULT_TABS_ORDER];
        });
    }, [isVerificationActive, statusKYB]);

    useEffect(() => {
        if (!restartVerificationMakerId) {
            return;
        }

        dispatch(startVerification(id));
    }, [restartVerificationMakerId]);

    useEffect(() => {
        if (status === "started") {
            dispatch(
                createNotification({
                    type: "inbox",
                    content: (
                        <NotificationContainer>
                            <PBold>Verification was started</PBold>
                            <P>The introduction email was sent to {name}. Please follow next steps.</P>
                        </NotificationContainer>
                    ),
                }),
            );
            dispatch(setVerificationStatus({ id: id, status: {} }));
        }
        if (status === "canceled") {
            setTab(ONBOARDING_TABS.assets);
            dispatch(setVerificationStatus({ id: id, status: {} }));
        }
    }, [status]);

    const handleStartVerification = () => {
        dispatch(startVerification(id));
    };

    const handleCancelVerification = () => {
        dispatch(cancelVerification(id));
    };

    const handleOnBackClick = () => {
        dispatch(setMakerInfoDetailed(undefined));
        dispatch(setStepsKYB([]));
    };

    return (
        <Container>
            <OnboardingInfoBanner />
            <FormProgressContainer>
                <FormProgress onBackClick={handleOnBackClick}></FormProgress>
            </FormProgressContainer>
            <ShadowCard isDefault>
                <ShadowItem padding="12px 12px 0 12px">
                    <Header>
                        <HeaderTitle
                            title={
                                <Flex spacing={8} alignItems="center" width="100%">
                                    <H2>{name}</H2>
                                    {statusKYB === StatusKYBVariant.Finished && <ClientId id={makerInfo.id} />}
                                    <PSmall color={colors.ui52}>{legal_name}</PSmall>
                                </Flex>
                            }
                            Icon={<CounterpartyIcon size={36} name={name} />}
                        />
                        <StatusKVB statusKYB={statusKYB} />
                    </Header>
                </ShadowItem>
                <ShadowItem padding="16px" isUnderlined>
                    <PropertiesContainer>
                        <CardItem width="50%" title={jurisdictionData.title}>
                            <PBold>{jurisdictionData.label}</PBold>
                        </CardItem>
                        <CardItem width="50%" title={regulationData.title}>
                            <PBold>{regulationData.label}</PBold>
                        </CardItem>
                    </PropertiesContainer>
                </ShadowItem>
                {statusKYB === StatusKYBVariant.Finished && (
                    <ShadowItem padding="12px 16px 16px">
                        <FinishedContainer>
                            <BasicButton size="medium" onClick={() => history.push(ROUTES.riskManagement)}>
                                <PBold>Manage Risks</PBold>
                            </BasicButton>
                            <ContactProvider href={handleContactProvider(makerEmails)}>
                                <PBold>Contact provider</PBold>
                            </ContactProvider>
                        </FinishedContainer>
                    </ShadowItem>
                )}
                {(statusKYB === StatusKYBVariant.NotStarted || statusKYB === StatusKYBVariant.Cancelled) && (
                    <ShadowItem padding="12px 16px 16px">
                        <VerificationContainer>
                            <PrimaryButton
                                size={isMobile ? "large" : "medium"}
                                onClick={handleStartVerification}
                                loading={isStartLoading}
                                fullWidth={isMobile}
                            >
                                Start verification
                            </PrimaryButton>
                            <TextSmall color={colors.ui52}>
                                By continuing you agree to share information and send the verification request to {name}
                            </TextSmall>
                        </VerificationContainer>
                    </ShadowItem>
                )}
            </ShadowCard>
            <TabContext value={tab} handleClick={setTab}>
                <ShadowCard isDefault>
                    <ShadowItem padding="0 0 8px 0">
                        <ShadowItem padding="12px 12px 0 12px">
                            <Header>
                                <FormTabList>
                                    {tabsOrder.map(({ key, title }) => (
                                        <FormTab key={key} title={title} value={key} />
                                    ))}
                                </FormTabList>
                            </Header>
                        </ShadowItem>
                        <TabPanel value={ONBOARDING_TABS.assets}>
                            {Object.entries(mappedAssets).map(
                                ([key, arr]) =>
                                    Boolean(arr.length) && (
                                        <ShadowItem padding="12px 16px" key={key}>
                                            <CardItem
                                                title={
                                                    <TextSmall color={colors.ui72}>
                                                        {key} <Mark color={colors.ui32}>{arr.length}</Mark>
                                                    </TextSmall>
                                                }
                                                key={key}
                                            >
                                                {arr.map(({ name, venue }) => (
                                                    <AssetPill
                                                        key={`${name}-${venue}`}
                                                        title={name ?? ""}
                                                        label={venue}
                                                    />
                                                ))}
                                            </CardItem>
                                        </ShadowItem>
                                    ),
                            )}
                        </TabPanel>
                        <TabPanel value={ONBOARDING_TABS.settlement}>
                            <ShadowItem padding="12px 16px" isUnderlined>
                                <SettlementContainer>
                                    <CardItem width="50%" title="Settlement format">
                                        <PBold>
                                            {settlements_auto ? "Automated" : "Manual"}
                                            <Mark color={colors.ui32}>∙</Mark>
                                            {settlements}
                                        </PBold>
                                    </CardItem>
                                    <CardItem width="50%" title="Overnight positions">
                                        <PBold>{overnight_allowed ? "Allowed" : "Restricted"}</PBold>
                                    </CardItem>
                                    <CardItem width="50%" title="Fastest time">
                                        <PBold>{fastest_time}</PBold>
                                    </CardItem>
                                    <CardItem width="50%" title="Required time">
                                        <PBold>{required_time}</PBold>
                                    </CardItem>
                                </SettlementContainer>
                            </ShadowItem>
                            <ShadowItem padding="12px 16px">
                                <SettlementContainer>
                                    <CardItem title={banksData.title}>
                                        <PBold>{banksData.label}</PBold>
                                    </CardItem>
                                </SettlementContainer>
                            </ShadowItem>
                        </TabPanel>
                        {isVerificationActive && (
                            <TabPanel value={ONBOARDING_TABS.verification}>
                                <Verification
                                    statusKYB={statusKYB}
                                    onCancelVerification={handleCancelVerification}
                                    isCancelLoading={Boolean(isCancelLoading)}
                                    makerInfo={makerInfo}
                                    makerEmails={makerEmails}
                                />
                            </TabPanel>
                        )}
                    </ShadowItem>
                </ShadowCard>
            </TabContext>
        </Container>
    );
};
