import { PBold, PSmall, VStack } from "@fm-frontend/uikit";
import styled from "styled-components";

interface StatisticProps {
    title: string;
    value: string | React.ReactNode;
}

const Value = styled(PBold)`
    word-break: break-all;
`;

export const Statistic = ({ title, value }: StatisticProps) => (
    <VStack spacing={2}>
        <PSmall>{title}</PSmall>
        <Value>{value}</Value>
    </VStack>
);
