import {
    Flex,
    H2,
    Icons,
    Modal,
    P,
    PlainButton,
    PrimaryButton,
    PSmallBold,
    Skeleton,
    VStack,
} from "@fm-frontend/uikit";
import { useModalControl } from "@fm-frontend/utils";
import { CopyToClipboard } from "components/CopyToClipboard";
import { createNotification } from "feature/app";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { startUserOnboarding } from "store/onboardingActions";
import { setIsTermsAndConditionsDelegated } from "store/onboardingSlice";
import styled, { css, keyframes, useTheme } from "styled-components";
import { useSigningLink } from "../useSigningLink";
import { SendSigningLink } from "./SendSigningLink";

const InfoToolTip = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
    height: 24px;
`;

type ForwardSigningLinkProps = {
    onClose: () => void;
};

const shortenUrl = (url: string): string => {
    const DEFAULT_VALUE = "...";

    if (!url) {
        return DEFAULT_VALUE;
    }

    try {
        const { hostname } = new URL(url);
        return `${hostname}/...`;
    } catch (e) {
        return DEFAULT_VALUE;
    }
};

const CheckIcon = () => (
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5 0C3.65 0 0.5 3.15 0.5 7C0.5 10.85 3.65 14 7.5 14C11.35 14 14.5 10.85 14.5 7C14.5 3.15 11.35 0 7.5 0ZM11.2657 5.32205C11.5781 5.01961 11.5781 4.52926 11.2657 4.22683C10.9533 3.92439 10.4467 3.92439 10.1343 4.22683L6.43333 7.80956L4.86569 6.292C4.55327 5.98956 4.04673 5.98956 3.73431 6.292C3.4219 6.59443 3.4219 7.08478 3.73431 7.38722L6.43333 10L11.2657 5.32205Z"
            fill="#16C7B0"
        />
    </svg>
);

const FADE_IN_CSS = keyframes`
  from {
    opacity: 0;
    transform: translateY(24px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedInfoContainer = styled.div<{ isActive?: boolean }>`
    display: flex;
    height: 24px;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    gap: 8px;
    border-radius: 12px;
    background-color: ${(p) => p.theme.colors.ui4};

    position: absolute;
    bottom: 0;
    width: 100%;
    opacity: 0;

    ${(p) =>
        p.isActive &&
        css`
            animation: ${FADE_IN_CSS} 0.5s ease forwards;
        `};
`;

export const ForwardSigningLink: React.FC<ForwardSigningLinkProps> = ({ onClose }) => {
    const dispatch = useDispatch();
    const { colors } = useTheme();
    const { isModalOpen, openModal, closeModal } = useModalControl();
    const { signingLink } = useSigningLink({ isReadOnly: false });

    useEffect(() => {
        if (signingLink) {
            navigator.clipboard.writeText(signingLink);
            dispatch(
                createNotification({
                    type: "success",
                    content: "Signing link was successfully copied to clipboard",
                }),
            );
        }
    }, [signingLink]);

    const handleClose = () => {
        dispatch(setIsTermsAndConditionsDelegated(true));
        dispatch(startUserOnboarding());
        onClose();
    };

    return (
        <>
            <VStack asCard maxWidth="360px" padding={12}>
                <VStack spacing={12}>
                    <H2>Forward signing link</H2>
                    <CopyToClipboard value={signingLink} title="Signing link">
                        <Flex
                            width="100%"
                            asCard
                            justifyContent="center"
                            alignItems="center"
                            padding={12}
                        >
                            {signingLink ? (
                                <>
                                    <P>{shortenUrl(signingLink)}</P>
                                    <Icons.CopyIcon color="none" />
                                </>
                            ) : (
                                <Skeleton height="24px" width="214px" />
                            )}
                        </Flex>
                    </CopyToClipboard>
                    <InfoToolTip>
                        <AnimatedInfoContainer isActive={Boolean(signingLink)}>
                            <CheckIcon />
                            <PSmallBold color={colors.ui52}>
                                The signing link was copied to a clipboard
                            </PSmallBold>
                        </AnimatedInfoContainer>
                    </InfoToolTip>
                    <P color={colors.ui52}>
                        Send the copied link to an authorised person via email, or via messengers by
                        yourself.
                    </P>
                    <P color={colors.ui52}>This link will expire in 30 days</P>
                </VStack>
                <VStack spacing={8} margin={[16, 0, 0, 0]}>
                    <PrimaryButton size="large" fullWidth onClick={openModal}>
                        Forward link via email
                    </PrimaryButton>
                    <PlainButton size="large" fullWidth onClick={handleClose}>
                        I sent copied link myself
                    </PlainButton>
                </VStack>
            </VStack>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <SendSigningLink onClose={closeModal} onSend={handleClose} />
            </Modal>
        </>
    );
};
