import { SearchSelect } from "components/select/SearchSelect";
import { TopMenuHeader } from "components/TopMenu";
import { UserMenuControl } from "components/UserMenuControl/UserMenuControl";
import { PINNED } from "const";
import { DummyButton } from "feature/form/Buttons";
import { useLockableState } from "hooks/useLockableState";
import { LS_VARIABLES, useLSState } from "hooks/useLSState";
import { useSelector } from "hooks/useSelector";
import { useTitle } from "hooks/useTitle";
import { memo, useCallback, useContext, useMemo } from "react";
import { useHasMakerRoleUser } from "store/hooks";
import { Spacer } from "style";
import { TradingAsset } from "types";
import { IconPinned, IconRecent, IconStar14, MobileLogo } from "../../../components/icons";
import { LPManagement } from "./LPManagement";
import { Pill } from "./Pill";
import { SelectedAssetContext } from "./selectedAsset";
import { NavigationContainer } from "./style";

const PILLS_LIST = ["BTC", "ETH", "USDC", "USDT", "USD", "EUR"];
const FIAT = ["USD", "EUR"];
const RECENTS_COUNT = 10;

export const TradingTopMenu: React.FC<{
    instrumentDict: {
        [k: string]: {
            base: string[];
            quote: string[];
            title: string;
        };
    };
    searchList: string[];
}> = memo(({ instrumentDict, searchList }) => {
    const context = useContext(SelectedAssetContext);
    const hasMakerRoleUser = useHasMakerRoleUser();
    const { isMobile } = useSelector((state) => state.app);
    useTitle(context.instrument ? `Trading ${context.instrument}` : "Trading");
    const favoriteInstruments = useSelector((state) => state.settings.favoriteInstruments);
    const [pinnedInstruments, setIsLocked] = useLockableState(favoriteInstruments);
    const [recents, setRecents] = useLSState<string[]>(LS_VARIABLES.TRADING_RECENT, []);
    const onSelectInstrument = useCallback(
        (asset: TradingAsset, instrument: string) => {
            setRecents((recents) => {
                return [
                    instrument,
                    ...recents.filter((thisInstrument) => instrument !== thisInstrument).slice(0, RECENTS_COUNT - 1),
                ];
            });
            context.setAsset?.(asset, instrument);
        },
        [setRecents, context],
    );
    const specialGroup = useMemo(() => {
        return [
            {
                title: "Recent",
                icon: <IconRecent />,
                children: recents,
            },
            {
                title: "Pinned",
                icon: <IconPinned />,
                children: pinnedInstruments,
            },
        ].filter((item) => item.children.length);
    }, [recents, pinnedInstruments]);
    const instrumentGroups = useMemo(() => {
        const { BTC, ETH, LTC, USDT, ...secondary } = instrumentDict;
        return [
            [BTC, ETH, LTC, USDT]
                .filter(Boolean)
                .map(({ base, quote, title }) => ({ title, children: { base, quote } })),
            Object.values(secondary).map(({ base, quote, title }) => ({ title, children: { base, quote } })),
        ];
    }, [instrumentDict]);
    const selectOptions = useMemo(() => {
        return [specialGroup, ...instrumentGroups];
    }, [specialGroup, instrumentGroups]);
    const pills = useMemo(() => {
        return favoriteInstruments.length ? [...PILLS_LIST, PINNED] : PILLS_LIST;
    }, [favoriteInstruments]);
    const content = useMemo(() => {
        return (
            <NavigationContainer isMaker={hasMakerRoleUser}>
                <SearchSelect
                    searchItems={searchList}
                    items={selectOptions}
                    onItemHover={(item) => {
                        setIsLocked(item === "Pinned");
                    }}
                    onSelect={onSelectInstrument}
                    selectedOption={pills.includes(context.asset || "") ? undefined : context.instrument}
                />
                {!isMobile &&
                    pills.map((asset) => {
                        const isThisAsset = asset === context.asset;
                        const isFiat = FIAT.includes(asset);
                        return (
                            <DummyButton
                                key={asset}
                                onClick={() => {
                                    context.setAsset?.({
                                        asset,
                                        isReversed:
                                            isFiat ||
                                            (asset === PINNED ? false : isThisAsset ? !context.isReversed : false),
                                    });
                                }}
                            >
                                <Pill
                                    canReverse={asset === PINNED || isFiat ? false : isThisAsset}
                                    isActive={isThisAsset}
                                    isReversed={isThisAsset && context.isReversed}
                                >
                                    {asset === PINNED ? (
                                        <>
                                            <IconStar14 isActive /> {favoriteInstruments.length}
                                        </>
                                    ) : (
                                        asset
                                    )}
                                </Pill>
                            </DummyButton>
                        );
                    })}
            </NavigationContainer>
        );
    }, [searchList, selectOptions, onSelectInstrument, pills, context, favoriteInstruments, isMobile]);
    return (
        <TopMenuHeader>
            {isMobile && <MobileLogo />}
            {content}
            {!isMobile && <Spacer />}
            {!hasMakerRoleUser && <LPManagement key="lp-management" />}
            <UserMenuControl />
        </TopMenuHeader>
    );
});
TradingTopMenu.displayName = "TradingTopMenu";
