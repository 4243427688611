import { SimpleValue } from "../../../feature/table";
import { ColumnTemplate, createColumns } from "../../../feature/table/columns";
import { usePositionsGrouped } from "../../../store/usePositionsGrouped";
import { CpInfo } from "../CpInfo";

type Item = ReturnType<typeof usePositionsGrouped>["result"][0];

const getColor = (value: SimpleValue, data: Item) => (value === 0n || data.value ? "textFG" : "blue");
const getColorRate = (value: SimpleValue, data: Item) => (data.value > 0 ? "textFG" : "red");
export const columnsPositionsBase: ColumnTemplate<Item>[] = [
    {
        header: "Position",
        accessor: (data) => data.value,
        format: { significantDigits: 8, type: "size" },
        getColor,
    },
    {
        header: "Price",
        accessor: (data) => data.price,
        format: { significantDigits: 2, type: "price" },
    },
    {
        header: "Position, $",
        accessor: (data) => data.usdValue,
        getColor,
        format: { significantDigits: 2, type: "price" },
        mixin: { fontWeight: 500 },
    },
];

export const columnsPositionsByCounterparty = createColumns<Item>([
    {
        header: "Counterparty / Asset",
        accessor: (data) => data.item,
        mixin: { fontWeight: 500, align: "left" },
    },
    ...columnsPositionsBase,
]);

export const columnsPositionsByAsset = createColumns<Item>([
    {
        header: "Asset / Counterparty",
        accessor: (data) => data.item,
        Cell: ({ row: { original: position } }) => <CpInfo cpId={position.counterparty} />,
        mixin: { fontWeight: 500, align: "left" },
    },
    ...columnsPositionsBase,
]);

const columnsPositionsWithPlannedBase: ColumnTemplate<Item>[] = [
    ...columnsPositionsBase,
    {
        header: "Planned Position",
        accessor: (data) => data.plannedValue,
        getColor,
        format: {
            significantDigits: 8,
            type: "size",
        },
    },
    {
        header: "Planned Position, $",
        accessor: (data) => data.usdPlannedValue,
        getColor,
        mixin: { fontWeight: 500 },
        format: {
            significantDigits: 2,
            type: "size",
        },
    },
    {
        header: "OR, %",
        accessor: (data) => data.overnightCost,
        getColor: getColorRate,
        format: { significantDigits: 2, type: "size" },
        mixin: { fontWeight: 500 },
    },
    {
        header: "Est. overnight cost, $",
        accessor: (data) => data.estOvernightCost,
        getColor: getColorRate,
        format: { significantDigits: 2, type: "size" },
        mixin: { fontWeight: 500 },
    },
];

export const columnsPositionsWithPlannedByCounterparty = createColumns<Item>([
    {
        header: "Counterparty / Asset",
        accessor: (data) => data.item,
        mixin: { fontWeight: 500, align: "left" },
    },
    ...columnsPositionsWithPlannedBase,
]);

export const columnsPositionsWithPlannedByAsset = createColumns<Item>([
    {
        header: "Asset / Counterparty",
        accessor: (data) => data.item,
        Cell: ({ row: { original: position } }) => <CpInfo cpId={position.counterparty} />,
        mixin: { fontWeight: 500, align: "left" },
    },
    ...columnsPositionsWithPlannedBase,
]);
