import { Modal, ModalProps, MODAL_TOP_OFFSET } from "@fm-frontend/uikit";
import { IS_TEST_ENV } from "const";
import React from "react";
import { TEST_ALERT_HEIGHT } from "components/TestEnvAlert";

const modalStyles = {
    overlay: {
        overflow: "auto",
    },
    content: {
        top: `${IS_TEST_ENV ? MODAL_TOP_OFFSET + TEST_ALERT_HEIGHT : MODAL_TOP_OFFSET}px`,
        left: "50%",
        transform: "translate(-50%)",
    },
};

export const AddressesModal: React.FC<ModalProps> = ({ children, ...props }) => (
    <Modal {...props} style={modalStyles}>
        {children}
    </Modal>
);
