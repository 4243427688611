import { useMemo } from "react";
import { useLimitsMap } from "feature/assetsControl/components/AssetsTabContent/hooks";
import {
    useInstrumentMarkups
} from "feature/assetsControl/components/InstrumentsTabContent/MakerMasterInstrumentsTabContent/IntsrumentsTable/hooks/useInstrumentMarkups";
import { useInstruments } from "hooks";
import { useShortSalesBan } from "hooks/useShortSalesBan";
import { useOvernightFees } from "hooks/useOvernightFees";
import { CounterpartyLimit, CounterpartyLimitIndex } from "types";
import { getTradingMarkupIndicatorData } from "../utils";

export const useLimitData = (limit: CounterpartyLimit) => {
    const cpId = limit[CounterpartyLimitIndex.counterpartyId];

    const { currencies, isLoading: isInstrumentsLoading } = useInstruments();
    const { limitsMap, isLoading: isLimitsMapLoading } = useLimitsMap();
    const { shortSalesBans, isLoading: isShortSalesBansLoading } = useShortSalesBan();
    const { overnightFees, isLoading: isOvernightFeesLoading } = useOvernightFees();
    const { instrumentMarkups, isLoading: isInstrumentMarkupsLoading } = useInstrumentMarkups();

    const isLoading = isInstrumentsLoading || isLimitsMapLoading || isShortSalesBansLoading || isOvernightFeesLoading || isInstrumentMarkupsLoading;

    const availableCurrencies = useMemo(() => Object.keys(currencies), [currencies]);
    const tradingMarkupIndicatorData = useMemo(() => getTradingMarkupIndicatorData(limit), [limit]);
    const limitPerAssetsCount = Object.values(limitsMap[cpId] ?? {})
        .reduce((acc, item) => {
            if (item.grossLimit !== null) {
                acc += 1;
            }
            return acc;
        }, 0);
    const shortSalesBansCount = Object.keys(shortSalesBans[cpId] ?? {}).length;
    const overnightFeesCount = useMemo(
        () => [...overnightFees.own, ...overnightFees.against].reduce((acc, item) => {
            if (item.counterparty === cpId && availableCurrencies.includes(item.currency)) {
                acc += 1;
            }
            return acc;
        }, 0),
        [cpId, overnightFees.own, overnightFees.against],
    );
    const markupsPerInstrumentsCount = useMemo(
        () => Object.keys(instrumentMarkups).reduce((acc, instrument) => {
            if (instrumentMarkups[instrument][cpId] !== undefined) {
                acc += 1;
            }
            return acc;
        }, 0),
        [instrumentMarkups, cpId],
    );

    return {
        cpId,
        defaultMarkup: tradingMarkupIndicatorData.value,
        markupsPerInstrumentsCount,
        limitPerAssetsCount,
        shortSalesBansCount,
        overnightFeesCount,
        isLoading,
    };
};
