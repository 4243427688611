import { P, Tooltip } from "@fm-frontend/uikit";
import React from "react";
import styled from "styled-components";
import { TradeHistory } from "../../../types";
import { CopyCell } from "../CopyCell";

const ManualTradeMark = styled.span`
    color: ${(p) => p.theme.colors.ui52};
`;

type OrderIdProps = Pick<TradeHistory, "orderId" | "orderType">;

export const OrderIdCell: React.VFC<OrderIdProps> = ({ orderId, orderType }) => {
    if (orderId === null || orderId === undefined) {
        return <></>;
    }

    const isManualTradeOrder = orderType === "voiceTrade" || orderType === "liquidation";
    const manualTradeTooltipText = orderType === "voiceTrade" ? "Voice trade" : "Liquidation";

    return (
        <CopyCell content={orderId}>
            {isManualTradeOrder && (
                <Tooltip content={manualTradeTooltipText}>
                    <P>
                        {orderId}
                        <ManualTradeMark>*</ManualTradeMark>
                    </P>
                </Tooltip>
            )}
            {!isManualTradeOrder && <P>{orderId}</P>}
        </CopyCell>
    );
};
