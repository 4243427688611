import { forwardRef } from "react";
import { DefaultTheme, useTheme } from "styled-components";
import { SmallLoader } from "../../animations";
import { IconButtonVariant } from "../types";
import { IconButtonProps } from "./IconButton.types";
import { IconButton as StyledIconButton } from "./styled";

const getIconColor = (variant: IconButtonVariant, disabled: boolean, colors: DefaultTheme["colors"]) => {
    if (disabled) {
        return colors.ui12;
    }

    if (variant === "primary") {
        return colors.uiWhite100;
    }

    return colors.ui32;
};

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
    ({ variant, loading = false, children, Icon, disabled = false, ...restProps }, ref) => {
        const { colors } = useTheme();
        const loaderColor = variant === "primary" ? colors.uiWhite100 : colors.ui32;
        const iconColor = getIconColor(variant, disabled, colors);
        const content = loading ? <SmallLoader color={loaderColor} /> : <Icon color={iconColor} />;

        return (
            <StyledIconButton
                variant={variant}
                loading={loading}
                disabled={loading || disabled}
                ref={ref}
                {...restProps}
            >
                {content}
            </StyledIconButton>
        );
    },
);
