import { PSBold } from "feature/inboxPopover/styled";
import styled from "styled-components";
import { Settlement } from "../../../../types";
import { useTransactionStatusStyles } from "../../../../useTransactionStatusStyles";

const StatusContainer = styled.div<{ bgColor: string; color: string }>`
    background-color: ${(p) => p.bgColor};
    color: ${(p) => p.color};
    display: inline-block;
    padding: 4px 8px;
    border-radius: 12px;
    position: relative;
    top: 2px;
`;

export const Status = ({ settlement: { status, type } }: { settlement: Settlement }) => {
    const transactionsStyles = useTransactionStatusStyles(type);
    const styles = transactionsStyles[status];

    return (
        <StatusContainer bgColor={styles.bgColor} color={styles.color}>
            <PSBold>{styles.title}</PSBold>
        </StatusContainer>
    );
};
