import { Icons, P } from "@fm-frontend/uikit";
import {
    DropdownOption,
    MultipleDropdown,
    SheetSize,
    SingleDropdown,
} from "@fm-frontend/uikit/src/components/v2";
import { OPTIONS_SEARCH_COUNT } from "@fm-frontend/uikit/src/components/v2/Dropdown";
import {
    CheckBoxChecked,
    CheckBoxUnchecked,
} from "@fm-frontend/uikit/src/components/v2/Dropdown/Option";
import { useMemo } from "react";

import styled, { useTheme } from "styled-components";

const OPTION_MARGIN = 4;

const DropdownActionOption = styled.div`
    border-radius: 6px;
    background-color: transparent;

    display: flex;
    align-items: center;
    padding: 8px;
    width: calc(100% - ${OPTION_MARGIN * 2}px);
    margin-left: ${OPTION_MARGIN}px;
    margin-right: ${OPTION_MARGIN}px;
    min-height: 36px;

    overflow: hidden;
    cursor: pointer;

    :first-child {
        margin-top: 4px;
    }
    :last-child {
        margin-bottom: 4px;
    }

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.07px;

    color: ${(p) => p.theme.colors.ui100};

    :hover {
        background-color: ${(p) => p.theme.colors.ui4};
        color: ${(p) => p.theme.colors.ui100};
    }

    mark {
        background-color: ${(p) => p.theme.colors.brand32};
    }
`;

const ActionCheckBoxContainer = styled.span`
    padding-right: 8px;
    line-height: 100%;
`;

const ActionCheckMarkContainer = styled.span`
    width: 24px;
    padding-right: 8px;
    padding-left: 4px;
    line-height: 100%;
`;

export const MultipleDropdownGroupedSheet = <TValue,>({
    values = [],
    onChange,
    allOptionText,
    groupOptions = [],
    options,
    Dropdown,
    size = "small",
    OptionEssence = Dropdown.OptionEssence,
}: {
    values: TValue[];
    onChange: (value: TValue[]) => void;
    allOptionText?: string;
    groupOptions?: { text: string; isOptionInGroup: (value: TValue) => boolean }[];
    options: DropdownOption<TValue>[] | DropdownOption<TValue>[][];
    Dropdown: typeof SingleDropdown | typeof MultipleDropdown;
    size: SheetSize;
    OptionEssence?: React.FC<any>;
}) => {
    const { colors } = useTheme();
    const flatOptions = useMemo(() => options.flat(), [options]);
    const allOptionsSelected = values.length === flatOptions.length;
    const groupOptionsMap = useMemo(() => {
        return groupOptions.reduce<
            Record<string, { optionsValues: TValue[]; isOptionInGroup: (value: TValue) => boolean }>
        >((acc, { text, isOptionInGroup }) => {
            acc[text] = {
                optionsValues: flatOptions
                    .filter((option) => isOptionInGroup(option.value))
                    .map(({ value }) => value),
                isOptionInGroup,
            };
            return acc;
        }, {});
    }, [groupOptions, options]);
    const groupOptionsStates = useMemo(() => {
        const flatValue = new Set(values);
        return Object.entries(groupOptionsMap).reduce<Record<string, boolean>>(
            (acc, [groupOptionText, { optionsValues }]) => {
                acc[groupOptionText] = optionsValues.every((optionValue) =>
                    flatValue.has(optionValue),
                );
                return acc;
            },
            {},
        );
    }, [groupOptionsMap, values]);
    const onGroupOptionClick = (groupOptionText: string) => {
        if (groupOptionsStates[groupOptionText]) {
            onChange(
                values.filter((val) => !groupOptionsMap[groupOptionText].isOptionInGroup(val)),
            );
            return;
        }
        onChange(
            Array.from(new Set([...values, ...groupOptionsMap[groupOptionText].optionsValues])),
        );
    };
    const filteredGroupOptions = useMemo(() => {
        const notEmptyGroups = groupOptions.filter(
            ({ text }) => groupOptionsMap[text].optionsValues.length > 0,
        );
        if (
            notEmptyGroups.length === 1 &&
            groupOptionsMap[notEmptyGroups[0].text].optionsValues.length === flatOptions.length
        ) {
            // if there is the only group and its options number equals all options
            // it is no need to show that group
            return [];
        }

        return notEmptyGroups;
    }, [groupOptions, groupOptionsMap, flatOptions]);

    const normalizedOptions = (
        Array.isArray(options[0]) ? options : [options]
    ) as DropdownOption<TValue>[][];

    return (
        <Dropdown.Sheet size={size}>
            {({ query, normalizedQuery, onSearch }) => (
                <>
                    {flatOptions.length > OPTIONS_SEARCH_COUNT && (
                        <Dropdown.Search query={query} onSearch={onSearch} />
                    )}
                    {query && (
                        <Dropdown.EmptySearch>
                            {normalizedOptions
                                .map((optionsSection) => {
                                    const optionsSectionElements = optionsSection
                                        .filter((o) =>
                                            o.text?.toLowerCase().includes(normalizedQuery),
                                        )
                                        .map((option) => (
                                            <Dropdown.Option
                                                key={`${option.value}`}
                                                value={option.value}
                                            >
                                                <OptionEssence option={option} query={query} />
                                            </Dropdown.Option>
                                        ));
                                    optionsSectionElements.push(<Dropdown.Divider />);
                                    return optionsSectionElements;
                                })
                                .flat()
                                .slice(0, -1)}
                        </Dropdown.EmptySearch>
                    )}
                    {!query && (
                        <Dropdown.EmptySearch>
                            {allOptionText && (
                                <>
                                    <DropdownActionOption
                                        tabIndex={0}
                                        key="all"
                                        onClick={() =>
                                            onChange(
                                                allOptionsSelected
                                                    ? []
                                                    : flatOptions.map(
                                                          ({ value: optionValue }) => optionValue,
                                                      ),
                                            )
                                        }
                                    >
                                        <ActionCheckMarkContainer>
                                            {values.length === flatOptions.length && (
                                                <Icons.Checkmark color={colors.brand} />
                                            )}
                                        </ActionCheckMarkContainer>
                                        <P>{allOptionText}</P>
                                    </DropdownActionOption>
                                    <Dropdown.Divider />
                                </>
                            )}
                            {filteredGroupOptions.length > 0 && (
                                <>
                                    {filteredGroupOptions.map(({ text }) => (
                                        <DropdownActionOption
                                            key={text}
                                            onClick={() => onGroupOptionClick(text)}
                                        >
                                            <ActionCheckBoxContainer>
                                                {groupOptionsStates[text] ? (
                                                    <CheckBoxChecked />
                                                ) : (
                                                    <CheckBoxUnchecked />
                                                )}
                                            </ActionCheckBoxContainer>
                                            <P>{text}</P>
                                        </DropdownActionOption>
                                    ))}
                                    <Dropdown.Divider />
                                </>
                            )}
                            {normalizedOptions
                                .map((optionsSection) => {
                                    const optionsSectionElements = optionsSection
                                        .filter((o) =>
                                            o.text?.toLowerCase().includes(normalizedQuery),
                                        )
                                        .map((option) => (
                                            <Dropdown.Option
                                                key={`${option.value}`}
                                                value={option.value}
                                            >
                                                <OptionEssence option={option} query={query} />
                                            </Dropdown.Option>
                                        ));
                                    optionsSectionElements.push(<Dropdown.Divider />);
                                    return optionsSectionElements;
                                })
                                .flat()
                                .slice(0, -1)}
                        </Dropdown.EmptySearch>
                    )}
                </>
            )}
        </Dropdown.Sheet>
    );
};
