import { ComponentProps, ReactNode } from "react";
import { Tooltip } from "../common";
import { Icons } from "../icons";

export type HelperOptions = Partial<Omit<ComponentProps<typeof Tooltip>, "isHoveredContent" | "content" | "children">>;

export const getClickHelper = (labelHelper: ReactNode | undefined, options?: HelperOptions) => {
    if (labelHelper === undefined) {
        return null;
    }

    return (
        <Tooltip isHoveredContent content={labelHelper} {...options}>
            <Icons.Help />
        </Tooltip>
    );
};

export const getHoverHelper = (labelHelper: ReactNode | undefined, options?: HelperOptions) => {
    if (labelHelper === undefined) {
        return null;
    }

    return (
        <Tooltip isHoveredContent content={labelHelper} {...options}>
            <Icons.TooltipQuestion size={14} />
        </Tooltip>
    );
};
