import type { Notification } from "@fm-frontend/uikit";
import { Icon } from "@fm-frontend/uikit";
import { CreateNotificationPayload } from "../feature/app";

type Arg0 = CreateNotificationPayload & {
    id: string;
};

const getIcon = (type: CreateNotificationPayload["type"]) => {
    switch (type) {
        case "error":
            return <Icon name="error" />;
        case "success":
            return <Icon name="success" />;
        case "inbox":
            return <Icon name="inbox" />;
    }
};

export const buildNotification = (notification: Arg0): Notification => {
    const { id, type, content } = notification;

    return {
        id,
        icon: getIcon(type),
        content,
    };
};
