import React from "react";
import styled, { css } from "styled-components";
import { useTabContext } from "./utils";

const Container = styled.div<{ $overflow: boolean }>`
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;

    ${(p) =>
        p.$overflow &&
        css`
            overflow: auto;
        `}
`;

export const TabPanel: React.FC<{ value: string; overflow?: boolean; className?: string }> = (props) => {
    const { value, overflow = false, className, children } = props;
    const context = useTabContext();

    return <>{context?.value === value ? <Container className={className} $overflow={overflow}>{children}</Container> : null}</>;
};
