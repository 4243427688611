import { kFeedNotAvailable } from "utils/ErrorCodes";
import { POSITION_COUNTERPARTY_ID } from "../const/position";

export default {
    names() {
        return ["I", "P", "G", "L", "B", "F", "R", "N"];
    },

    masterFeed(feed) {
        switch (feed) {
            case "C": {
                return "I";
            }
            default: {
                return feed;
            }
        }
    },

    isValidCombination(feed, feedId) {
        switch (feed) {
            case "A":
            case "I":
            case "P":
            case "G":
            case "L":
            case "M":
            case "R":
            case "K":
            case "O":
            case "S":
            case "N": {
                if (0 !== feedId) {
                    return false;
                }
                break;
            }
            case "B":
            case "F": {
                if (0 === feedId || "" === feedId) {
                    return false;
                }
                break;
            }
            default: {
                return false;
            }
        }
        return true;
    },

    applyToSnapshot(snapshot, feed, action, data) {
        switch (feed) {
            case "A": {
                switch (action) {
                    case "S": {
                        snapshot = [...data];
                        break;
                    }
                    case "+": {
                        snapshot = [...snapshot, ...data];
                        break;
                    }
                    case "M": {
                        snapshot = data.reduce((acc, modifyItem) => {
                            const index = acc.findIndex((item) => item.id === modifyItem.id);
                            if (index !== -1) {
                                acc[index] = modifyItem;
                            }
                            return acc;
                        }, snapshot);
                        break;
                    }
                    case "-": {
                        snapshot = snapshot.filter((item) =>
                            data.every((removeItem) => removeItem.id !== item.id),
                        );
                        break;
                    }
                }
                break;
            }
            case "I": {
                switch (action) {
                    case "S": {
                        snapshot = [[...data[0]], [...data[1]]];
                        break;
                    }
                    case "+": {
                        snapshot[1].push(data);
                        break;
                    }
                    case "M": {
                        for (const i in snapshot[1]) {
                            if (snapshot[1][i][1] == data[1]) {
                                snapshot[1][i] = data;
                                break;
                            }
                        }
                        break;
                    }
                    case "-": {
                        for (const i in snapshot[1]) {
                            if (snapshot[1][i][1] == data[1]) {
                                snapshot[1].splice(i, 1);
                                break;
                            }
                        }
                        break;
                    }
                }
                break;
            }
            case "C": {
                switch (action) {
                    case "+": {
                        snapshot[0].push(data);
                        break;
                    }
                    case "M": {
                        for (const i in snapshot[0]) {
                            if (snapshot[0][i][1] == data[1]) {
                                snapshot[0][i] = data;
                                break;
                            }
                        }
                        break;
                    }
                    case "-": {
                        for (const i in snapshot[0]) {
                            if (snapshot[0][i][1] == data[1]) {
                                snapshot[0].splice(i, 1);
                                break;
                            }
                        }
                        break;
                    }
                }
                break;
            }
            case "P": {
                switch (action) {
                    case "S": {
                        snapshot = [
                            data[0],
                            [...data[1].map((item) => [...item])],
                            [...data[2].map((item) => [...item])],
                            [...data[3].map((item) => [...item])],
                        ];
                        break;
                    }
                }
                break;
            }
            case "O": {
                switch (action) {
                    case "S": {
                        snapshot = [...data];
                        break;
                    }
                    case "+": {
                        snapshot.push(data);
                        break;
                    }
                    case "D": {
                        const orderType = data[1];
                        // if deel is manualOrder or liquidation type - do nothing
                        if (orderType === 6 || orderType === 7) {
                            break;
                        }
                        const orderId = data[4];
                        const orderIndexInSnapshot = snapshot.findIndex(
                            (order) => order[4] === orderId,
                        );
                        if (orderIndexInSnapshot > -1) {
                            const sizeOrVolumeLeft = data[8];
                            if (sizeOrVolumeLeft === 0) {
                                snapshot.splice(orderIndexInSnapshot, 1);
                            } else {
                                snapshot[orderIndexInSnapshot][8] = sizeOrVolumeLeft;
                            }
                        }
                        break;
                    }
                    case "-": {
                        for (const i in snapshot) {
                            if (snapshot[i][4] == data[4]) {
                                snapshot.splice(i, 1);
                                break;
                            }
                        }
                        break;
                    }
                }
                break;
            }
            case "S": {
                switch (action) {
                    case "S": {
                        snapshot = [...data];
                        break;
                    }
                    case "+": {
                        snapshot = [...snapshot, ...data];
                        break;
                    }
                    // @TODO: action "D" should be implemented, related settlements logic should be changed
                    case "D":
                    case "-": {
                        snapshot = snapshot.filter((item) =>
                            data.every((removeItem) => removeItem.id !== item.id),
                        );
                        break;
                    }
                }
                break;
            }
            case "G": {
                switch (action) {
                    case "S": {
                        snapshot = [...data];
                        break;
                    }
                    case "+": {
                        snapshot.push(data);
                        break;
                    }
                    case "M": {
                        // when the user is Master there are 2 limits in one array
                        if (Array.isArray(data) && data.length === 2) {
                            snapshot = data;
                            break;
                        }
                        snapshot = [data];
                        break;
                    }
                    case "-": {
                        for (const i in snapshot) {
                            if (snapshot[i][0] == data[0]) {
                                snapshot.splice(i, 1);
                                break;
                            }
                        }
                        break;
                    }
                }
                break;
            }
            case "M":
            case "L": {
                switch (action) {
                    case "S": {
                        snapshot = [...data];
                        break;
                    }
                    case "+": {
                        snapshot.push(data);
                        break;
                    }
                    case "M": {
                        for (const i in snapshot) {
                            if (
                                snapshot[i][POSITION_COUNTERPARTY_ID] ===
                                data[POSITION_COUNTERPARTY_ID]
                            ) {
                                snapshot[i] = data;
                                break;
                            }
                        }
                        break;
                    }
                    case "-": {
                        for (const i in snapshot) {
                            if (
                                snapshot[i][POSITION_COUNTERPARTY_ID] ===
                                    data[POSITION_COUNTERPARTY_ID]
                            ) {
                                snapshot.splice(i, 1);
                                break;
                            }
                        }
                        break;
                    }
                }
                break;
            }
            case "B":
            case "F": {
                switch (action) {
                    case "S": {
                        snapshot = [[...data[0]], [...data[1]]];
                        break;
                    }
                    case "M": {
                        data[0].forEach((item) => {
                            const side = snapshot[0];
                            switch (item[0]) {
                                case "+": {
                                    let found = false;
                                    for (const i in side) {
                                        if (side[i][0] < item[1]) {
                                            side.splice(i, 0, [item[1], item[2]]);
                                            found = true;
                                            break;
                                        }
                                    }
                                    if (!found) {
                                        side.push([item[1], item[2]]);
                                    }
                                    break;
                                }
                                case "M": {
                                    for (const i in side) {
                                        if (side[i][0] == item[1]) {
                                            side[i][1] = item[2];
                                            break;
                                        }
                                    }
                                    break;
                                }
                                case "-": {
                                    for (const i in side) {
                                        if (side[i][0] == item[1]) {
                                            side.splice(i, 1);
                                            break;
                                        }
                                    }
                                    break;
                                }
                                case "~": {
                                    if (item[1]) {
                                        for (const i in side) {
                                            if (side[i][0] == item[1]) {
                                                side[i][1] = item[2];
                                                if (i > 0) {
                                                    side.splice(0, i);
                                                }
                                                break;
                                            }
                                        }
                                    } else {
                                        side.length = 0;
                                    }
                                    break;
                                }
                            }
                        });
                        data[1].forEach((item) => {
                            const side = snapshot[1];
                            switch (item[0]) {
                                case "+": {
                                    let found = false;
                                    for (const i in side) {
                                        if (side[i][0] > item[1]) {
                                            side.splice(i, 0, [item[1], item[2]]);
                                            found = true;
                                            break;
                                        }
                                    }
                                    if (!found) {
                                        side.push([item[1], item[2]]);
                                    }
                                    break;
                                }
                                case "M": {
                                    for (const i in side) {
                                        if (side[i][0] == item[1]) {
                                            side[i][1] = item[2];
                                            break;
                                        }
                                    }
                                    break;
                                }
                                case "-": {
                                    for (const i in side) {
                                        if (side[i][0] == item[1]) {
                                            side.splice(i, 1);
                                            break;
                                        }
                                    }
                                    break;
                                }
                                case "~": {
                                    if (item[1]) {
                                        for (const i in side) {
                                            if (side[i][0] == item[1]) {
                                                side[i][1] = item[2];
                                                if (i > 0) {
                                                    side.splice(0, i);
                                                }
                                                break;
                                            }
                                        }
                                    } else {
                                        side.length = 0;
                                    }
                                    break;
                                }
                            }
                        });
                        break;
                    }
                    case "Z": {
                        if (data === kFeedNotAvailable) {
                            snapshot = { errorCode: data };
                        }
                        break;
                    }
                }
                break;
            }
            case "R": {
                switch (action) {
                    case "S": {
                        snapshot = [[...data[0]], [...data[1]]];
                        break;
                    }
                    case "+": {
                        for (const incoming of data[0]) {
                            snapshot[0].push(incoming);
                        }
                        for (const outgoing of data[1]) {
                            snapshot[1].push(outgoing);
                        }
                        break;
                    }
                    case "-": {
                        for (const incoming of data[0]) {
                            for (const i in snapshot[0]) {
                                // 7 is an index of settlement ID
                                if (snapshot[0][i][7] == incoming[7]) {
                                    snapshot[0].splice(i, 1);
                                    break;
                                }
                            }
                        }
                        for (const outgoing of data[1]) {
                            for (const i in snapshot[1]) {
                                // 7 is an index of settlement ID
                                if (snapshot[1][i][7] == outgoing[7]) {
                                    snapshot[1].splice(i, 1);
                                    break;
                                }
                            }
                        }
                        break;
                    }
                }
                break;
            }
            case "N": {
                switch (action) {
                    case "S": {
                        snapshot = [data[0], [...data[1]], [...data[2]]];
                        break;
                    }
                    case "+": {
                        for (const incoming of data[0]) {
                            snapshot[1].push(incoming);
                        }
                        for (const outgoing of data[1]) {
                            snapshot[2].push(outgoing);
                        }
                        break;
                    }
                    case "M": {
                        for (const incoming of data[0]) {
                            for (const i in snapshot[1]) {
                                if (snapshot[1][i][3] == incoming[3]) {
                                    snapshot[1][i] = incoming;
                                    break;
                                }
                            }
                        }
                        for (const outgoing of data[1]) {
                            for (const i in snapshot[2]) {
                                if (snapshot[2][i][3] == outgoing[3]) {
                                    snapshot[2][i] = outgoing;
                                    break;
                                }
                            }
                        }
                        break;
                    }
                    case "D":
                    case "-": {
                        for (const incoming of data[0]) {
                            if (incoming[10]) {
                                snapshot[0] = incoming[10] + 1;
                            }
                            for (const i in snapshot[1]) {
                                if (snapshot[1][i][3] == incoming[3]) {
                                    snapshot[1].splice(i, 1);
                                    break;
                                }
                            }
                        }
                        for (const outgoing of data[1]) {
                            if (outgoing[10]) {
                                snapshot[0] = outgoing[10] + 1;
                            }
                            for (const i in snapshot[2]) {
                                if (snapshot[2][i][3] == outgoing[3]) {
                                    snapshot[2].splice(i, 1);
                                    break;
                                }
                            }
                        }
                        break;
                    }
                }
                break;
            }
            case "K": {
                switch (action) {
                    case "S": {
                        snapshot = [...data.map((item) => [...item])];
                        break;
                    }
                    case "M": {
                        for (const i in snapshot) {
                            if (snapshot[i][0] == data[0] && snapshot[i][2] == data[2]) {
                                snapshot[i] = data;
                                break;
                            }
                        }
                        break;
                    }
                    case "+": {
                        snapshot.push(data);
                        break;
                    }
                    case "-": {
                        const [currencyName, , counterpartyId] = data;
                        const positionIndexInSnapshot = snapshot.findIndex(
                            ([sCurrencyName, , sCounterpartyId]) =>
                                sCurrencyName === currencyName &&
                                sCounterpartyId === counterpartyId,
                        );

                        snapshot.splice(positionIndexInSnapshot, 1);
                        break;
                    }
                }
                break;
            }
        }
        return snapshot;
    },
};
