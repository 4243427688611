import { VStack } from "@fm-frontend/uikit";
import { startOfDay, subMonths } from "date-fns";
import { useCallback, useMemo, VFC } from "react";
import styled from "styled-components";
import { isEqual } from "lodash";
import { EmDash } from "const";
import { useCounterparties, useInstruments } from "hooks";
import { LS_VARIABLES, useLSState } from "hooks/useLSState";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useUserType } from "store/hooks";
import { historicalTradesFetcher, useDealHistoryParams, useHistoricalTrades } from "store/useHistoricalTrades";
import { Deal, prepareDeal } from "types";
import { fmt } from "utils/format";
import { Content } from "./Content";
import { GroupingOrder, Header, Range } from "./Header";
import { makeExportColumns } from "./exportHeaders";
import {
    useCounterpartiesState,
    useInstrumentsState,
    usePagesState,
    useRangeState
} from "./hooks";
import { groupTradeHistoriesByOrder, mapPreparedDealToTradingHistory } from "./utils";

const DEALS_LIMIT = 250;

const getDefaultRange = () => ({
    startDate: startOfDay(subMonths(new Date(), 1)),
    endDate: new Date(),
});

export const CardContainer = styled(VStack)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;

export const TradesHistory: VFC = () => {
    const { cpIds: counterparties, isLoading: isCounterpartiesLoading } = useCounterparties();
    const { instruments: instrumentList, isLoading: isInstrumentsLoading } = useInstruments();

    const instruments = useMemo(
        () => instrumentList.map((instrument) => instrument.instrumentName),
        [instrumentList],
    );

    const [pages, setPages] = usePagesState([]);
    const [range, setRange] = useRangeState(getDefaultRange());
    const [selectedCounterparties, setSelectedCounterparties] = useCounterpartiesState(
        [],
        isCounterpartiesLoading ? undefined : counterparties,
    );
    const [selectedInstruments, setSelectedInstruments] = useInstrumentsState(
        [],
        isInstrumentsLoading ? undefined : instruments,
    );
    const [groupingOrder, setGroupingOrder] = useLSState<GroupingOrder>(LS_VARIABLES.TRADES_GROUPED_BY, GroupingOrder.ByOrder);


    // TODO: move to separate Component and made request only after CP and Instruments loaded
    const { deals, isLoading, isValidating, mutate } = useHistoricalTrades({
        limit: DEALS_LIMIT,
        counterpartyIds: isCounterpartiesLoading || isInstrumentsLoading ? [] : selectedCounterparties,
        instrument: isCounterpartiesLoading || isInstrumentsLoading ? [] : selectedInstruments,
        from: range.startDate?.getTime(),
        to: range.endDate?.getTime(),
        till: pages[0],
    });
    const hasPrevPage = pages.length > 0;
    const hasNextPage = deals.length === DEALS_LIMIT;

    const preparedData = useMemo(
        () => deals.map((deal) => mapPreparedDealToTradingHistory(prepareDeal(deal))),
        [deals],
    );
    const groupedData = useMemo(
        () => groupingOrder === GroupingOrder.ByOrder ? groupTradeHistoriesByOrder(preparedData) : preparedData,
        [groupingOrder, preparedData],
    );

    const { getCpName } = useCpInfoHelpers();
    const userType = useUserType();
    const requestParams = useDealHistoryParams({ limit: DEALS_LIMIT });
    const getExportData = useCallback(async ({ startDate, endDate }: Range) => {
        const exportColumns = makeExportColumns({ userType });
        let lastId = undefined;
        let exportData: Record<string, any>[] = [];

        while (true) {
            const pageData: Deal[] = await historicalTradesFetcher({
                ...requestParams,
                from: startDate?.getTime(),
                to: endDate?.getTime(),
                till: lastId,
            });
            const preparedPageData = pageData.map((deal) => prepareDeal(deal));
            const exportPageData = preparedPageData.map(
                (preparedDeal) => exportColumns.reduce((row, column) => {
                    const value = column.accessor({
                        ...preparedDeal,
                        counterpartyName: getCpName(preparedDeal.counterpartyId, "full"),
                    });
                    const formattedValue = column.format ? fmt(value ?? "", column.format).copyableValue : value;

                    row[column.Header] = formattedValue === EmDash ? "-" : formattedValue;
                    return  row;
                }, {} as Record<string, any>)
            );
            exportData = exportData.concat(exportPageData);

            if (preparedPageData.length === 0) {
                break;
            }
            if (lastId !== preparedPageData[preparedPageData.length - 1].dealId) {
                lastId = preparedPageData[preparedPageData.length - 1].dealId;
            } else {
                break;
            }
        }

        return exportData;
    }, [requestParams, userType]);

    const handleRangeChange = useCallback(
        ({ startDate, endDate }: Range) => {
            setPages([]);
            setRange({ startDate, endDate });
        },
        [],
    );
    const handleGroupingOrderChange = useCallback(
        (newGroupingOrder: GroupingOrder) => setGroupingOrder(newGroupingOrder),
    [],
    );
    const handleSelectedCounterpartiesChange = useCallback(
        (newCounterparties: number[]) => {
            if (!isEqual(selectedCounterparties, newCounterparties)) {
                setPages([]);
                setSelectedCounterparties(newCounterparties);
            }
        },
        [selectedCounterparties],
    );
    const handleSelectedInstrumentsChange = useCallback(
        (newInstruments: string[]) => {
            if (!isEqual(selectedInstruments, newInstruments)) {
                setPages([]);
                setSelectedInstruments(newInstruments);
            }
        },
        [selectedInstruments],
    );
    const handleRangeReset = useCallback(() => {
        setPages([]);
        setRange(getDefaultRange());
    }, []);
    const handleFilterReset = useCallback(() => {
        setPages([]);
        setSelectedCounterparties([]);
        setSelectedInstruments([]);
    }, []);
    const handleRefresh = useCallback(() => mutate(), [mutate]);
    const handlePrevClick = useCallback(() => {
        setPages(pages.slice(1));
    }, [pages]);
    const handleNextClick = useCallback(() => {
        const lastIndex = preparedData.length - 1;
        const lastId = preparedData[lastIndex].tradeId;
        setPages(lastId !== undefined ? [lastId, ...pages] : pages);
    }, [preparedData, pages]);

    return (
        <VStack margin={[0, 12, 0, 12]}>
            <CardContainer asCard>
                <Header
                    key={`${isCounterpartiesLoading}_${isInstrumentsLoading}`}
                    range={range}
                    groupingOrder={groupingOrder}
                    counterparties={counterparties}
                    instruments={instruments}
                    selectedCounterparties={selectedCounterparties}
                    selectedInstruments={selectedInstruments}
                    isRefreshing={isValidating}
                    getExportData={getExportData}
                    onRangeChange={handleRangeChange}
                    onRangeReset={handleRangeReset}
                    onFilterReset={handleFilterReset}
                    onGroupingOrderChange={handleGroupingOrderChange}
                    onSelectedCounterpartiesChange={handleSelectedCounterpartiesChange}
                    onSelectedInstrumentsChange={handleSelectedInstrumentsChange}
                    onRefresh={handleRefresh}
                />
                <Content
                    data={groupedData}
                    pageItemsCount={preparedData.length}
                    allItemsCount={DEALS_LIMIT * pages.length + preparedData.length}
                    isLoading={isLoading}
                    hasPrevPage={hasPrevPage}
                    hasNextPage={hasNextPage}
                    onPrevClick={handlePrevClick}
                    onNextClick={handleNextClick}
                />
            </CardContainer>
        </VStack>
    );
};
