import { IconButton, Icons, PlainButton } from "@fm-frontend/uikit";
import { useSelector } from "hooks/useSelector";
import { useHistory } from "react-router-dom";
import { actionChannel } from "store/actionChannel";
import { Notification as NotificationItem } from "../Notification/Notification";
import { H2, P, PSBold } from "../styled";
import type { Notification } from "../wsNotifications";
import { wsNotificationReadAll, wsNotificationsLoadMore } from "../wsNotifications";
import {
    Content,
    Description,
    EmptyContent,
    Footer,
    Header,
    LoadMoreButton,
    NotificationList,
    Panda,
    Strong,
} from "./styled";

const SettingsButton = ({ closePopover }: { closePopover: () => void }) => {
    const history = useHistory();
    const handleClick = () => {
        closePopover();
        history.push("/settings/notifications");
    };

    return <IconButton variant="basic" Icon={Icons.Settings} onClick={handleClick} />;
};

const EmptyInboxPopoverContent = ({ closePopover }: { closePopover: () => void }) => (
    <Content>
        <Header>
            <H2>Inbox</H2>
            <SettingsButton closePopover={closePopover} />
        </Header>
        <EmptyContent>
            <Panda>🐼</Panda>
            <P>Very quiet here</P> <br />
            <Description>
                <PSBold>Inbox</PSBold> is the place, where all important notifications will be located.
            </Description>
        </EmptyContent>
    </Content>
);

export const InboxPopoverContent = ({
    newEventsNumber,
    notifications,
    closePopover,
}: {
    newEventsNumber: number;
    notifications: Notification[];
    closePopover: () => void;
}) => {
    const { loading, totalCount } = useSelector((state) => state.wsNotifications);

    if (!notifications.length) {
        return <EmptyInboxPopoverContent closePopover={closePopover} />;
    }

    const lastNotificationId = notifications.at(-1)?.id;
    const hasLoadMoreButton = notifications.length < totalCount;
    const handleLoadMoreClick = () => {
        if (typeof lastNotificationId === "number") {
            actionChannel.put(wsNotificationsLoadMore(lastNotificationId));
        }
    };
    const handleReadAllClick = () => {
        actionChannel.put(wsNotificationReadAll());
    };

    return (
        <Content>
            <Header>
                <H2>Inbox {Boolean(newEventsNumber) && <Strong>{newEventsNumber}</Strong>}</H2>
                <SettingsButton closePopover={closePopover} />
            </Header>
            <NotificationList>
                {notifications.map((props) => (
                    <NotificationItem key={props.id} closePopover={closePopover} {...props} />
                ))}
                {hasLoadMoreButton && (
                    <LoadMoreButton onClick={handleLoadMoreClick} size="medium" fullWidth loading={loading}>
                        Load more
                    </LoadMoreButton>
                )}
            </NotificationList>
            <Footer>
                <PlainButton size="small" onClick={handleReadAllClick}>
                    Mark all as read
                </PlainButton>
            </Footer>
        </Content>
    );
};
