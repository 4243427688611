import { BasicButton, Modal, Tooltip } from "@fm-frontend/uikit";
import { FileContextProvider } from "@fm-frontend/uikit/src/components/dropZone/FileContext";
import { useFormShouldCloseConfirm, useModalControl } from "@fm-frontend/utils";
import { FIXED_MODAL_STYLE } from "const/modal";
import { useModalCloseWithConfirm } from "hooks/useModalCloseWithConfirm";
import { FC, useCallback } from "react";
import styled from "styled-components";
import { useWhitelistingContext } from "../../components/WhitelistingTabContent/whitelistingContext";
import { BULK_EDIT_WHITELISTING_MODAL_KEY } from "../../utils";
import { WhitelistingBulkEditModal } from "../WhitelistingBulkEditModal";

const StyledBasicButton = styled(BasicButton)`
    margin-right: 6px;
`;

type WhitelistingBulkEditButtonProps = {
    disabled?: boolean;
};

export const WhitelistingBulkEditButton: FC<WhitelistingBulkEditButtonProps> = ({ disabled }) => {
    const { refetchWhitelist } = useWhitelistingContext();
    const { isModalOpen, openModal, closeModal } = useModalControl(false);

    const handleModalClose = useCallback(() => {
        refetchWhitelist();
        closeModal();
    }, [refetchWhitelist, closeModal]);

    const shouldConfirmClose = useFormShouldCloseConfirm(BULK_EDIT_WHITELISTING_MODAL_KEY);
    const { closeModalWithConfirm } = useModalCloseWithConfirm(
        shouldConfirmClose,
        handleModalClose,
    );

    return (
        <>
            <Tooltip
                content={
                    disabled
                        ? "Bulk edit will be available when the whitelisting option is ON"
                        : null
                }
            >
                <StyledBasicButton size="small" onClick={openModal} disabled={disabled}>
                    Bulk edit...
                </StyledBasicButton>
            </Tooltip>
            <Modal isOpen={isModalOpen} onClose={closeModalWithConfirm} style={FIXED_MODAL_STYLE}>
                <FileContextProvider>
                    <WhitelistingBulkEditModal onCancel={closeModalWithConfirm} />
                </FileContextProvider>
            </Modal>
        </>
    );
};
