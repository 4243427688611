import {
    DesctructiveButton,
    Flex,
    H2,
    HStack,
    Icons,
    P,
    PlainButton,
    PSmall,
    VStack,
} from "@fm-frontend/uikit";
import { useMemo, useState } from "react";
import styled, { useTheme } from "styled-components";
import { BulkService, BulkUpdateResponse } from "../WhitelistingBulkEditModal/bulkService";

const Details = styled(P)`
    color: ${(p) => p.theme.colors.ui72};
    padding: 0 12px;
`;

const Result = styled.div`
    overflow: auto;
    margin: 8px 12px 12px 12px;
    width: calc(100% - 24px);
    height: 164px;
    border: 1px solid ${(p) => p.theme.colors.ui8};
    border-radius: 8px;
`;

const ResultLine = styled(Flex)`
    padding: 4px 8px 8px 8px;
    align-items: center;
    gap: 8px;
`;

type Props = {
    onClose: () => void;
    onUpdate: (response: BulkUpdateResponse) => Promise<void>;
    response: BulkUpdateResponse;
};

export const WhitelistingBulkEditResultModal: React.FC<Props> = ({
    onClose,
    response,
    onUpdate,
}) => {
    const { colors } = useTheme();
    const [isRetrying, setIsRetrying] = useState(false);

    const failedCount = useMemo(() => response.requestBody.body.instruments.length, [response]);

    const handleRetryClick = async () => {
        try {
            const isRequestFailed = response.error !== null;

            setIsRetrying(true);
            const newOutput = await BulkService.bulkUpdateWhitelisting(response.requestBody);

            if (isRequestFailed) {
                onUpdate(newOutput);
            } else {
                onClose();
            }
        } finally {
            setIsRetrying(false);
        }
    };

    return (
        <VStack minWidth="360px" maxWidth="360px" asCard>
            <VStack padding={12}>
                <H2>Setup results</H2>
            </VStack>
            <Details>
                {failedCount} of {failedCount} requests applied successfully. {failedCount} errors
                occurred. Retry or contact admin.
            </Details>
            <Result>
                {response.requestBody.body.instruments.map((instrument) => (
                    <ResultLine key={instrument}>
                        {response.error !== null ? (
                            <Icons.Attention color={colors.negative100} />
                        ) : (
                            <Icons.CheckShape />
                        )}
                        <PSmall>
                            {instrument}
                            {response.error}
                        </PSmall>
                    </ResultLine>
                ))}
            </Result>
            <HStack padding={12} paddingTop={16} spacing={10}>
                <PlainButton fullWidth size="large" type="button" onClick={onClose}>
                    Skip
                </PlainButton>
                <DesctructiveButton
                    fullWidth
                    size="large"
                    type="submit"
                    loading={isRetrying}
                    onClick={handleRetryClick}
                >
                    Retry {failedCount}
                </DesctructiveButton>
            </HStack>
        </VStack>
    );
};
