import { parse, stringify } from "./json";

export const LS_PREFIX = "efx-value-";

/**
 * Get key with prefix for localStorage value
 * @param key non-namespaced key
 * @returns namespaced key
 */
export function getLSKey(key: string) {
    return `${LS_PREFIX}${key}`;
}

/**
 * Encode and write value to localStorage
 * @param key non-namespaced key
 * @param value any js value
 * @returns @void
 */
export function setLSValue(key: string, value: unknown) {
    localStorage.setItem(getLSKey(key), btoa(stringify(value)));
}

/**
 * Clear localStorage value by key
 * @param key non-namespaced key
 * @returns @void
 */
export function removeLSValue(key: string) {
    localStorage.removeItem(getLSKey(key));
}

/**
 * Read and parse value from localStorage
 * @param key non-namespaced key
 * @returns parsed value or null
 */
export function getLSValue<T>(key: string): T | null {
    const lsValue = localStorage.getItem(getLSKey(key));
    if (lsValue === null) {
        return null;
    }
    try {
        return parse(atob(lsValue));
    } catch {
        localStorage.removeItem(key);
    }
    try {
        const value = parse(lsValue);
        setLSValue(key, value);
        return value;
    } catch {
        return null;
    }
}
