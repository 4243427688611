import React, { Children, cloneElement } from "react";
import styled, { css } from "styled-components";
import { Size } from "./types";

const Container = styled.div<{ size: Size }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;

    background-color: ${(p) => p.theme.colors.ui8};
    border-radius: 8px;

    ${(p) =>
        p.size === "small" &&
        css`
            min-height: 24px;
            padding: 0;
        `}

    ${(p) =>
        p.size === "medium" &&
        css`
            min-height: 32px;
            padding: 2px;
        `}

    ${(p) =>
        p.size === "large" &&
        css`
            min-height: 40px;
            padding: 2px;
        `}
`;

type TabListProps = {
    size?: Size;
    className?: string;
};

export const TabList: React.FC<TabListProps> = (props) => {
    const { children, size = "medium", className } = props;

    return (
        <Container size={size} className={className}>
            {Children.map(children, (child) => {
                if (!React.isValidElement<{ size: Size }>(child)) {
                    return child;
                }

                return cloneElement(child, { size });
            })}
        </Container>
    );
};
