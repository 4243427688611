import { H2 as UIKitH2, P as UIKitP } from "@fm-frontend/uikit";
import styled from "styled-components";

// TODO discuss with designers all possible typography types and move it to UI Kit
export const P = styled(UIKitP)`
    display: inline;
    color: ${(p) => p.theme.colors.ui100};
`;

export const PBold = styled(P)`
    font-weight: 600;
`;

export const PS = styled.span`
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.005em;
`;

export const PSBold = styled(PS)`
    font-weight: 600;
`;

export const H2 = styled(UIKitH2)`
    margin: 0;
`;
