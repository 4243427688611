import { isNullOrUndefined } from "@fm-frontend/utils";
import { DEFAULT_CP_NAME, FIAT_LIST, FMT_CRYPTO_PRECISION, FMT_FIAT_PRECISION } from "../const";
import { createNotification } from "../feature/app";
import { actionChannel } from "../store/actionChannel";
import { fmtDeltaratePercent, FormatConfig } from "./format";

export function when<T>(value: T, jsx: React.ReactNode, jsxFallback: React.ReactNode | null = null) {
    return value ? jsx : jsxFallback;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

export const notTruthy = (item: unknown) => !item || (Array.isArray(item) && item.length === 0);

export const displayError = (error: unknown) => {
    actionChannel.put(
        createNotification({
            type: "error",
            content: String(error),
        }),
    );
};

export function scrollToElement<T extends HTMLElement>(el: T) {
    el.scrollIntoView({ behavior: "smooth" });
}

export const composeUsername = (username?: string | number) =>
    username && typeof username === "string" ? `${username}` : DEFAULT_CP_NAME;

export const transition = (transition: string, ...keys: string[]) => {
    return `transition: ${
        keys.length > 0 ? keys.map((key) => `${key} ${transition}`).join(",") : `all ${transition}`
    };`;
};

export const increaseSpecificity = (times = 1) => new Array(times + 1).fill("&").join("");

export const getCurrencyPlaceholder = (currency: string) => {
    return FIAT_LIST.includes(currency) ? "0.00" : "0.00000000";
};

export const isFiat = (asset: string) => {
    return FIAT_LIST.includes(asset);
};

export const getFmtPrecisionConfig = (asset: string | null): FormatConfig => {
    if (asset && isFiat(asset)) {
        return {
            significantDigits: FMT_FIAT_PRECISION,
            minSignificantDigits: 2,
        };
    }

    return {
        significantDigits: FMT_CRYPTO_PRECISION,
        minSignificantDigits: 2,
    };
};

export const formatOvernightFee = (overnight: number | null | undefined) => {
    if (isNullOrUndefined(overnight)) {
        return null;
    }

    return fmtDeltaratePercent(overnight!, { showZero: true });
};

export const getTabNumericLabel = (itemCount: number) => (itemCount > 0 ? String(itemCount) : "");
