import {
    DiffLightbox,
    FIXED_MODAL_STYLE,
    HStack,
    Modal,
    P,
    PlainButton,
    PrimaryButton,
} from "@fm-frontend/uikit";
import { Gap } from "@fm-frontend/uikit/src/components/common/Gap";
import { useFileContext } from "@fm-frontend/uikit/src/components/dropZone/FileContext";
import { DiffDataResult, EmDash, useModalControl, ValueFormat } from "@fm-frontend/utils";
import format from "date-fns/format";
import { createNotification } from "feature/app";
import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { DiffLightBoxFooterInfo } from "../AssetsBulkEditDiffLightBox";
import { BulkService, BulkUpdateResponse } from "../WhitelistingBulkEditModal/bulkService";
import { WhitelistingDiffTable } from "../WhitelistingBulkEditModal/components/WhitelistingDiffTable";
import { isWhitelisted, WhitelistingImportType } from "../WhitelistingBulkEditModal/utils";
import { WhitelistingBulkEditResultModal } from "../WhitelistingBulkEditResultModal";

const FileInfo = styled(P)`
    color: ${(p) => p.theme.colors.ui52};
`;
const FileErrorInfo = styled(P)`
    color: ${(p) => p.theme.colors.negative100};
`;

type DiffLightBoxProps = {
    importData: WhitelistingImportType[];
    diffResult: DiffDataResult;
    diffTableColumns: string[];
    onClose: () => void;
    onBack: () => void;
};
export const WhitelistingBulkEditDiffLightBox: FC<DiffLightBoxProps> = ({
    importData,
    diffResult,
    diffTableColumns,
    onClose,
    onBack,
}) => {
    const dispatch = useDispatch();
    const [isApplyingUpdates, setIsApplyingUpdates] = useState<boolean>(false);

    const { acceptedFiles } = useFileContext();
    const fileName = acceptedFiles[0]?.name || EmDash;
    const fileInfo = `File size: ${ValueFormat.formatBytes(
        acceptedFiles[0]?.size ?? 0,
    )} Last changes: ${format(acceptedFiles[0]?.lastModified ?? 0, "d MMMM yyyy 'at' HH:mm")}`;

    const [bulkUpdateResult, setBulkUpdateResult] = useState<BulkUpdateResponse | null>(null);
    const { isModalOpen: isBulkUpdateResultModalOpened, openModal: openBulkUpdateResultModal } =
        useModalControl();
    const { closeModal: closeBulkUpdateLightbox, isModalOpen: isBulkUpdateLightboxOpened } =
        useModalControl(true);

    const handleBulkUpdateResult = async (response: BulkUpdateResponse) => {
        closeBulkUpdateLightbox();

        const hasErrors = response.error !== null;

        if (hasErrors) {
            setBulkUpdateResult(response);
            openBulkUpdateResultModal();
        } else {
            dispatch(
                createNotification({
                    type: "success",
                    content: "All changes were successfully applied.",
                }),
            );
            onClose();
        }
    };

    const handleSave = async () => {
        const updatedData = BulkService.mapInstrumentsToRequestBody(
            importData
                .filter(({ whitelisting }) => isWhitelisted(whitelisting))
                .map(({ instrument }) => instrument),
        );

        setIsApplyingUpdates(true);
        const updateResult = await BulkService.bulkUpdateWhitelisting(updatedData);
        setIsApplyingUpdates(false);
        handleBulkUpdateResult(updateResult);
    };

    return (
        <>
            <DiffLightbox isOpen={isBulkUpdateLightboxOpened} onClose={onClose}>
                <DiffLightbox.Header
                    title={fileName}
                    essence={() =>
                        diffResult.hasErrors ? (
                            <FileErrorInfo>
                                Some cells contains wrong values. Overview the issues, correct the
                                initial file and upload again.
                            </FileErrorInfo>
                        ) : (
                            <FileInfo>{fileInfo}</FileInfo>
                        )
                    }
                />
                <DiffLightbox.Body>
                    <WhitelistingDiffTable
                        isLoading={diffResult.isLoading}
                        columns={diffTableColumns}
                        data={diffResult.diff}
                    />
                </DiffLightbox.Body>
                <DiffLightbox.Footer>
                    <DiffLightBoxFooterInfo diffResult={diffResult} />
                    <Gap />
                    <HStack spacing={6}>
                        <PlainButton size="medium" onClick={onBack}>
                            Cancel
                        </PlainButton>
                        {diffResult.hasDiffs && !diffResult.hasErrors && (
                            <PrimaryButton
                                disabled={diffResult.isLoading}
                                size="medium"
                                onClick={handleSave}
                                loading={isApplyingUpdates}
                            >
                                Update
                            </PrimaryButton>
                        )}
                        {!diffResult.hasDiffs && !diffResult.hasErrors && (
                            <PrimaryButton
                                disabled={diffResult.hasErrors || diffResult.isLoading}
                                size="medium"
                                onClick={onClose}
                                loading={isApplyingUpdates}
                            >
                                Continue
                            </PrimaryButton>
                        )}
                    </HStack>
                </DiffLightbox.Footer>
            </DiffLightbox>
            <Modal
                isOpen={isBulkUpdateResultModalOpened}
                onClose={onClose}
                style={FIXED_MODAL_STYLE}
            >
                {bulkUpdateResult && (
                    <WhitelistingBulkEditResultModal
                        onClose={onClose}
                        response={bulkUpdateResult}
                        onUpdate={handleBulkUpdateResult}
                    />
                )}
            </Modal>
        </>
    );
};
