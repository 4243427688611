import { forwardRef, ReactNode, useEffect, useState } from "react";
import styled, { DefaultTheme, StyledComponent } from "styled-components";
import { useWidth } from "../../../hooks";
import { HintVariant, HintVariantComponents } from "../../common/HintVariantComponents";
import { getClickHelper } from "../../helper";
import { TextSmall } from "../../typography";
import { InputProps, InputVariant } from "./Input.types";
import {
    BasicInput,
    BasicInput as MinimumInput,
    BasicLabel,
    Container,
    InputGroup,
    SimpleInput,
    SimpleLabel,
} from "./styled";

const InputVariantComponents: {
    [Key in InputVariant]: StyledComponent<"input", DefaultTheme, { isError?: boolean }, never>;
} = {
    basic: BasicInput,
    simple: SimpleInput,
    minimum: MinimumInput,
};

const LabelVariantComponents: {
    [Key in InputVariant]: StyledComponent<"label", DefaultTheme, { offset: number }, never>;
} = {
    basic: BasicLabel,
    simple: SimpleLabel,
    minimum: styled.label`
        display: none;
    `,
};

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const { variant, label, labelHelper, labelHelperOptions, name, hint, error, disabled, _size = "large" } = props;

    const [hintVariant, setHintVariant] = useState<{ variant: HintVariant; text?: string | ReactNode }>({
        variant: "hint",
    });

    useEffect(() => {
        if (error) {
            setHintVariant({ variant: "error", text: error });
        } else {
            setHintVariant({ variant: "hint", text: hint });
        }
    }, [error, hint]);

    const InputComponent = InputVariantComponents[variant];
    const HintComponent = HintVariantComponents[hintVariant.variant];
    const LabelComponent = LabelVariantComponents[variant];

    const { offset, ref: labelRef } = useWidth({ label });

    return (
        <Container size={_size} isDisabled={disabled}>
            <InputGroup>
                {label && (
                    <LabelComponent offset={offset} ref={labelRef} htmlFor={name}>
                        {label}
                        {getClickHelper(labelHelper, labelHelperOptions)}
                    </LabelComponent>
                )}
                <InputComponent data-field-group ref={ref} isError={hintVariant.variant === "error"} {...props} />
            </InputGroup>
            {hintVariant.text && (
                <HintComponent>
                    <TextSmall>{hintVariant.text}</TextSmall>
                </HintComponent>
            )}
        </Container>
    );
});
