import { Table } from "@fm-frontend/uikit";
import { createColumnHelper, ExpandedState, getCoreRowModel, getExpandedRowModel, Row } from "@tanstack/react-table";
import { BottomFixTableContainer } from "components/BottomFixTableContainer";
import React, { useMemo, useState } from "react";
import { actionColumn, nameColumn, TableEmptyState, toggleColumn, walletColumn } from "../components";
import { BankCell } from "../components/BankCell";
import { BanksStatusCell } from "../components/BanksStatusCell";
import { GroupCellWrapper } from "../components/CellWrapper";
import { GroupRowInfoCell } from "../components/GroupRowInfoCell";
import { PublicDetailsCell } from "../components/PublicDetailsCell";
import { AccountStatusType, AddressesTableProps, AddressGroup, BankAddress, BankTableData } from "../types";
import { isAddressGroupType } from "../utils";

const columnHelper = createColumnHelper<BankTableData>();

const bankNameColumn = columnHelper.accessor((row) => row, {
    header: "Bank name",
    cell: (info) => (
        <GroupCellWrapper
            data={info.getValue()}
            renderItemRow={(itemRow) => <BankCell bankName={itemRow.bankPrimary?.name} />}
        />
    ),
    enableSorting: false,
    meta: {
        headerStyleProps: {
            width: "180px",
            minWidth: "180px",
            maxWidth: "180px",
        },
        cellStyleProps: {
            width: "180px",
            minWidth: "180px",
            maxWidth: "180px",
        },
    },
});

const publicDescriptionColumn = columnHelper.accessor((row) => row, {
    header: "Public description",
    cell: (info) => (
        <GroupCellWrapper
            data={info.getValue()}
            renderItemRow={(itemRow) => <PublicDetailsCell publicDetails={itemRow.publicDetails} />}
        />
    ),
    enableSorting: false,
    meta: {
        headerStyleProps: {
            width: "100%",
        },
        cellStyleProps: {
            width: "100%",
        },
    },
});

const bankStatusColumn = columnHelper.display({
    header: "Status",
    cell: (info) => (
        <GroupCellWrapper
            data={info.row.original}
            renderGroupRow={({ count, needToConfirmCount }) => (
                <GroupRowInfoCell subRowCount={count} needToConfirmCount={needToConfirmCount} />
            )}
            renderItemRow={({ status, revisions }) => <BanksStatusCell status={status} revisions={revisions} />}
        />
    ),
    meta: {
        headerStyleProps: {
            textAlign: "end",
            width: "120px",
            minWidth: "120px",
            maxWidth: "120px",
        },
        cellStyleProps: {
            textAlign: "end",
            width: "120px",
            maxWidth: "120px",
        },
    },
});

const YourBanksTableView: React.FC<{
    totalAddressesCount: number;
    data: BankTableData[];
    isLoading: boolean;
    onRowClick: (row: Row<BankTableData>, cellId: string) => void;
}> = ({ totalAddressesCount, isLoading, onRowClick, data }) => {
    const memoColumns = useMemo(
        () => [nameColumn, bankNameColumn, walletColumn, publicDescriptionColumn, bankStatusColumn, actionColumn],
        [],
    );
    const memoData = useMemo(() => data, [data]);

    return (
        <Table
            tableOptions={{
                data: memoData,
                columns: memoColumns as any[],
                getCoreRowModel: getCoreRowModel(),
            }}
            onRowClick={onRowClick}
            paginator={null}
            isLoading={isLoading}
            renderEmptyState={() => <TableEmptyState defaultActionAvailable={totalAddressesCount === 0} />}
        />
    );
};

const SharedBanksTableView: React.FC<{
    data: BankTableData[];
    isLoading: boolean;
    onRowClick: (row: Row<BankTableData>, cellId: string) => void;
}> = ({ isLoading, onRowClick, data }) => {
    const [expanded, setExpanded] = useState<ExpandedState>(true);

    const memoColumns = useMemo(
        () => [
            toggleColumn,
            nameColumn,
            bankNameColumn,
            walletColumn,
            publicDescriptionColumn,
            bankStatusColumn,
            actionColumn,
        ],
        [],
    );
    const memoData = useMemo(() => data, [data]);

    return (
        <Table
            tableOptions={{
                data: memoData,
                columns: memoColumns as any[],
                getCoreRowModel: getCoreRowModel(),
                getSubRows: (row) => (isAddressGroupType<BankAddress>(row) ? row.addresses : undefined),
                getExpandedRowModel: getExpandedRowModel(),
                state: {
                    expanded,
                },
                onExpandedChange: setExpanded,
            }}
            onRowClick={onRowClick}
            paginator={null}
            isLoading={isLoading}
            renderEmptyState={() => <TableEmptyState />}
        />
    );
};

export const BanksTable: React.FC<AddressesTableProps<BankAddress, BankTableData>> = ({
    totalAddressesCount,
    addresses,
    sharedAddresses,
    side,
    onRowClick,
}) => {
    const isYourSide = side === "your";
    const isSharedSide = side === "shared";

    const groupedSharedAddresses = sharedAddresses.data.reduce<AddressGroup<BankAddress>[]>((acc, cur) => {
        const cp = acc.find((a) => a.cp?.id === cur.rule?.cp?.id);

        if (cp) {
            cp.addresses.push(cur);
            cp.count += 1;

            if (cur.status === AccountStatusType.NeedToConfirm) {
                cp.needToConfirmCount += 1;
            }
        } else {
            acc = [
                ...acc,
                {
                    cp: cur.rule?.cp,
                    addresses: [cur],
                    count: 1,
                    needToConfirmCount: cur.status === AccountStatusType.NeedToConfirm ? 1 : 0,
                } as AddressGroup<BankAddress>,
            ];
        }

        return acc;
    }, []);

    return (
        <BottomFixTableContainer>
            {isYourSide && (
                <YourBanksTableView
                    totalAddressesCount={totalAddressesCount}
                    data={addresses.data}
                    isLoading={addresses.isLoading}
                    onRowClick={onRowClick}
                />
            )}
            {isSharedSide && (
                <SharedBanksTableView
                    data={groupedSharedAddresses}
                    isLoading={sharedAddresses.isLoading}
                    onRowClick={onRowClick}
                />
            )}
        </BottomFixTableContainer>
    );
};
