import { Page } from "style";
import { TopMenu } from "components/TopMenu";
import { TradesHistory, tradesHistoryTextAdoptionDictionary } from "feature/history/trades";
import { TextAdaptorContext } from "hooks/useTextAdaptor";
import { usePrimeBrokerViewType } from "store/hooks";

export const Trades = () => {
    const primeBrokerViewType = usePrimeBrokerViewType();

    return (
        <Page noBottomSpace>
            <TextAdaptorContext.Provider value={tradesHistoryTextAdoptionDictionary}>
                <TopMenu>Trades</TopMenu>
                <TradesHistory key={primeBrokerViewType} />
            </TextAdaptorContext.Provider>
        </Page>
    );
};
