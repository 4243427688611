import { IconButton, Icons } from "@fm-frontend/uikit";
import { getEnv } from "@fm-frontend/utils";
import { Item } from "@react-stately/collections";
import React, { MouseEvent, useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { Badge } from "components/Badge";
import { CopyToClipboard } from "components/CopyToClipboard";
import { OptionSelect } from "components/select/OptionSelect";
import { Tooltip } from "components/Tooltip";
import { SmallButton } from "feature/form/Buttons";
import { MemoTable } from "feature/table";
import { useCreatePrefilledTransactionOrRequest } from "hooks";
import { LS_VARIABLES, useLSState } from "hooks/useLSState";
import { GroupBy, usePositionsGrouped } from "store/usePositionsGrouped";
import { fmt } from "utils/format";
import { CpInfo } from "../CpInfo";
import { PrependContainer } from "./style";
import { columnsPositionsByAsset, columnsPositionsByCounterparty } from "./table";
import styled from "styled-components";

const { HRP_MASTER_ID } = getEnv();

const ActionWrapper = styled.div`
    margin-top: -6px;
`;

// TODO It is copy paste of table from PageTrading for Trading page. Should be moved to one reusable place
export const Positions = () => {
    const history = useHistory();
    const createPrefilledTransactionOrRequest = useCreatePrefilledTransactionOrRequest();

    const [groupBy, setGroupBy] = useLSState<GroupBy>(LS_VARIABLES.POSITIONS_GROUP_BY, "counterparty");

    const { prependData, totalUSD, result, isLoading } = usePositionsGrouped({
        includeSettlementOrders: false,
        groupBy,
    });

    const prependAtIndex = useMemo(() => {
        return Object.fromEntries(
            Object.entries(prependData).map(([key, [cpIdOrAsset, partUSD]]) => {
                const { copyableValue, formattedValue, isNegative } = fmt(partUSD, {
                    significantDigits: 2,
                    type: "size",
                    unit: "$",
                });
                return [
                    key,
                    <PrependContainer>
                        <CopyToClipboard value={cpIdOrAsset}>
                            {groupBy === "counterparty" ? (
                                <Badge>
                                    <CpInfo cpId={Number(cpIdOrAsset)} />
                                </Badge>
                            ) : (
                                <Badge>{cpIdOrAsset}</Badge>
                            )}
                        </CopyToClipboard>
                        <CopyToClipboard value={copyableValue}>
                            <Badge isNegative={isNegative}>
                                <header>{groupBy === "counterparty" ? "Equity" : "Value"}:</header>
                                {formattedValue}
                            </Badge>
                        </CopyToClipboard>
                    </PrependContainer>,
                ];
            }),
        );
    }, [prependData, groupBy]);

    const globalActions = useMemo(
        () => (
            <>
                <SmallButton onClick={() => history.push("/positions")}>Net Open Positions</SmallButton>
            </>
        ),
        [history],
    );
    const titleSlot = useMemo(
        () => (
            <>
                <OptionSelect
                    title="Group by"
                    aria-label="Group by"
                    onSelectionChange={(groupBy) => {
                        if (["asset", "counterparty"].includes(String(groupBy))) setGroupBy(groupBy as GroupBy);
                    }}
                    showValue
                    selectedKey={groupBy}
                >
                    <Item key="counterparty">Group by Counterparty</Item>
                    <Item key="asset">Group by Asset</Item>
                </OptionSelect>
                <CopyToClipboard value={totalUSD.copyableValue}>
                    <Badge isNegative={totalUSD.isNegative}>
                        <header>Total Equity:</header>
                        {totalUSD.formattedValue}
                    </Badge>
                </CopyToClipboard>
            </>
        ),
        [totalUSD, groupBy],
    );
    const actions = useCallback(
        ({ counterparty, asset, value }: typeof result[0]) => {
            if (counterparty === HRP_MASTER_ID) {
                return null;
            }

            return (
                <ActionWrapper>
                    <Tooltip text="Settle">
                        <IconButton
                            variant="basic"
                            Icon={Icons.ArrowUp}
                            onClick={(e: MouseEvent) => {
                                e.preventDefault();
                                createPrefilledTransactionOrRequest({
                                    cpId: counterparty,
                                    asset,
                                    value,
                                });
                            }}
                        />
                    </Tooltip>
                </ActionWrapper>
            );
        },
        [history],
    );
    return (
        <MemoTable
            title="Net Open Positions"
            titleBadge={titleSlot}
            globalActions={globalActions}
            columns={groupBy === "counterparty" ? columnsPositionsByCounterparty : columnsPositionsByAsset}
            data={result}
            actions={actions}
            prependAtIndex={prependAtIndex}
            isLoading={isLoading}
            data-test-id="net-open-positions"
        />
    );
};
