import {
    ClientId,
    EXPAND_TABLE_COLUMN_KEY,
    Flex,
    HStack,
    Icons,
    P,
    PBold,
    PSmall,
    Tooltip,
    VStack,
} from "@fm-frontend/uikit";
import { getEnv } from "@fm-frontend/utils";
import { createColumnHelper } from "@tanstack/react-table";
import { CoinIcon } from "components/CoinIcon";
import { EmDash } from "const";
import { HRPClearingTimeInfoRow, PositionsTableRowData, Subrow } from "feature/positions/types";
import {
    isExpandableRow,
    isGroupedByAssetExpandableRow,
    isGroupedByCpExpandableRow,
    isHRPClearingTimeInfoRow,
    isSubrow,
    isTotalRow,
} from "feature/positions/utils";
import { getLabelWithHighlightedSearch } from "feature/settlements";
import { GroupBy } from "store/usePositionsGrouped";
import styled from "styled-components";
import { fmt, getAbsoluteValue } from "utils/format";
import { CopyableLabel } from "./TransactionLabel";

export const columnHelper = createColumnHelper<PositionsTableRowData | Subrow | HRPClearingTimeInfoRow>();

const Cell = styled(VStack)<{ $isNegative: boolean; $isNegativeSubText?: boolean; $copyable?: boolean }>`
    > p {
        color: ${(p) => (p.$isNegative ? p.theme.colors.negative100 : "inherit")};
    }
    > span {
        color: ${(p) => (p.$isNegativeSubText ? p.theme.colors.negative100 : p.theme.colors.ui72)};
        padding-right: ${(p) => (p.$copyable ? "18px" : 0)};
    }
`;

const ClearingBrokerContainer = styled(VStack)`
    padding: 2px 0;
`;

const PXSmall = styled(PSmall)`
    font-size: 10px;
    line-height: 12px;
`;

const ClearingTime = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui72};
`;

export const PointerCursor = styled(Flex)`
    cursor: pointer;
`;

const shiftedEmDash = (
    <Cell $isNegative={false} paddingRight={18}>
        {EmDash}
    </Cell>
);

const columns = [
    columnHelper.accessor((row) => row, {
        id: "price",
        cell: (rowData) => {
            const row = rowData.getValue();
            if (isExpandableRow(row) || isTotalRow(row) || isHRPClearingTimeInfoRow(row)) return null;
            const isNegative = row.price < 0;

            return (
                <Cell $isNegative={isNegative}>
                    <P>
                        {
                            fmt(row.price, {
                                significantDigits: 2,
                                type: "price",
                            }).formattedValue
                        }
                    </P>
                </Cell>
            );
        },
        enableSorting: false,
        header: "Price, $",
        meta: {
            cellStyleProps: {
                width: "100px",
                textAlign: "right",
            },
            headerStyleProps: {
                textAlign: "right",
            },
        },
    }),
    columnHelper.accessor((row) => row, {
        id: "current",
        cell: (rowData) => {
            const row = rowData.getValue();

            if (isHRPClearingTimeInfoRow(row)) {
                return null;
            }

            if (isExpandableRow(row) || isTotalRow(row)) {
                if (row.valueUSD === 0n) {
                    return shiftedEmDash;
                }

                const { formattedValue: usdPositionText, copyableValue: usdPositionCopyableText } = fmt(row.valueUSD, {
                    significantDigits: 2,
                    type: "price",
                });

                return (
                    <Cell $isNegative={row.valueUSD < 0}>
                        <PBold>
                            <CopyableLabel value={usdPositionCopyableText} title={`$${usdPositionText}`} />
                        </PBold>
                    </Cell>
                );
            }

            const usdPositionText = fmt(getAbsoluteValue(row.valueUSD), {
                significantDigits: 2,
                type: "price",
            }).formattedValue;
            const { formattedValue: positionText, copyableValue: positionCopyableText } = fmt(row.value, {
                significantDigits: 8,
                type: "size64",
            });

            if (row.value === 0n) {
                return shiftedEmDash;
            }

            const isNegative = row.value < 0;

            return (
                <Cell $isNegative={isNegative} $copyable>
                    <P>
                        <CopyableLabel value={positionCopyableText} title={positionText} />
                    </P>
                    <PSmall>${usdPositionText}</PSmall>
                </Cell>
            );
        },
        enableSorting: false,
        header: "Current",
        meta: {
            cellStyleProps: {
                width: "218px",
                textAlign: "right",
            },
            headerStyleProps: {
                textAlign: "right",
                padding: [0, 18, 8, 0],
            },
        },
    }),
    columnHelper.accessor((row) => row, {
        id: "planned",
        cell: (rowData) => {
            const row = rowData.getValue();

            if (isHRPClearingTimeInfoRow(row)) {
                return null;
            }

            if (isExpandableRow(row) || isTotalRow(row)) {
                if (row.plannedValueUSD === 0n) {
                    return shiftedEmDash;
                }

                const { formattedValue: usdPlannedText, copyableValue: usdPlannedCopyableText } = fmt(
                    row.plannedValueUSD,
                    {
                        significantDigits: 2,
                        type: "size",
                    },
                );

                return (
                    <Cell $isNegative={row.plannedValueUSD < 0}>
                        <PBold>
                            <CopyableLabel value={usdPlannedCopyableText} title={`$${usdPlannedText}`} />
                        </PBold>
                    </Cell>
                );
            }

            const usdPlannedText = fmt(getAbsoluteValue(row.plannedValueUSD), {
                significantDigits: 2,
                type: "size",
            }).formattedValue;
            const { formattedValue: plannedText, copyableValue: plannedCopyableText } = fmt(row.plannedValue, {
                significantDigits: 8,
                type: "size64",
            });
            if (row.plannedValue === 0n) {
                return shiftedEmDash;
            }
            const isNegative = row.plannedValue < 0;

            return (
                <Cell $isNegative={isNegative} $copyable>
                    <P>
                        <CopyableLabel value={plannedCopyableText} title={plannedText} />
                    </P>
                    <PSmall>${usdPlannedText}</PSmall>
                </Cell>
            );
        },
        enableSorting: false,
        header: () => (
            <HStack spacing={4}>
                Planned{" "}
                <Tooltip content="Planned position includes uncommitted pending settlement transactions.">
                    <Icons.Help />
                </Tooltip>
            </HStack>
        ),
        meta: {
            cellStyleProps: {
                width: "218px",
                textAlign: "right",
            },
            headerStyleProps: {
                textAlign: "right",
                padding: [0, 18, 8, 0],
            },
        },
    }),
    columnHelper.accessor((row) => row, {
        id: "overnight",
        cell: (rowData) => {
            const row = rowData.getValue();
            if (isExpandableRow(row) || isTotalRow(row) || isHRPClearingTimeInfoRow(row)) return null;

            const usdOvernightText = fmt(row.estOvernightCost, {
                significantDigits: 2,
                type: "size",
            }).formattedValue;

            if (row.estOvernightCost === 0n) {
                return EmDash;
            }

            const isNegative = row.value < 0;
            const overnightText = fmt(row.overnightCost, {
                significantDigits: 2,
                type: "size",
            }).formattedValue;

            return (
                <Cell $isNegative={isNegative} $isNegativeSubText={isNegative}>
                    <P>${usdOvernightText}</P>
                    <PSmall>{overnightText}%</PSmall>
                </Cell>
            );
        },
        enableSorting: false,
        header: "Overnight",
        meta: {
            cellStyleProps: {
                width: "140px",
                textAlign: "right",
            },
            headerStyleProps: {
                textAlign: "right",
            },
        },
    }),
];

const { HRP_MASTER_ID, HRP_CLEARING_TIME } = getEnv();

export const getColumns = ({ query, groupBy }: { query: string; groupBy: GroupBy }) => [
    columnHelper.display({
        id: EXPAND_TABLE_COLUMN_KEY,
        header: ({ table }) => {
            const isAllRowsExpanded = table.getIsAllRowsExpanded();

            return (
                <PointerCursor onClick={table.getToggleAllRowsExpandedHandler()}>
                    {isAllRowsExpanded ? <Icons.CollapseAll /> : <Icons.ExpandAll />}
                </PointerCursor>
            );
        },
        cell: ({ row }) => {
            if (
                isHRPClearingTimeInfoRow(row.original) ||
                (isSubrow(row.original) && groupBy === "asset" && row.original.cpId === HRP_MASTER_ID)
            ) {
                return <Icons.WashBasin />;
            }

            return !row.getCanExpand() ? null : row.getIsExpanded() ? <Icons.Collapse /> : <Icons.Expand />;
        },
        meta: {
            headerStyleProps: {
                width: "32px",
                maxWidth: "32px",
            },
            cellStyleProps: {
                width: "32px",
                maxWidth: "32px",
            },
        },
    }),
    columnHelper.accessor((row) => row, {
        id: "name",
        cell: (rowData) => {
            const row = rowData.getValue();

            if (isHRPClearingTimeInfoRow(row)) {
                return <ClearingTime>All positions will be cleared at {HRP_CLEARING_TIME}</ClearingTime>;
            }

            if (isTotalRow(row)) {
                return <PBold>Global total</PBold>;
            }

            if (isGroupedByAssetExpandableRow(row)) {
                const { asset } = row;

                return (
                    <HStack spacing={6} alignItems="center">
                        <CoinIcon coin={asset} size={16} />
                        <PBold>{getLabelWithHighlightedSearch(asset, query)}</PBold>
                    </HStack>
                );
            }

            if (isGroupedByCpExpandableRow(row)) {
                const { cpName, cpId } = row;

                if (cpId === HRP_MASTER_ID) {
                    return (
                        <ClearingBrokerContainer>
                            <HStack spacing={6} alignItems="center">
                                <PBold>{getLabelWithHighlightedSearch(cpName, query)}</PBold>
                                <ClientId id={cpId} />
                            </HStack>
                            <PXSmall>Clearing broker</PXSmall>
                        </ClearingBrokerContainer>
                    );
                }

                return (
                    <HStack spacing={6} alignItems="center">
                        <PBold>{getLabelWithHighlightedSearch(cpName, query)}</PBold>
                        <ClientId id={cpId} />
                    </HStack>
                );
            }

            if (isSubrow(row)) {
                const { cpName, cpId, asset } = row;

                if (groupBy === "counterparty") {
                    return (
                        <HStack spacing={6} alignItems="center">
                            <CoinIcon coin={asset} size={16} />
                            <P>{getLabelWithHighlightedSearch(asset, query)}</P>
                        </HStack>
                    );
                }

                if (groupBy === "asset") {
                    if (cpId === HRP_MASTER_ID) {
                        return (
                            <ClearingBrokerContainer>
                                <HStack spacing={6} alignItems="center">
                                    <P>{getLabelWithHighlightedSearch(cpName, query)}</P>
                                    <ClientId id={cpId} />
                                </HStack>
                                <PXSmall>Clearing broker</PXSmall>
                            </ClearingBrokerContainer>
                        );
                    }

                    return (
                        <HStack spacing={6} alignItems="center">
                            <P>{getLabelWithHighlightedSearch(cpName, query)}</P>
                            <ClientId id={cpId} />
                        </HStack>
                    );
                }
            }

            return null;
        },
        enableSorting: false,
        header: "Name",
    }),
    ...columns,
];
