import { ClientType, DealSide, PreparedDeal } from "types";
import { ExportColumnType } from "../types";
import { deltaCellFormatConfig, priceCellFormatConfig, sizeCellFormatConfig, volumeCellFormatConfig } from "./cells";

type Data = PreparedDeal & { counterpartyName: string; };

const generalColumns: ExportColumnType<Data>[] = [
    {
        Header: "Order ID",
        accessor: (preparedDeal) => preparedDeal.orderId,
    },
    {
        Header: "Client ID",
        accessor: (preparedDeal) => preparedDeal.counterpartyId,
    },
    {
        Header: "Client name",
        accessor: (preparedDeal) => preparedDeal.counterpartyName,
    },
    {
        Header: "Instrument",
        accessor: (preparedDeal) => preparedDeal.instrument,
    },
    {
        Header: "Side",
        accessor: (preparedDeal) => (preparedDeal.side === DealSide.Buy ? "Buy" : "Sell"),
    },
    {
        Header: "Price",
        accessor: (preparedDeal) => preparedDeal.price,
        format: priceCellFormatConfig,
    },
    {
        Header: "Size",
        accessor: (preparedDeal) => preparedDeal.size,
        format: sizeCellFormatConfig,
    },
    {
        Header: "Volume",
        accessor: (preparedDeal) => preparedDeal.volume,
        format: volumeCellFormatConfig,
    },
];

const tradeIdColumn: ExportColumnType<Data> = {
    Header: "Trade ID",
    accessor: (preparedDeal) => preparedDeal.dealId,
};
const deltaColumn: ExportColumnType<Data> = {
    Header: "Delta",
    accessor: (preparedDeal) => preparedDeal.delta,
    format: deltaCellFormatConfig,
};
const linkedTradeColumn: ExportColumnType<Data> = {
    Header: "Linked trade",
    accessor: (preparedDeal) => preparedDeal.linkedDealId,
};
const dateColumn: ExportColumnType<Data> = {
    Header: "Date",
    accessor: (preparedDeal) => preparedDeal.dealMoment,
    format: { type: "timems", withTime: true },
};
const clientOrderIdColumn: ExportColumnType<Data> = {
    Header: "Client order ID",
    accessor: (preparedDeal) => preparedDeal.clientOrderId,
};

const pbPartColumns = [deltaColumn, tradeIdColumn, linkedTradeColumn, dateColumn, clientOrderIdColumn];
const makerPartColumns = [deltaColumn, tradeIdColumn, dateColumn, clientOrderIdColumn];
const takerPartColumns = [tradeIdColumn, dateColumn, clientOrderIdColumn];

export const makeExportColumns = (config: { userType: ClientType | undefined }) => {
    let columns: ExportColumnType<Data>[] = [...generalColumns];

    if (config.userType === "primeBroker") {
        columns = [...columns, ...pbPartColumns];
    }
    if (config.userType === "maker" || config.userType === "subaccountMaker") {
        columns = [...columns, ...makerPartColumns];
    }
    if (config.userType === "taker" || config.userType === "subaccountTaker") {
        columns = [...columns, ...takerPartColumns];
    }

    return columns;
};
