import { H2, Modal, ShadowCard, TextLarge, TextSmall } from "@fm-frontend/uikit";
import { Tooltip } from "components/Tooltip";
import { onHover } from "const";
import { EnableTrading } from "feature/counterparties/counterpartyLimitModal/enableTrading/EnableTrading";
import { LimitTradingStatus, parseLimit } from "feature/counterparties/utils";
import { DummyButton } from "feature/form/Buttons";
import { FormError } from "feature/form/style";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useListTransition } from "hooks/useTransition";
import { useCallback, useMemo, useState } from "react";
import { useCounterpartyLimitsSnapshot, useIsSubaccountUser } from "store/hooks";
import styled from "styled-components";

export const Widget = styled.article`
    background: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin-left: 6px;
    padding: 5px 8px;
    min-width: 107px;
    transition: background-color ${(p) => p.theme.transitions.default};

    && {
        ${onHover} {
            background-color: ${({ theme: { colors } }) => colors.selectBgHover};
        }
    }

    header {
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 10px;
        letter-spacing: -0.005em;
        text-align: start;

        color: #999999;
    }

    section {
        height: 16px;
        margin-top: 4px;
        display: flex;
    }
`;

const LP = styled.span<{ disabled?: boolean }>`
    color: ${(p) => (p.disabled ? p.theme.colors.textSubdued2 : p.theme.colors.textFG)};
    background: #ffffff;
    border-radius: 16px;
    margin: 0 2px;
    font-size: 11px;
    line-height: 10px;
    display: inline-block;
    min-width: 16px;

    letter-spacing: -0.005em;

    font-weight: 600;
    padding: 3px 4px;
`;

const Container = styled(ShadowCard)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: 360px;
    gap: 10px;

    max-height: 600px;

    overflow: auto;
    white-space: normal;

    ${H2} {
        margin: 0;
    }

    & > ${TextLarge} {
        margin: 0;
        opacity: unset;
        color: ${(p) => p.theme.colors.ui52};
    }

    & > ${TextSmall} {
        margin: 0;
        font-weight: 600;
        color: ${(p) => p.theme.colors.ui52};
    }
`;

export const LPManagement = () => {
    const isSubaccountUser = useIsSubaccountUser();
    const { data: counterpartyLimits } = useCounterpartyLimitsSnapshot();

    const [error, setError] = useState<string | null>(null);

    const allLimits = useMemo(() => {
        return [
            ...counterpartyLimits.map((limit) => {
                const { counterpartyId, tradingStatus } = parseLimit(limit);
                return {
                    counterpartyId,
                    key: counterpartyId,
                    limit,
                    isEnabled:
                        tradingStatus === LimitTradingStatus.ENABLED ||
                        tradingStatus === LimitTradingStatus.DISABLED_BY_CP,
                };
            }),
        ];
    }, [counterpartyLimits]);

    const limitsSnapshot = useMemo(() => {
        return [...allLimits].sort((a, b) => {
            if (a.isEnabled === b.isEnabled) {
                return a.limit[6] - b.limit[6];
            }

            return a.isEnabled ? -1 : 1;
        });
    }, [allLimits]);

    const transitionedList = useListTransition(limitsSnapshot);
    const enabledLimitsCount = useMemo(
        () => limitsSnapshot.filter((limit) => limit.isEnabled).length,
        [limitsSnapshot],
    );

    const widgetLimits = useMemo(() => {
        return limitsSnapshot.slice(0, 4);
    }, [limitsSnapshot]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleClose = useCallback(() => setIsModalOpen(false), []);

    const { getCpName } = useCpInfoHelpers();

    if (isSubaccountUser) {
        return null;
    }

    return (
        <>
            {allLimits.length > 0 ? (
                <DummyButton
                    onClick={(e) => {
                        e.preventDefault();
                        setIsModalOpen(true);
                    }}
                >
                    <Widget>
                        <header>{enabledLimitsCount} CP enabled</header>
                        <section>
                            {widgetLimits.map(({ key, counterpartyId, isEnabled }) => (
                                <LP disabled={!isEnabled} key={key}>
                                    {counterpartyId}
                                </LP>
                            ))}
                        </section>
                    </Widget>
                </DummyButton>
            ) : (
                <Tooltip text="There is no CP with the set limits. Please set them on the Limits page">
                    <Widget>
                        <header>No CP Limits set</header>
                        <section>
                            <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M13 8C13 10.7614 10.7614 13 8 13C6.98096 13 6.03312 12.6951 5.2427 12.1717L12.1717 5.2427C12.6951 6.03312 13 6.98096 13 8ZM3.82846 10.7575L10.7575 3.82846C9.96703 3.3049 9.01912 3 8 3C5.23858 3 3 5.23858 3 8C3 9.01912 3.3049 9.96703 3.82846 10.7575ZM15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
                                    fill="#CCCCCC"
                                />
                            </svg>
                        </section>
                    </Widget>
                </Tooltip>
            )}
            <Modal isOpen={isModalOpen} onClose={handleClose}>
                <Container>
                    <H2>CP management</H2>
                    <TextLarge>You can stop Limits on a certain CP not to trade with it.</TextLarge>
                    <TextSmall>{enabledLimitsCount} CP enabled</TextSmall>
                    {transitionedList.map(({ key, counterpartyId, limit }) => (
                        <EnableTrading
                            key={key}
                            limit={limit}
                            setError={setError}
                            label={`${getCpName(counterpartyId, "full")} (${counterpartyId})`}
                        />
                    ))}
                    {error && <FormError>{error}</FormError>}
                </Container>
            </Modal>
        </>
    );
};
