import { ClientId, CounterpartyIcon, HStack, P, PBold } from "@fm-frontend/uikit";
import { CurrencyIcon } from "components/CurrencyIcon";
import { EmDash } from "const";
import styled from "styled-components";
import { TSectionItem } from "./SectionItem";

const NetworkText = styled(P)`
    color: ${(p) => p.theme.colors.ui52};
`;

export const fromSectionItem = (cpId: number | undefined, cpName: string = EmDash): TSectionItem => ({
    title: "From",
    value: cpName,
    copyValue: `${cpName} (${cpId})`,
    renderContent: (content) => (
        <HStack spacing={4} alignItems="center">
            <CounterpartyIcon size={16} name={String(cpId)} />
            <PBold ellipsis>{content}</PBold>
            <ClientId id={cpId} />
        </HStack>
    ),
});
export const assetSectionItem = (currency: string = EmDash, network?: string): TSectionItem => ({
    title: "Asset",
    value: currency,
    copyValue: network ? `${currency} (${network})` : currency,
    renderContent: (content) => (
        <HStack spacing={4} alignItems="center">
            <CurrencyIcon size={16} name={content} />
            <PBold ellipsis>{content}</PBold>
            {network && <NetworkText ellipsis>{network}</NetworkText>}
        </HStack>
    ),
});
export const accountSectionItem = (wallet: string = EmDash): TSectionItem => ({
    title: "Account number/IBAN",
    value: wallet,
    copyable: true,
});
export const addressSectionItem = (wallet: string = EmDash): TSectionItem => ({
    title: "Address",
    value: wallet,
    copyable: true,
});
export const bankNameSectionItem = (bankPrimaryName: string = EmDash): TSectionItem => ({
    title: "Bank name",
    value: bankPrimaryName,
    copyable: true,
});
export const bankLegalAddressSectionItem = (bankPrimaryAddress: string = EmDash): TSectionItem => ({
    title: "Bank legal address",
    value: bankPrimaryAddress,
    copyable: true,
});
export const companyNameSectionItem = (companyName: string = EmDash): TSectionItem => ({
    title: "Company name",
    value: companyName,
    copyable: true,
});
export const companyLegalAddressSectionItem = (companyAddress: string = EmDash): TSectionItem => ({
    title: "Company legal address",
    value: companyAddress,
    copyable: true,
});
export const otherDetailsSectionItem = (otherDetails: string = EmDash): TSectionItem => ({
    title: "Other details",
    value: otherDetails,
    copyable: true,
});
export const publicDetailsSectionItem = (publicDetails: string = EmDash): TSectionItem => ({
    title: "Public description, payment instructions",
    value: publicDetails,
    copyable: true,
});
export const memoOrTagSectionItem = (memoOrTag: string = EmDash): TSectionItem => ({
    title: "Memo or tag",
    value: memoOrTag,
    copyable: true,
});
export const privateNoteSectionItem = (privateNote: string = EmDash): TSectionItem => ({
    title: "Private notes",
    value: privateNote,
    copyable: true,
});
