import { CoinIcon } from "components/CoinIcon";
import { IconRefresh, IconStar } from "components/icons";
import { Tooltip } from "components/Tooltip";
import { createNotification } from "feature/app";
import { BuySellButton, DummyButton } from "feature/form/Buttons";
import FormInput from "feature/form/FormInput";
import { FormSelect } from "feature/form/FormSelect";
import { FormConfig, useForm } from "feature/form/useForm";
import { useClickOutside } from "hooks/useClickOutside";
import { ForceDropdownDirection } from "hooks/useDropdownDirection";
import { Subscription, useEventReceiver } from "hooks/useEventEmitter";
import { useIsStarred } from "hooks/useIsStarred";
import { useSelector } from "hooks/useSelector";
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHasMakerRoleUser } from "store/hooks";
import { useLastTradesSWRKey } from "store/useLastTrades";
import { ButtonGroup, Form, Spacer } from "style";
import { useSWRConfig } from "swr";
import { Book } from "types";
import { getCurrencyPlaceholder, when } from "utils";
import { fmt, markSignificantPart } from "utils/format";
import { SelectedAssetContext } from "./selectedAsset";
import { Header, Spread } from "./style";
import { NotEnoughLiquidity, TRADING_LAST_TRADES_LIMIT, weightedAverage } from "./utils";

export type Vals = [number, number];

const typeMakerOptions = [
    { text: "Limit", value: "limit" },
    {
        text: "Post Only",
        value: "postOnly",
    },
];
const typeTakerOptions = [
    {
        text: "Market IOC",
        value: "marketIOC",
    },
    {
        text: "Market FOK",
        value: "marketFOK",
    },
    {
        text: "Limit IOC",
        value: "limitIOC",
    },
    {
        text: "Limit FOK",
        value: "limitFOK",
    },
];

const CardHeader: React.FC<{
    currency: string;
}> = memo(({ currency }) => {
    const coin = useMemo(() => currency.split("-")[1].toLowerCase(), [currency]);
    const contents = useMemo(() => {
        if (currency.length > 18) {
            return <Tooltip text={currency}>{currency.slice(0, 17)}…</Tooltip>;
        }
        return currency;
    }, [currency]);
    const { isStarred, starredHandler } = useIsStarred(currency);
    return (
        <Header>
            <CoinIcon coin={coin} />
            <span>{contents}</span>
            <Spacer />
            <DummyButton onClick={starredHandler}>
                <IconStar isActive={isStarred} />
            </DummyButton>
        </Header>
    );
});

export const TradeForm: React.FC<{
    instrument: string;
    selectedBook: Book;
    isInstrumentSelected: boolean;
    resetErrors: () => void;
}> = ({ instrument, selectedBook, isInstrumentSelected, resetErrors }) => {
    const dispatch = useDispatch();
    const lastTradesSWRKey = useLastTradesSWRKey(TRADING_LAST_TRADES_LIMIT);
    const hasMakerRoleUser = useHasMakerRoleUser();
    const { mutate } = useSWRConfig();

    const ref = useRef<HTMLFormElement>(null);
    useEffect(() => {
        if (isInstrumentSelected)
            ref.current?.scrollIntoView({
                behavior: "smooth",
                inline: "center",
                block: "nearest",
            });
    }, [isInstrumentSelected]);
    const [selectedAction, setSelectedAction] = useState("");
    const [isPairSwitched, setIsPairSwitched] = useState(false);
    const [baseAsset, priceAsset] = useMemo(() => {
        return instrument.split("-");
    }, [instrument]);
    const selectedAsset = useMemo(
        () => (isPairSwitched ? priceAsset : baseAsset),
        [isPairSwitched, priceAsset, baseAsset],
    );
    const { setInstrument } = useContext(SelectedAssetContext);
    useEffect(() => {
        if (selectedAction) {
            setInstrument?.(instrument);
        }
    }, [selectedAction, setInstrument]);

    const fields: FormConfig["fields"] = useMemo(
        () => ({
            instrument: {
                title: "Instrument",
            },
            price: {
                title: "Price",
                type: "price" as const,
                props: {
                    autoComplete: "off",
                },
            },
            size: {
                title: isPairSwitched ? "Volume" : "Size",
                type: "size" as const,
                placeholder: getCurrencyPlaceholder(selectedAsset),
                props: {
                    autoComplete: "off",
                },
            },
            volume: {
                type: "size" as const,
                props: {
                    autoComplete: "off",
                },
            },
            type: {
                type: "select" as const,
                options: hasMakerRoleUser ? typeMakerOptions : typeTakerOptions,
            },
        }),
        [selectedAsset, hasMakerRoleUser],
    );

    const form = useMemo<FormConfig>(() => {
        const form: FormConfig = {
            fields,
            url: "add",
            implicitValues: {
                instrument,
            },
            onChange: ({ implicitValues }) => {
                if (implicitValues.instrument) {
                    setInstrument?.(instrument);
                }
            },
            preSubmit: ({ price, size, ...values }: any) => {
                const finalValues = values;
                if (values.type?.startsWith("limit") || values.type === "postOnly") {
                    finalValues.price = price;
                }
                if (!isPairSwitched) {
                    finalValues.size = size;
                } else {
                    finalValues.volume = size;
                }
                return finalValues;
            },
        };
        return form;
    }, [instrument, isPairSwitched, setInstrument]);
    const {
        propsFor,
        globalError,
        onSubmit,
        setValues,
        areActionsDisabled,
        setGlobalError,
        parsedValues,
        errors,
        inputProps,
        values: formValues,
    } = useForm(form);
    const resetError = useCallback(() => {
        setGlobalError("");
    }, [setGlobalError]);
    const size = useMemo(() => {
        return parsedValues?.size ? (parsedValues?.size as number) : undefined;
    }, [parsedValues]);
    const resetAction = useCallback(() => setSelectedAction(""), [setSelectedAction]);
    useEffect(resetAction, [parsedValues]);
    useClickOutside(resetAction, ref);

    const receiverParams = useMemo<{
        feed: string;
        fn: Subscription;
    }>(() => {
        return {
            feed: "trading",
            fn: (msg) => {
                if (msg?.type === "reset" && (Object.values(errors).length > 0 || globalError)) {
                    resetError();
                    return;
                }
                if (msg?.type === "setPrice" && msg.instrument === instrument) {
                    const values: typeof formValues = { price: msg.value };
                    if (!hasMakerRoleUser) values.type = "limitIOC";
                    setValues((oldValues: typeof formValues) => ({
                        ...oldValues,
                        ...values,
                    }));
                }
            },
        };
    }, [errors, resetError, globalError, setValues, instrument]);
    useEventReceiver(receiverParams);

    const { spread, sellLabel, buyLabel } = useMemo(() => {
        const result: {
            buyLabel?: React.ReactNode;
            sellLabel?: React.ReactNode;
            spread?: React.ReactNode;
        } = {};
        if (hasMakerRoleUser || !size || !isInstrumentSelected) return result;

        let weightedSellPrice: bigint | undefined;
        let weightedBuyPrice: bigint | undefined;
        const [bidLevels, askLevels] = selectedBook;
        if (bidLevels) {
            weightedSellPrice = weightedAverage({
                value: size,
                levels: bidLevels,
                byAsset: !isPairSwitched,
            });
            result.sellLabel =
                weightedSellPrice === NotEnoughLiquidity ? (
                    "Not enough liquidity"
                ) : (
                    <>
                        {markSignificantPart(
                            fmt(weightedSellPrice, {
                                type: "price",
                                significantDigits: 8,
                            }).formattedValue,
                        )}
                        <Spacer /> <span>Sell {baseAsset}</span>
                    </>
                );
        }
        if (askLevels) {
            weightedBuyPrice = weightedAverage({
                value: size,
                levels: askLevels,
                byAsset: !isPairSwitched,
            });
            result.buyLabel =
                weightedBuyPrice === NotEnoughLiquidity ? (
                    "Not enough liquidity"
                ) : (
                    <>
                        {markSignificantPart(
                            fmt(weightedBuyPrice, {
                                type: "price",
                                significantDigits: 8,
                            }).formattedValue,
                        )}
                        <Spacer /> <span>Buy {baseAsset}</span>
                    </>
                );
        }
        if (
            typeof weightedSellPrice === "bigint" &&
            weightedSellPrice !== NotEnoughLiquidity &&
            typeof weightedBuyPrice === "bigint" &&
            weightedBuyPrice !== NotEnoughLiquidity
        ) {
            result.spread = markSignificantPart(
                fmt(weightedBuyPrice - weightedSellPrice, {
                    type: "price",
                    significantDigits: 8,
                }).formattedValue,
            );
        }
        return result;
    }, [hasMakerRoleUser, selectedBook, size, isPairSwitched, baseAsset, isInstrumentSelected]);

    const inputButtonProps = useMemo(() => {
        if (hasMakerRoleUser) return {};
        return {
            buttonTitle: (
                <>
                    <span>{selectedAsset}</span>
                    <IconRefresh />
                </>
            ),
            buttonAction: (e: React.MouseEvent) => {
                e.preventDefault();
                setIsPairSwitched(!isPairSwitched);
            },
            buttonValue: isPairSwitched,
        };
    }, [selectedAsset, hasMakerRoleUser, isPairSwitched]);

    const { showRealTimeNotification } = useSelector((state) => state.settings);

    const successHander = useCallback(
        (success) => {
            if (success && showRealTimeNotification) {
                dispatch(
                    createNotification({
                        type: "success",
                        content: `The ${instrument} ${
                            hasMakerRoleUser ? "order has been placed" : "trade has been done"
                        }.`,
                    }),
                );
            }
            mutate(lastTradesSWRKey);
        },
        [dispatch, instrument, hasMakerRoleUser, showRealTimeNotification, mutate],
    );

    const buyHandler = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            resetErrors();
            if (selectedAction !== "buy") {
                setSelectedAction("buy");
            } else {
                setSelectedAction("");
                onSubmit({
                    side: "bid",
                    instrument,
                }).then(successHander);
            }
        },
        [resetErrors, selectedAction, setSelectedAction, onSubmit, instrument, successHander],
    );
    const sellHandler = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            resetErrors();
            if (selectedAction !== "sell") {
                setSelectedAction("sell");
            } else {
                setSelectedAction("");
                onSubmit({
                    side: "ask",
                    instrument,
                }).then(successHander);
            }
        },
        [resetErrors, selectedAction, setSelectedAction, onSubmit, instrument, successHander],
    );
    const handlerOnSubmit = useCallback(() => false, []);
    const select = useMemo(() => <FormSelect {...inputProps.type} />, [inputProps.type]);
    const input = useMemo(
        () => <FormInput {...inputProps.size} {...inputButtonProps} />,
        [inputProps.size, inputButtonProps],
    );
    return (
        <Form
            type="trading"
            ref={ref}
            onSubmit={handlerOnSubmit}
            isSelected={isInstrumentSelected}
            onClickCapture={() => {
                if (!isInstrumentSelected) setInstrument?.(instrument);
            }}
        >
            <CardHeader currency={instrument} />
            <ForceDropdownDirection horizontal="right" vertical="bottom">
                {select}
            </ForceDropdownDirection>

            {input}
            {!propsFor("type").selectedKey?.startsWith("market") && <FormInput {...propsFor("price")} />}
            {globalError}
            <ButtonGroup>
                <BuySellButton
                    isLocked={selectedAction === "buy"}
                    type="submit"
                    color="green"
                    hasPrice={Boolean(buyLabel)}
                    disabled={areActionsDisabled || buyLabel === "Not enough liquidity"}
                    onClick={buyHandler}
                >
                    {buyLabel || `Buy ${baseAsset}`}
                </BuySellButton>
                {when(spread, <Spread>{spread}</Spread>)}
                <BuySellButton
                    isLocked={selectedAction === "sell"}
                    type="submit"
                    color="red"
                    hasPrice={Boolean(sellLabel)}
                    disabled={areActionsDisabled || sellLabel === "Not enough liquidity"}
                    onClick={sellHandler}
                >
                    {sellLabel || `Sell ${baseAsset}`}
                </BuySellButton>
            </ButtonGroup>
        </Form>
    );
};
