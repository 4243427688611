import {
    Flex,
    FormTab,
    FormTabList,
    IconButton,
    Icons,
    PrimaryButton,
    Search,
    TabContext,
    Tooltip,
    VStack,
} from "@fm-frontend/uikit";
import { DropdownOption, MultipleDropdown } from "@fm-frontend/uikit/src/components/v2";
import { CounterpartiesSheet } from "components/CounterpartiesSheet";
import { CounterpartyTriggerEssence } from "components/CounterpartiesTriggerEssence";
import { CurrencyDropdownSheet } from "components/CurrencySheet";
import { CurrencyTriggerEssence } from "components/CurrencyTriggerEssence";
import { Settlement, SettlementStatus } from "feature/settlements/types";
import { useSettlements } from "feature/settlements/useSettlements";
import { useFilterState } from "hooks/useFilterState";
import { use2WayBinding } from "hooks/use2WayBinding";
import { useMemo, useState, VFC } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { getTabNumericLabel } from "utils";
import { Transaction } from "../types";
import { TransactionsTable } from "./TransactionsTable";
import { useActions } from "./useActions";

const Container = styled(Flex)`
    overflow: auto;
`;

const CardContainer = styled(VStack)`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`;

const Header = styled.div`
    margin: 12px 12px 12px 8px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

const HeaderActions = styled.div`
    display: flex;
    gap: 8px;
    padding-top: 12px;
    padding-left: 4px;

    .search {
        width: 160px;
        input::placeholder {
            font-size: 12px;
        }
    }
`;

const isActionable = ({ status, type }: Partial<Settlement>) => (status === "request" && type === "in") || (status === "created") || (status === "received");
const isDeposits = ({ status, type }: Partial<Settlement>) => type === "in" && status !== "request";
const isWithdrawals = ({ status, type }: Partial<Settlement>) => type === "out" && status !== "request";
const isRequest = ({ status }: Partial<Settlement>) => status === "request";


type FilterValue = {
    statusFilter: Settlement["status"][];
    assetFilter: Settlement["asset"][];
    cpFilter: number[];
};

const STATUS_OPTIONS: DropdownOption<SettlementStatus>[] = [
    {
        text: "Request",
        value: SettlementStatus.request,
    },
    {
        text: "Created",
        value: SettlementStatus.created,
    },
    {
        text: "Sent",
        value: SettlementStatus.sent,
    },
    {
        text: "Received",
        value: SettlementStatus.received,
    },
    {
        text: "Committed",
        value: SettlementStatus.committed,
    },
    {
        text: "Cancelled",
        value: SettlementStatus.cancelled,
    },
];

const PAGE_FILTER_KEY = "pb_subaccount_transactions";

export const PBSubaccountsViewTransactionsPageContent: VFC = () => {
    const { settlements, isLoading } = useSettlements();
    const [selectedFilterTab, setSelectedFilterTab] = useState<
        "all" | "actionable" | "deposits" | "withdrawals" | "requests"
    >("all");
    const {
        commitDeposit,
        createTransaction,
        createTransactionByRequest,
        autoCommitTransaction,
        deleteSettlement,
        actionsContent,
    } = useActions();
    const [searchQuery, setSearchQuery] = useState("");

    const [assetFilter, setAssetFilter, deleteAssetFilter] = useFilterState<Settlement["asset"][]>(
        PAGE_FILTER_KEY,
        "asset",
        [],
        { isArray: true, availableValues: isLoading ? undefined : settlements.map(({ asset }) => asset) },
    );
    const [cpFilter, setCpFilter, deleteCpFilter] = useFilterState<number[]>(
        PAGE_FILTER_KEY,
        "cp",
        [],
        { isArray: true, parseValue: Number, availableValues: isLoading ? undefined : settlements.map(({ cpId }) => cpId) },
    );
    const [statusFilter, setStatusFilter, deleteStatusFilter] = useFilterState<SettlementStatus[]>(
        PAGE_FILTER_KEY,
        "status",
        [],
        { isArray: true, availableValues: Object.values(SettlementStatus) },
    );

    const {
        control,
        watch,
        setValue,
    } = useForm<FilterValue>({
        defaultValues: {
            assetFilter,
            cpFilter,
            statusFilter,
        },
    });

    const assetFilterValue = watch("assetFilter");
    use2WayBinding(
        assetFilterValue,
        (filterValue) => setValue("assetFilter", filterValue),
        assetFilter,
        setAssetFilter,
    );

    const cpFilterValue = watch("cpFilter");
    use2WayBinding(
        cpFilterValue,
        (filterValue) => setValue("cpFilter", filterValue),
        cpFilter,
        setCpFilter,
    );

    const statusFilterValue = watch("statusFilter");
    use2WayBinding(
        statusFilterValue,
        (filterValue) => setValue("statusFilter", filterValue),
        statusFilter,
        setStatusFilter,
    );

    const isDirty =
        assetFilterValue.length !== 0 ||
        cpFilterValue.length !== 0 ||
        statusFilterValue.length !== 0;

    const resetFilter = () => {
        deleteAssetFilter();
        deleteCpFilter();
        deleteStatusFilter();
    };

    const handleCreateTransactionClick = () =>
        createTransaction(
            (selectedFilterTab === "withdrawals" ? "withdrawal" : "deposit") as Transaction["type"],
        );

    const assetFilterOptions: DropdownOption<Settlement["asset"]>[] = [
        ...new Set(settlements.map(({ asset }) => asset)),
    ].map((asset) => ({
        text: asset.toLocaleUpperCase(),
        value: asset,
    }));
    const cpFilterOptions: DropdownOption<number>[] = Object.entries(
        settlements.reduce(
            (acc, { cpId, cpName }) => ({
                ...acc,
                [cpId]: cpName,
            }),
            {},
        ),
    ).map(([cpId, cpName]) => ({
        text: String(cpName),
        value: Number(cpId),
    }));

    const filteredSettlements = useMemo(
        () =>
            settlements.filter(({ type, comment, cpId, cpName, asset, network, status, txId }) => {
                let matched =
                    (statusFilterValue.length === 0 || statusFilterValue.includes(status)) &&
                    (assetFilterValue.length === 0 || assetFilterValue.includes(asset)) &&
                    (cpFilterValue.length === 0 || cpFilterValue.includes(cpId));
                if (matched && selectedFilterTab !== "all") {
                    if (selectedFilterTab === "actionable") {
                        matched = isActionable({ status, type });
                    }
                    if (selectedFilterTab === "deposits") {
                        matched = isDeposits({ status, type });
                    }
                    if (selectedFilterTab === "withdrawals") {
                        matched = isWithdrawals({ status, type });
                    }
                    if (selectedFilterTab === "requests") {
                        matched = isRequest({ status });
                    }
                }

                const query = searchQuery.trim().toLocaleLowerCase();
                if (matched && query) {
                    matched =
                        comment.toLocaleLowerCase().includes(query) ||
                        String(cpId).toLocaleLowerCase().includes(query) ||
                        cpName.toLocaleLowerCase().includes(query) ||
                        Boolean(asset?.toLocaleLowerCase().includes(query)) ||
                        Boolean(network?.toLocaleLowerCase().includes(query)) ||
                        txId.toLocaleLowerCase().includes(query);
                }

                return matched;
            }),
        [settlements, selectedFilterTab, searchQuery, assetFilterValue, cpFilterValue, statusFilterValue],
    );

    const actionableCount = useMemo(
        () =>
            settlements.reduce<number>(
                (acc, settlement) => {
                    if (isActionable(settlement)) {
                        acc++;
                    }
                    return acc;
                },
                0,
            ),
        [settlements],
    );

    return (
        <Container spacing={8} paddingLeft={8} paddingRight={8}>
            <CardContainer flex={1} asCard minWidth="445px">
                <Header>
                    <Flex>
                        <TabContext
                            value={selectedFilterTab}
                            handleClick={(v) =>
                                setSelectedFilterTab(
                                    v as "all" | "actionable" | "deposits" | "withdrawals" | "requests",
                                )
                            }
                        >
                            <FormTabList>
                                <FormTab
                                    title="All"
                                    value="all"
                                />
                                <FormTab
                                    title="Actionable"
                                    label={getTabNumericLabel(actionableCount)}
                                    value="actionable"
                                />
                                <FormTab
                                    title="Deposits"
                                    value="deposits"
                                />
                                <FormTab
                                    title="Withdrawals"
                                    value="withdrawals"
                                />
                                <FormTab
                                    title="Requests"
                                    value="requests"
                                />
                            </FormTabList>
                        </TabContext>
                        <PrimaryButton size="small" onClick={handleCreateTransactionClick}>
                            Add new
                        </PrimaryButton>
                    </Flex>
                    <HeaderActions>
                        <Search
                            size="small"
                            placeholder="Search"
                            query={searchQuery}
                            onChange={(value) => setSearchQuery(value)}
                            className="search"
                        />
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <MultipleDropdown
                                    values={field.value}
                                    onChange={field.onChange}
                                    renderTrigger={(trigger) => (
                                        <MultipleDropdown.Trigger
                                            {...trigger}
                                            size="small"
                                            variant="simple"
                                        >
                                            <CurrencyTriggerEssence
                                                {...trigger}
                                                option={trigger.selectedOptions}
                                                noIcon
                                                size="small"
                                            />
                                        </MultipleDropdown.Trigger>
                                    )}
                                    options={assetFilterOptions}
                                    align="end"
                                    caption="Asset"
                                    asFilter
                                >
                                    <CurrencyDropdownSheet
                                        size="medium"
                                        options={assetFilterOptions}
                                        Dropdown={MultipleDropdown}
                                    />
                                </MultipleDropdown>
                            )}
                            name="assetFilter"
                        />
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <MultipleDropdown
                                    values={field.value}
                                    onChange={field.onChange}
                                    renderTrigger={(trigger) => (
                                        <MultipleDropdown.Trigger
                                            {...trigger}
                                            size="small"
                                            variant="simple"
                                        >
                                            <CounterpartyTriggerEssence
                                                {...trigger}
                                                option={trigger.selectedOptions}
                                                noIcon
                                                size="small"
                                            />
                                        </MultipleDropdown.Trigger>
                                    )}
                                    options={cpFilterOptions}
                                    align="end"
                                    caption="CP"
                                    asFilter
                                >
                                    <CounterpartiesSheet
                                        size="medium"
                                        options={cpFilterOptions}
                                        Dropdown={MultipleDropdown}
                                    />
                                </MultipleDropdown>
                            )}
                            name="cpFilter"
                        />
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <MultipleDropdown
                                    values={field.value}
                                    onChange={field.onChange}
                                    renderTrigger={(trigger) => (
                                        <MultipleDropdown.Trigger
                                            {...trigger}
                                            size="small"
                                            variant="simple"
                                        >
                                            <MultipleDropdown.TriggerEssence
                                                {...trigger}
                                                option={trigger.selectedOptions}
                                                size="small"
                                            />
                                        </MultipleDropdown.Trigger>
                                    )}
                                    options={STATUS_OPTIONS}
                                    align="end"
                                    caption="Status"
                                    asFilter
                                >
                                    <MultipleDropdown.BasicSheet
                                        size="small"
                                        options={STATUS_OPTIONS}
                                    />
                                </MultipleDropdown>
                            )}
                            name="statusFilter"
                        />
                        {isDirty && (
                            <Tooltip content="Reset filter" align="center">
                                <IconButton
                                    variant="plain"
                                    type="button"
                                    Icon={Icons.Recent}
                                    onClick={resetFilter}
                                />
                            </Tooltip>
                        )}
                    </HeaderActions>
                </Header>
                <TransactionsTable
                    data={filteredSettlements}
                    isLoading={isLoading}
                    onCreateTransactionByRequest={createTransactionByRequest}
                    onCommitDepositClick={commitDeposit}
                    onAutoCommitTransactionClick={autoCommitTransaction}
                    onDeleteSettlementClick={deleteSettlement}
                />
            </CardContainer>
            {actionsContent}
        </Container>
    );
};
