import {
    ClientId,
    CounterpartyIcon,
    Flex,
    Header,
    HeaderTitle,
    PBold,
    PSmallBold,
    ShadowItem,
    Skeleton,
    TextSmall,
} from "@fm-frontend/uikit";
import { onMobile } from "const";
import { useSelector } from "hooks";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { setMakerInfoDetailed, StatusKYBVariant } from "store/onboardingSlice";
import styled, { useTheme } from "styled-components";
import { MakerInfo } from "types";
import { StatusKVB } from "./StatusKVB";
import { Body, Property } from "./styled";
import {
    BANKS_LIST_ITEMS_NUMBER,
    getBanksData,
    getCryptoData,
    getFiatData,
    getJurisdictionData,
    getRegulationData,
    getSettlementsData,
} from "./utils";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    cursor: pointer;
`;

const HoverContainer = styled.div`
    :hover {
        background-color: ${(p) => p.theme.colors.ui4};
    }
`;

export const LiquidityProviderCard: React.VFC<{
    makerInfo: MakerInfo;
}> = ({ makerInfo }) => {
    const dispatch = useDispatch();
    const { name, legal_name } = makerInfo;
    const { colors } = useTheme();
    const { statusKYB: statusObj } = useSelector((state) => state.onboarding);
    const statusKYB = statusObj?.[makerInfo.id] ?? StatusKYBVariant.NotStarted;

    const jurisdictionData = useMemo(() => getJurisdictionData(makerInfo), [makerInfo]);
    const cryptoData = useMemo(() => getCryptoData(makerInfo), [makerInfo]);
    const regulationData = useMemo(() => getRegulationData(makerInfo), [makerInfo]);
    const fiatData = useMemo(() => getFiatData(makerInfo), [makerInfo]);
    const banksData = useMemo(() => getBanksData(makerInfo, BANKS_LIST_ITEMS_NUMBER), [makerInfo]);
    const settlementsData = useMemo(() => getSettlementsData(makerInfo), [makerInfo]);

    const handleMakerSelect = () => {
        dispatch(setMakerInfoDetailed(makerInfo));
    };

    return (
        <HoverContainer>
            <ShadowItem padding="16px" isUnderlined>
                <Container onClick={handleMakerSelect}>
                    <Header>
                        <HeaderTitle
                            title={
                                <Flex spacing={4} alignItems="center" width="100%">
                                    <PBold>{name}</PBold>
                                    {statusKYB === StatusKYBVariant.Finished && <ClientId id={makerInfo.id} />}
                                </Flex>
                            }
                            label={legal_name}
                            Icon={<CounterpartyIcon size={36} name={name} />}
                        />
                        <StatusKVB statusKYB={statusKYB} />
                    </Header>
                    <Body>
                        <Property>
                            <TextSmall color={colors.ui72}>{jurisdictionData.title}</TextSmall>
                            <PSmallBold>
                                {jurisdictionData.label} {jurisdictionData.mark}
                            </PSmallBold>
                        </Property>
                        <Property>
                            <TextSmall color={colors.ui72}>{cryptoData.title}</TextSmall>
                            <PSmallBold>
                                {cryptoData.label} {cryptoData.mark}
                            </PSmallBold>
                        </Property>
                        <Property>
                            <TextSmall color={colors.ui72}>{regulationData.title}</TextSmall>
                            <PSmallBold>{regulationData.label}</PSmallBold>
                        </Property>
                        <Property>
                            <TextSmall color={colors.ui72}>{fiatData.title}</TextSmall>
                            <PSmallBold>
                                {fiatData.label} {fiatData.mark}
                            </PSmallBold>
                        </Property>
                        <Property>
                            <TextSmall color={colors.ui72}>{banksData.title}</TextSmall>
                            <PSmallBold>
                                {banksData.label} {banksData.mark}
                            </PSmallBold>
                        </Property>
                        <Property>
                            <TextSmall color={colors.ui72}>{settlementsData.title}</TextSmall>
                            <PSmallBold>{settlementsData.label}</PSmallBold>
                        </Property>
                    </Body>
                </Container>
            </ShadowItem>
        </HoverContainer>
    );
};

const PropertySkeleton = styled.div`
    display: flex;
    width: 50%;
    gap: 8px;

    ${onMobile} {
        width: 100%;
    }
`;

export const LiquidityProviderPlaceholderCard = () => (
    <Container>
        <Header>
            <HeaderTitle
                title={<Skeleton width="88px" height="18px" />}
                Icon={<Skeleton width="36px" height="36px" />}
            />
            <Skeleton width="124px" height="24px" />
        </Header>
        <Body>
            {[...Array(6).keys()].map((i, index) => (
                <PropertySkeleton key={index}>
                    <Skeleton width="88px" height="16px" />
                    <Skeleton width="50%" height="16px" />
                </PropertySkeleton>
            ))}
        </Body>
    </Container>
);
