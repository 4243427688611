import { modalAnimationCss } from "@fm-frontend/uikit";
import { Text } from "feature/auth/style";
import { cover, normalize } from "polished";
import styled, { createGlobalStyle, css, DefaultTheme } from "styled-components";
import { TooltipContainer } from "../components/Tooltip";
import { TopMenuHeader } from "../components/TopMenu";
import { IS_TEST_ENV, onHover, onMobile } from "../const";
import {
    FormFooter,
    FormHeader,
    FormHint,
    FormInfo,
    FormInline,
    FormInputError,
    FormLabel,
    FormValue,
    ModalFormActions,
} from "../feature/form/style";
import { TableContainer } from "../feature/table";
import { TransitionState } from "../hooks/useTransition";
import { Cluster, Spread } from "../pages/trade/trading/style";
import { transition } from "../utils";
import { selectors } from "./theme";

export const Spacer = styled.div`
    flex: 1;
`;

export const GlobalStyle = createGlobalStyle`
    ${normalize()}
    ${modalAnimationCss}
    body {
        background: ${({
            theme: {
                colors: { mainBG },
            },
        }) => mainBG};
        font-family: "InterVariable", sans-serif;
        font-weight: 400;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        margin: 0
    }
    ${
        IS_TEST_ENV &&
        css`
            body {
                background: ${({
                    theme: {
                        colors: { activeBG },
                    },
                }) => activeBG};
            }
            body:after {
                content: "";
                position: fixed;
                top: -50%;
                right: -50%;
                bottom: -50%;
                left: -50%;
                z-index: -1;
                background: ${({
                    theme: {
                        colors: { mainBG },
                    },
                }) => mainBG};
            }
        `
    }

    mark {
        background: transparent;
        color: currentColor;
    }
    ::selection {
        background-color: #DAF6F2;
    }
    :root {
        .container {
            margin: 0 12ch;
            line-height: 2em;
            margin-bottom: 1ch;
        }
        .static {
            ol,
            ul {
                margin-left: 6ch;
                list-style: decimal;
            }
            ol ol,
            ol ul,
            ul ol,
            ul ul {
                margin-left: 6ch;
            }
        }
        font-size: 12px;
    }

    :not([data-dropdown-sheet]):not([data-scroll-container])::-webkit-scrollbar {
        width: 0;
        height: 0;
        background: transparent;
    }
    *:not([data-dropdown-sheet]):not([data-scroll-container]) {
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
    b {
        font-weight: 600;
    }

    *:not(div) {
        outline: none !important;
    }


    // layout component crutches
    h1, h2, h3, h4 {
        font-weight: 100;
    }

    a {
        text-decoration: none;
        color: ${(p) => p.theme.colors.textFG};
    }

    *:not(section) > a:hover {
        text-decoration: underline;
    }

    button, input, input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        border: none;
        padding: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }
    input[type=file] {
        opacity: 0; display: none;
    }

    textarea {
        resize: none;
        border: none;
        font-family: "InterVariable", sans-serif;
    }

    // TODO Remove it after UI Kit update
    // now it's used only for PrimaryButton component
    @-webkit-keyframes spin2 {
        from {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
        }
    }
    @keyframes spin2 {
        from {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
        }
    }

    @media print {
        body {
            background: ${(p) => p.theme.colors.uiWhite100};
            :after {
                background: ${(p) => p.theme.colors.uiWhite100};
            }
        }
    }
`;

export const Overlay = styled.article<{ transitionState: TransitionState }>`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: white;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 75ms 25ms ease-out;
    svg {
        transform: scale(1.1);
        transition: transform 75ms ease-out;
    }

    ${({ transitionState }) => {
        switch (transitionState) {
            case "entering": {
                return css`
                    svg {
                        transform: scale(1.1);
                    }
                `;
            }
            case "active": {
                return css`
                    opacity: 1;
                    svg {
                        transform: scale(1);
                    }
                `;
            }
            case "exiting": {
                return css`
                    svg {
                        transform: scale(1.1);
                    }
                `;
            }
        }
    }}
`;

export const Fieldset = styled.fieldset<{ hasError?: boolean; fullWidth?: boolean }>`
    ${selectors.mixinBody1}
    white-space: nowrap;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
    ${({ fullWidth }) =>
        fullWidth &&
        css`
            width: 100%;
        `}
    ${({ hasError }) =>
        hasError &&
        css`
            input {
                color: ${(p) => p.theme.colors.red};
            }
        `}
`;

export const GuestActions = styled.aside`
    margin: 24px 12px 8px;
`;

export const ButtonGroup = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    button:first-child {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
    }
    button:last-child {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
    }
    button + button,
    ${Spread} + button {
        margin-top: 6px;
    }
    button {
        width: 100%;
        flex: 1;
    }
`;

export const Form = styled.form<{ isSelected?: boolean; type?: "trading" }>`
    ${selectors.mixinBubble}
    background: ${selectors.activeBG};
    min-width: 296px;
    max-width: 380px;
    position: relative;
    z-index: 1;
    cursor: ${({ isSelected }) => (isSelected === false ? "pointer" : "unset")};
    & > * {
        margin: 8px 12px;
    }
    ${({
        type,
        isSelected,
        theme: {
            colors: { brand },
        },
    }) => {
        if (type === "trading")
            return css`
                &:after {
                    position: absolute;
                    top: -3px;
                    right: -3px;
                    bottom: -3px;
                    left: -3px;
                    content: "";
                    ${(p) => transition(p.theme.transitions.long, "border", "transform")}
                    border-radius: 15px;
                    pointer-events: none;
                    user-select: none;
                    border: 1px solid transparent;
                    transform: scale(0.98);
                    z-index: 0;
                    ${isSelected &&
                    css`
                        border: 3px solid ${brand};
                        transform: scale(1);
                    `}
                }
            `;
    }}
    ${ButtonGroup} {
        margin-top: 16px;
        margin-bottom: 12px;
    }
    ${FormHeader} {
        margin: 12px;
    }
    ${FormHint} {
        margin: 6px 12px 16px;
    }
    ${FormHeader} + ${FormLabel} {
        margin-top: 16px;
    }
    ${FormInline} {
        margin: 12px 16px 24px;
        ${FormLabel} + ${FormValue} {
            margin-top: 6px;
        }
        ${FormValue} + ${FormLabel} {
            display: inline-block;
            margin-top: 16px;
        }
    }
    ${FormFooter} {
        margin: 16px;
    }
    ${FormInputError} {
        margin-top: 6px;
    }
    ${FormInfo} {
        margin: 16px 12px 11px;
        & + ${ButtonGroup} {
            margin-top: unset;
        }
    }
    ${ModalFormActions} {
        margin: 16px 12px;
    }
    a {
        color: ${(p) => p.theme.colors.brand};
    }
    hr {
        max-width: 106%;
        margin-left: -12px;
        margin-right: -12px;
        border: none;
        border-bottom: 1px solid #f0eeee;
    }
    ${Text} {
        padding: 0;
    }
    ${TooltipContainer} {
        vertical-align: bottom;
        margin-left: 6px;
    }
`;

export const CompactSelectContainer = styled.div`
    position: relative;
`;

export const InputSelectedValue = styled.div``;

export const InputContainer = styled.div<{
    leftPad?: number;
    rightPad?: number;
    disabled?: boolean;
    buttonValue?: boolean;
    checkboxValue?: boolean;
    forcePlaceholder?: boolean;
}>`
    display: flex;
    align-items: center;
    position: relative;
    border: none;
    width: 100%;
    background: ${(p) => p.theme.colors.smallButtonBG};
    color: ${selectors.textSubdued};
    border-radius: 8px;
    min-height: 44px;
    ${selectors.mixinHover}
    ${(p) =>
        p.disabled &&
        css`
            background: #e6e6e6;
            cursor: not-allowed;
        `}
    ${onHover} {
        z-index: 3;
    }
    & > svg {
        margin: 0 8px;
        rect {
            transition: fill ${(p) => p.theme.transitions.default};
        }
    }
    label {
        position: absolute;
        display: flex;
        user-select: none;
        pointer-events: none;
        left: 12px;
        div {
            margin-left: 6px;
            display: flex;
            align-items: center;
        }
    }
    & > input {
        ${selectors.mixinBody1}
        color: ${(p) => {
            if (p.forcePlaceholder) return selectors.textSubdued2;
            return p.disabled ? p.theme.colors.textSubdued : p.theme.colors.textFG;
        }};
        height: 44px;
        padding: 13px 12px;
        width: 1px;
        flex: 1;
        justify-content: flex-end;
        ${({ leftPad }) =>
            leftPad
                ? css`
                      text-align: end;
                      padding-left: ${leftPad}px;
                  `
                : null}
        ${({ rightPad }) =>
            rightPad
                ? css`
                      padding-right: ${rightPad}px;
                  `
                : null}
        border: none;
        background: none;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        &::placeholder {
            color: ${selectors.textSubdued2};
        }
        &::-webkit-calendar-picker-indicator {
            cursor: pointer;
        }
    }
    button {
        ${selectors.mixinBody1}
        height: 44px;
        display: flex;
        align-items: center;
        background: transparent;
        border-left: 1px solid rgba(190, 190, 190, 0.2);
        cursor: pointer;
        & > span {
            color: ${(p) => p.theme.colors.textSubdued};
            padding: 13px 32px 13px 12px;
        }
        & > svg {
            position: absolute;
            right: 6px;
            margin: 0;
            transition: transform ${(p) => p.theme.transitions.flicker};
            transform: rotate(${({ buttonValue }) => (buttonValue ? 180 : 360)}deg);
        }
    }
    & > *:last-child:not(svg) {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }
    ${InputSelectedValue} {
        padding-left: 12px;
        ${selectors.mixinBody1}
        color: ${selectors.textFG};
    }
    ${(p) => {
        if (typeof p.checkboxValue === "boolean") {
            return css`
                & > svg {
                    border: 1px solid #e5e5e5;
                    border-radius: 8px;
                    ${transition(p.theme.transitions.default, "border", "opacity")}
                }
                &:hover > svg {
                    border: 1px solid ${p.theme.colors.brand};
                    opacity: ${p.checkboxValue === true ? 0.6 : 1};
                }
            `;
        }
    }}
`;

export const Sidebar = styled.section`
    display: flex;
    align-items: flex-start;
    & > :first-child {
        flex: 1;
        margin-right: 8px;
        align-self: flex-start;
    }
    & > :last-child {
        flex: 1;
        overflow: hidden;
    }
    ${onMobile} {
        flex-direction: column;
        & > * {
            width: 100%;
        }
        & > :first-child {
            margin-right: unset;
        }
        & > :last-child {
            flex: 1;
            margin-top: 12px;
        }
    }
`;

export const Page = styled.div<{ noBottomSpace?: boolean }>`
    margin-bottom: ${(p) => (p.noBottomSpace ? 0 : 16)}px;

    ${TopMenuHeader} {
        margin: 11px 12px 0;
    }
    ${TopMenuHeader} + ${TopMenuHeader} {
        margin-top: -6px;
    }
    & > ${Sidebar} {
        margin-right: 12px;
        ${Form} {
            min-width: 296px;
            max-width: 296px;
        }
    }
    & > ${TableContainer} {
        margin-right: 12px;
    }
    ${onMobile} {
        & > ${Sidebar}, & > ${TableContainer}, & > ${Cluster} {
            margin-left: 12px;
        }
        & > ${Sidebar} {
            ${Form} {
                max-width: unset;
            }
        }
    }
`;

export const ColoredText = styled.span<{
    isNegative?: boolean;
    defaultColor?: keyof DefaultTheme["colors"];
}>`
    color: ${({ isNegative, theme, defaultColor }) =>
        isNegative ? theme.colors.red : theme.colors[defaultColor || "textFG"]};
`;

export const Background = styled.div<{ url: string }>`
    ${cover()}
    background: no-repeat 50% 100% url(${({ url }) => url});
    background-size: contain;
    margin: 0;
`;

export const SignParams = styled.div`
    width: 336px;
`;

export const PanelBottom = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 60px;
`;

export const Title = styled.div`
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.005em;
    text-align: left;
    margin-bottom: 24px;
    margin-top: 8px;
`;
