import { all, call, put, select, spawn, takeEvery } from "@redux-saga/core/effects";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { appSlice } from "feature/app";
import { wsSlice } from "feature/app/ws";
import { wsSaga } from "feature/app/ws.saga";
import { authSaga, sagaUpdateClientData } from "feature/auth/auth.saga";
import { wsNotificationsSaga } from "feature/inboxPopover/wsNotifications.saga";
import createSagaMiddleware, { SagaIterator } from "redux-saga";

import { appSaga } from "feature/app/store/saga";
import { wsNotificationsSlice } from "feature/inboxPopover/wsNotifications";
import { onboardingSaga } from "feature/onboarding/onboarding.saga";
import { sagaLoadSettings, sagaSettings, settingSlice } from "feature/settings";
import { actionChannel } from "store/actionChannel";
import { completeNewPassword, confirmSignIn, signOut, verifyMfa } from "store/auth/actions";
import { logout } from "store/auth/logout";
import { isAuthenticated } from "store/auth/selectors";
import { authenticationSlice } from "store/auth/slice";
import { counterpartiesSlice } from "store/counterpartiesSlice";
import { onboardingSlice } from "store/onboardingSlice";

export type IRootState = ReturnType<typeof appReducer>;

export function* actionPortal(action: any) {
    yield put(action);
}

function* actionSaga() {
    yield takeEvery(actionChannel, actionPortal);
}

function* safeCallSagaWithRetry(saga: () => SagaIterator, maxRetries = 5) {
    let count = 0;

    while (count < maxRetries) {
        try {
            yield call(saga);
            break;
        } catch (e) {
            console.error(`Error in saga ${saga.name}:`, e);
            count += 1;
            if (count >= maxRetries) {
                console.error(`Saga ${saga.name} failed after ${maxRetries} retries.`);
            }
        }
    }
}

function* runAuthSagaList() {
    let initialAuthenticated: boolean = yield select(isAuthenticated);

    const sagasToRunWhenAuthenticated = [
        wsSaga,
        wsNotificationsSaga,
        sagaLoadSettings,
        sagaUpdateClientData,
    ];

    if (initialAuthenticated) {
        yield all(sagasToRunWhenAuthenticated.map((saga) => spawn(safeCallSagaWithRetry, saga)));
    }
}

function* watchAuthStatusSaga() {
    yield takeEvery(authenticationSlice.actions.setAuthenticated, runAuthSagaList);
    yield takeEvery(confirmSignIn.fulfilled, runAuthSagaList);
    yield takeEvery(completeNewPassword.fulfilled, runAuthSagaList);
    yield takeEvery(verifyMfa.fulfilled, runAuthSagaList);
    yield takeEvery(signOut.fulfilled, runAuthSagaList);
}

export function* rootSaga() {
    const sagas = [
        actionSaga,
        authSaga,
        onboardingSaga,
        appSaga,
        sagaSettings,
        watchAuthStatusSaga,
    ];

    yield all(
        sagas.map((saga) =>
            spawn(function* () {
                let count = 0;
                while (count < 5) {
                    try {
                        yield call(saga);
                        break;
                    } catch (e) {
                        console.error(e);
                        count += 1;
                    }
                }
            }),
        ),
    );
}

const saga = createSagaMiddleware();
const appReducer = combineReducers({
    app: appSlice.reducer,
    settings: settingSlice.reducer,
    ws: wsSlice.reducer,
    wsNotifications: wsNotificationsSlice.reducer,
    counterparties: counterpartiesSlice.reducer,
    onboarding: onboardingSlice.reducer,
    authentication: authenticationSlice.reducer,
});
const rootReducer: typeof appReducer = (state, action) => {
    if (action.type === logout.toString()) {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};
export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(saga),
});
saga.run(rootSaga);
export type AppDispatch = typeof store.dispatch;

export default store;
