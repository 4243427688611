import { PBold, PXSmall, VStack } from "@fm-frontend/uikit";
import { FC, SyntheticEvent, useMemo, useState } from "react";
import { Subaccount } from "../../api";
import { useWizard } from "../../wizard/useWizard";
import { NavContainer } from "../Container";
import { PlusIcon } from "../PlusIcon";

type AddNewSubaccountButtonProps = {
    subaccounts: Subaccount[];
};

export const AddNewSubaccountButton: FC<AddNewSubaccountButtonProps> = ({ subaccounts }) => {
    const [isPlusActive, setIsPlusActive] = useState(false);
    const { wizard, createSubaccount } = useWizard();
    const { activeCount, pendingCount } = useMemo(
        () =>
            subaccounts.reduce(
                (acc, { status }) => {
                    if (status === "active") acc.activeCount++;
                    if (status === "pending") acc.pendingCount++;
                    return acc;
                },
                {
                    activeCount: 0,
                    pendingCount: 0,
                },
            ),
        [subaccounts],
    );
    const handlePlusFocus = () => {
        setIsPlusActive(true);
    };
    const handlePlusBlur = () => {
        setIsPlusActive(false);
    };
    const handlePlusClick = (e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        createSubaccount();
    };

    return (
        <>
            {wizard}
            <NavContainer to="/subaccounts">
                <VStack>
                    <PBold>Sub-accounts</PBold>
                    <PXSmall>
                        {activeCount > 0 && `${activeCount} active`}
                        {activeCount > 0 && pendingCount > 0 && ", "}
                        {pendingCount > 0 && `${pendingCount} pending`}
                    </PXSmall>
                </VStack>
                <VStack className="plus-icon-container" onPointerEnter={handlePlusFocus} onPointerLeave={handlePlusBlur} onClick={handlePlusClick}>
                    <PlusIcon active={isPlusActive} />
                </VStack>
            </NavContainer>
        </>
    );

};
