import { PSBold } from "feature/inboxPopover/styled";
import styled from "styled-components";
import { InviteStatus } from "hooks/useInvitesList";
import { SubaccountStatus } from "./api";

export type Variant = SubaccountStatus | InviteStatus;

const PillContainer = styled.div<{ variant: Variant }>`
    display: inline-flex;
    padding: 6px 8px;
    border-radius: 12px;
    background-color: ${(p) =>
        (p.variant === SubaccountStatus.Active || p.variant === InviteStatus.Accepted)
            ? p.theme.colors.brand8
            : (p.variant === SubaccountStatus.Pending || p.variant === InviteStatus.Sent)
            ? p.theme.colors.process8
            : p.theme.colors.ui8};
`;

const PillTitle = styled(PSBold)<{ variant: Variant }>`
    line-height: 12px;
    color: ${(p) =>
        (p.variant === SubaccountStatus.Active || p.variant === InviteStatus.Accepted)
            ? p.theme.colors.brand100
            : (p.variant === SubaccountStatus.Pending || p.variant === InviteStatus.Sent)
            ? p.theme.colors.process100
            : p.theme.colors.ui100};
`;
const STATUS_TITLES = {
    [SubaccountStatus.Active]: "Active",
    [SubaccountStatus.Pending]: "Pending",
    [SubaccountStatus.Disabled]: "Disabled",
    [InviteStatus.Accepted]: "Active",
    [InviteStatus.Sent]: "Pending",
    [InviteStatus.Expired]: "Expired",
};
export const Status = ({ variant }: { variant: Variant }) => (
    <PillContainer variant={variant}>
        <PillTitle variant={variant}>{STATUS_TITLES[variant]}</PillTitle>
    </PillContainer>
);
