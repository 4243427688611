import { useTheme } from "styled-components";
import { createColumns } from "../../../feature/table/columns";
import { Deal } from "../../../types";
import { fmt } from "../../../utils/format";

export { columnsPositionsByAsset, columnsPositionsByCounterparty } from "../positions/table";

export const columnsLastTrades = createColumns<Deal>([
    {
        header: "Instrument",
        accessor: (data) => data[0],
        mixin: { fontWeight: 500, align: "left" },
    },
    {
        header: "Side",
        accessor: (data) => (data[2] ? "Sell" : "Buy"),
        getColor: (_, data) => (data[2] ? "red" : "green"),
        mixin: { align: "left" },
    },
    {
        header: "Price",
        accessor: (data) => data[13],
        format: { type: "price", significantDigits: 8 },
        getColor: (_, data) => (data[2] ? "red" : "green"),
    },
    {
        header: "Size",
        accessor: (data) => data[14],
        format: { type: "size", significantDigits: 8 },
    },
    {
        header: "Volume",
        accessor: (data) => data[15],
        format: { type: "size", significantDigits: 8 },
    },
]);

export const columnsInstrumentBook: Array<{
    id: string;
    Header: string;
    width: number;
    minWidth: number;
    accessor: (row: {
        bidSize?: number | bigint;
        bidPrice?: number | bigint;
        askSize?: number | bigint;
        askPrice?: number | bigint;
    }) => string | null;
    Cell?: React.FC<{ value: string | null }>;
}> = [
    {
        id: "Bid Size",
        Header: "Size",
        width: 40,
        minWidth: 140,
        accessor: ({ bidSize } = {}) => {
            if (!bidSize) return null;
            return fmt(bidSize, { type: "size", significantDigits: 8 }).formattedValue;
        },
    },
    {
        id: "Bid Price",
        Header: "Price",
        width: 60,
        minWidth: 130,
        accessor: ({ bidPrice } = {}) => {
            if (!bidPrice) return null;
            return fmt(bidPrice, { type: "size", significantDigits: 8 }).formattedValue;
        },
        Cell: ({ value }) => {
            const {
                colors: { green },
            } = useTheme();
            if (!value) return <span>{value}</span>;
            return <span style={{ color: green }}>{value}</span>;
        },
    },
    {
        id: "Ask Price",
        Header: "Price",
        width: 60,
        minWidth: 130,
        accessor: ({ askPrice } = {}) => {
            if (!askPrice) return null;
            return fmt(askPrice, { type: "size", significantDigits: 8 }).formattedValue;
        },
        Cell: ({ value }) => {
            const {
                colors: { red },
            } = useTheme();
            if (!value) return <span>{value}</span>;
            return <span style={{ color: red }}>{value}</span>;
        },
    },
    {
        id: "Ask Size",
        Header: "Size",
        width: 40,
        minWidth: 140,
        accessor: ({ askSize } = {}) => {
            if (!askSize) return null;
            return fmt(askSize, { type: "size", significantDigits: 8 }).formattedValue;
        },
    },
];
