import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { useOvernightFees } from "hooks/useOvernightFees";
import { useMemo } from "react";
import { ClientType, CpId, Overnight } from "types";
import { fmt } from "utils/format";
import {
    useFilteredAndExtendedPositionsSnapshot,
    useFilteredAndExtendedSettlementOrdersSnapshot,
    useIsMakerUser,
    useIsPrimeBrokerUser,
    useUsdPrices,
} from "./hooks";

export type GroupBy = "asset" | "counterparty";

const getOvernightCostData = (
    groupedOvernightFees: Record<CpId, Overnight[]>,
    asset: string,
    counterparty: number,
    size: bigint,
    isPrimeBrokerUser: boolean,
    isMakerUser: boolean,
    cpType: ClientType,
) => {
    let overnightCost = 0n;
    let estOvernightCost = 0n;
    const overnightFees = groupedOvernightFees[counterparty];

    if (!overnightFees || (isPrimeBrokerUser && cpType === "primeBroker")) {
        return { overnightCost, estOvernightCost };
    }

    const { negativeRate, positiveRate } = overnightFees.find((item) => item.currency === asset) ?? {
        negativeRate: 0,
        positiveRate: 0,
    };

    if (isMakerUser || isPrimeBrokerUser) {
        overnightCost = size < 0 ? BigInt(positiveRate) : BigInt(negativeRate);
    } else {
        overnightCost = size > 0 ? BigInt(positiveRate) : BigInt(negativeRate);
    }

    estOvernightCost = (size * overnightCost) / BigInt(365) / BigInt(1000000);
    overnightCost *= BigInt(10000);

    return { overnightCost, estOvernightCost };
};

export const usePositionsGrouped = ({
    includeSettlementOrders,
    groupBy,
}: {
    includeSettlementOrders: boolean;
    groupBy: "asset" | "counterparty";
}) => {
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const isMakerUser = useIsMakerUser();
    const filteredSettlementOrdersSnapshot = useFilteredAndExtendedSettlementOrdersSnapshot();
    const { getCpName } = useCpInfoHelpers();
    const { groupedOvernightFees, isLoading: overnightFeesLoading } = useOvernightFees();
    const { priceObj, isLoading: usdPricesLoading } = useUsdPrices();
    const filteredExtendedPositionsSnapshot = useFilteredAndExtendedPositionsSnapshot();

    const groupObj = useMemo(() => {
        const groupObj: Record<
            string,
            Record<
                string,
                {
                    value: bigint;
                    plannedValue: bigint;
                    valueUSD: bigint;
                    plannedValueUSD: bigint;
                    price: bigint;
                    counterparty: number;
                    asset: string;
                    overnightCost: bigint;
                    estOvernightCost: bigint;
                }
            > & {
                partUSD: bigint;
                partPlannedUSD: bigint;
            }
        > = {};
        for (const {
            cpType,
            position: [asset, sizeNum, counterparty],
        } of filteredExtendedPositionsSnapshot.data) {
            const size = BigInt(sizeNum);
            const groupId = groupBy === "counterparty" ? counterparty : asset;
            const entryId = groupBy === "counterparty" ? asset : counterparty;
            const groupBalance = groupObj[groupId] || {
                partUSD: 0n,
                partPlannedUSD: 0n,
            };
            const price = priceObj[asset];

            if (!price) {
                continue;
            }
            if (!groupBalance[entryId])
                groupBalance[entryId] = {
                    value: 0n,
                    plannedValue: 0n,
                    valueUSD: 0n,
                    plannedValueUSD: 0n,
                    overnightCost: 0n,
                    estOvernightCost: 0n,
                    price,
                    asset,
                    counterparty,
                };
            groupBalance[entryId].plannedValue += size;
            groupBalance[entryId].value += size;
            const valueUSD = (size * BigInt(price)) / BigInt(100000000) || 0n;
            groupBalance[entryId].valueUSD += valueUSD;
            groupBalance.partUSD += valueUSD;
            groupBalance[entryId].plannedValueUSD += valueUSD;
            groupBalance.partPlannedUSD += valueUSD;
            groupObj[groupId] = groupBalance;
            const { overnightCost, estOvernightCost } = getOvernightCostData(
                groupedOvernightFees,
                asset,
                counterparty,
                groupBalance[entryId].valueUSD,
                isPrimeBrokerUser,
                isMakerUser,
                cpType,
            );
            groupBalance[entryId].overnightCost = overnightCost;
            groupBalance[entryId].estOvernightCost = estOvernightCost;
        }
        if (!includeSettlementOrders) {
            return groupObj;
        }
        for (const {
            cpType,
            settlementOrder: { currency1, currency2, size1, size2, counterpartyId },
        } of filteredSettlementOrdersSnapshot.data) {
            const asset = currency1 || currency2 || "";
            const price = priceObj[asset];
            if (!price) {
                continue;
            }
            const size = BigInt(size1 || size2 || 0);
            const groupId = groupBy === "counterparty" ? counterpartyId : asset;
            const entryId = groupBy === "counterparty" ? asset : counterpartyId;
            const groupBalance = groupObj[groupId] || {
                partUSD: 0n,
                partPlannedUSD: 0n,
            };
            const { overnightCost, estOvernightCost } = getOvernightCostData(
                groupedOvernightFees,
                asset,
                counterpartyId,
                size,
                isPrimeBrokerUser,
                isMakerUser,
                cpType,
            );
            if (!groupBalance[entryId])
                groupBalance[entryId] = {
                    value: 0n,
                    plannedValue: 0n,
                    valueUSD: 0n,
                    plannedValueUSD: 0n,
                    overnightCost: overnightCost,
                    estOvernightCost: estOvernightCost,
                    price,
                    asset,
                    counterparty: counterpartyId,
                };
            groupBalance[entryId].plannedValue += size;
            const plannedValueUSD = (size * price) / BigInt(100000000) || 0n;
            groupBalance[entryId].plannedValueUSD += plannedValueUSD;
            groupBalance.partPlannedUSD += plannedValueUSD;
            groupObj[groupId] = groupBalance;
        }
        return groupObj;
    }, [
        priceObj,
        filteredExtendedPositionsSnapshot,
        filteredSettlementOrdersSnapshot,
        groupBy,
        groupedOvernightFees,
        isPrimeBrokerUser,
    ]);
    const groups = useMemo(() => {
        return Object.keys(groupObj).sort((a: string, b: string) => {
            if (groupBy === "counterparty") return getCpName(a, "short").localeCompare(getCpName(b, "short"));
            return a.localeCompare(b);
        });
    }, [groupObj, getCpName, groupBy]);
    const getGroupName = (assetOrCpIdGroupName: string | number) =>
        groupBy === "asset" ? getCpName(assetOrCpIdGroupName, "short") : String(assetOrCpIdGroupName);
    const { totalUSD, totalPlannedUSD, result, prependData } = useMemo(() => {
        const result: Array<{
            group: string;
            plannedValue: bigint;
            usdValue: bigint;
            usdPlannedValue: bigint;
            item: string;
            value: bigint;
            price: bigint;
            counterparty: number;
            overnightCost: bigint;
            estOvernightCost: bigint;
            asset: string;
            key: string;
        }> = [];
        const prependData: Record<number, [string, bigint, bigint]> = {};
        let totalUSD = 0n;
        let totalPlannedUSD = 0n;
        for (const group of groups) {
            const { partUSD, partPlannedUSD, ...items } = groupObj[group];
            const prependIndex = result.length;
            for (const item of Object.keys(items).sort((a: string, b: string) =>
                getGroupName(a).localeCompare(getGroupName(b)),
            )) {
                const {
                    value,
                    plannedValue,
                    valueUSD,
                    plannedValueUSD,
                    price,
                    asset,
                    counterparty,
                    overnightCost,
                    estOvernightCost,
                } = groupObj[group][item];
                result.push({
                    group,
                    plannedValue,
                    usdValue: valueUSD,
                    usdPlannedValue: plannedValueUSD,
                    item: getGroupName(item),
                    value,
                    price,
                    asset,
                    counterparty,
                    overnightCost,
                    estOvernightCost,
                    key: `${group}:${item}`,
                });
            }
            prependData[prependIndex] = [group, partPlannedUSD, partUSD];
            totalUSD += partUSD;
            totalPlannedUSD += partPlannedUSD;
        }
        return {
            totalUSD: fmt(totalUSD, {
                unit: "$",
                significantDigits: 2,
                type: "size",
            }),
            totalPlannedUSD: fmt(totalPlannedUSD, {
                unit: "$",
                significantDigits: 2,
                type: "size",
            }),
            prependData,
            result,
            groups,
        };
    }, [groupObj, priceObj, groups, groupBy, getCpName]);
    return {
        totalUSD,
        totalPlannedUSD,
        result,
        prependData,
        isLoading:
            filteredSettlementOrdersSnapshot.isLoading ||
            filteredExtendedPositionsSnapshot.isLoading ||
            overnightFeesLoading ||
            usdPricesLoading,
    };
};

export type GroupedPositionsData = Record<
    string,
    {
        subitems: Record<
            string,
            {
                value: bigint;
                plannedValue: bigint;
                valueUSD: bigint;
                plannedValueUSD: bigint;
                price: bigint;
                cpId: number;
                cpName: string;
                asset: string;
                overnightCost: bigint;
                estOvernightCost: bigint;
            }
        >;
        partUSD: bigint;
        partPlannedUSD: bigint;
    }
>;
export const usePositionsTableData = ({
    includeSettlementOrders,
    groupBy,
}: {
    includeSettlementOrders: boolean;
    groupBy: "asset" | "counterparty";
}) => {
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const isMakerUser = useIsMakerUser();
    const filteredSettlementOrdersSnapshot = useFilteredAndExtendedSettlementOrdersSnapshot();
    const { getCpName } = useCpInfoHelpers();
    const { groupedOvernightFees, isLoading: overnightFeesLoading } = useOvernightFees();
    const { priceObj, isLoading: usdPricesLoading } = useUsdPrices();
    const filteredExtendedPositionsSnapshot = useFilteredAndExtendedPositionsSnapshot();

    const groupedPositionsData = useMemo(() => {
        const groupObj: GroupedPositionsData = {};
        for (const {
            cpType,
            position: [asset, sizeNum, counterparty],
        } of filteredExtendedPositionsSnapshot.data) {
            const size = BigInt(sizeNum);
            const groupId = groupBy === "counterparty" ? counterparty : asset;
            const entryId = groupBy === "counterparty" ? asset : counterparty;
            const groupBalance = groupObj[groupId] || {
                subitems: {},
                partUSD: 0n,
                partPlannedUSD: 0n,
            };
            const price = priceObj[asset];

            if (!price) {
                continue;
            }
            if (!groupBalance.subitems[entryId]) {
                groupBalance.subitems[entryId] = {
                    value: 0n,
                    plannedValue: 0n,
                    valueUSD: 0n,
                    plannedValueUSD: 0n,
                    overnightCost: 0n,
                    estOvernightCost: 0n,
                    price,
                    asset,
                    cpId: counterparty,
                    cpName: getCpName(counterparty, "full"),
                };
            }
            groupBalance.subitems[entryId].plannedValue += size;
            groupBalance.subitems[entryId].value += size;
            const valueUSD = (size * BigInt(price)) / BigInt(100000000) || 0n;
            groupBalance.subitems[entryId].valueUSD += valueUSD;
            groupBalance.partUSD += valueUSD;
            groupBalance.subitems[entryId].plannedValueUSD += valueUSD;
            groupBalance.partPlannedUSD += valueUSD;
            groupObj[groupId] = groupBalance;
            const { overnightCost, estOvernightCost } = getOvernightCostData(
                groupedOvernightFees,
                asset,
                counterparty,
                groupBalance.subitems[entryId].valueUSD,
                isPrimeBrokerUser,
                isMakerUser,
                cpType,
            );
            groupBalance.subitems[entryId].overnightCost = overnightCost;
            groupBalance.subitems[entryId].estOvernightCost = estOvernightCost;
        }
        if (!includeSettlementOrders) {
            return groupObj;
        }
        for (const {
            cpType,
            settlementOrder: { currency1, currency2, size1, size2, counterpartyId },
        } of filteredSettlementOrdersSnapshot.data) {
            const asset = currency1 || currency2 || "";
            const price = priceObj[asset];
            if (!price) {
                continue;
            }
            const size = BigInt(size1 || size2 || 0);
            const groupId = groupBy === "counterparty" ? counterpartyId : asset;
            const entryId = groupBy === "counterparty" ? asset : counterpartyId;
            const groupBalance = groupObj[groupId] || {
                subitems: {},
                partUSD: 0n,
                partPlannedUSD: 0n,
            };
            const { overnightCost, estOvernightCost } = getOvernightCostData(
                groupedOvernightFees,
                asset,
                counterpartyId,
                size,
                isPrimeBrokerUser,
                isMakerUser,
                cpType,
            );
            if (!groupBalance.subitems[entryId]) {
                groupBalance.subitems[entryId] = {
                    value: 0n,
                    plannedValue: 0n,
                    valueUSD: 0n,
                    plannedValueUSD: 0n,
                    overnightCost: overnightCost,
                    estOvernightCost: estOvernightCost,
                    price,
                    asset,
                    cpId: counterpartyId,
                    cpName: getCpName(counterpartyId, "full"),
                };
            }
            groupBalance.subitems[entryId].plannedValue += size;
            const plannedValueUSD = (size * price) / BigInt(100000000) || 0n;
            groupBalance.subitems[entryId].plannedValueUSD += plannedValueUSD;
            groupBalance.partPlannedUSD += plannedValueUSD;
            groupObj[groupId] = groupBalance;
        }
        return groupObj;
    }, [
        priceObj,
        filteredExtendedPositionsSnapshot,
        filteredSettlementOrdersSnapshot,
        groupBy,
        groupedOvernightFees,
        isPrimeBrokerUser,
        getCpName,
    ]);
    const groups = useMemo(() => {
        return Object.keys(groupedPositionsData).sort((a: string, b: string) => {
            if (groupBy === "counterparty") return getCpName(a, "short").localeCompare(getCpName(b, "short"));
            return a.localeCompare(b);
        });
    }, [groupedPositionsData, getCpName, groupBy]);
    const { totalUSD, totalPlannedUSD } = useMemo(() => {
        let totalUSD = 0n;
        let totalPlannedUSD = 0n;
        for (const group of groups) {
            const { partUSD, partPlannedUSD } = groupedPositionsData[group];
            totalUSD += partUSD;
            totalPlannedUSD += partPlannedUSD;
        }
        return {
            totalUSD,
            totalPlannedUSD,
        };
    }, [groupedPositionsData, priceObj, groups, groupBy]);

    return {
        totalUSD,
        totalPlannedUSD,
        groupedPositionsData,
        isLoading:
            filteredSettlementOrdersSnapshot.isLoading ||
            filteredExtendedPositionsSnapshot.isLoading ||
            overnightFeesLoading ||
            usdPricesLoading,
    };
};
