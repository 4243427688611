import {
    ACTIONS_TABLE_COLUMN_KEY,
    EXPAND_TABLE_COLUMN_KEY,
    IconButton,
    Icons,
    InfiniteTable,
} from "@fm-frontend/uikit";
import {
    ColumnDef,
    ExpandedState,
    getCoreRowModel,
    getExpandedRowModel,
    Row,
} from "@tanstack/react-table";
import { BottomFixTableContainer } from "components/BottomFixTableContainer";
import { memo, useMemo, useState } from "react";
import styled from "styled-components";
import { SetupModalSettings } from "../MakerMasterInstrumentsTabContent";
import { CpActionCell } from "./cells";
import { useNotMakerCpsIds } from "./hooks/useCounterpartiesWithTypes";
import { columnHelper, columns } from "./table";
import { InstrumentTableData } from "./types";
import { formatPercentValue, isInstrumentRow } from "./utils";

const StyledBottomFixTableContainer = styled(BottomFixTableContainer)`
    display: flex;
    flex-direction: column;
`;

const InstrumentsTableView = ({
    onEdit,
    isLoading,
    instrumentsData,
}: {
    onEdit: (setupModalSettings?: SetupModalSettings) => void;
    isLoading: boolean;
    instrumentsData: InstrumentTableData[];
}) => {
    const notMakerCpsIds = useNotMakerCpsIds();
    const expandColumn = useMemo(
        () =>
            columnHelper.display({
                id: EXPAND_TABLE_COLUMN_KEY,
                header: ({ table }) => {
                    const isAllRowsExpanded = table.getIsAllRowsExpanded();

                    return (
                        <IconButton
                            variant="plain"
                            Icon={isAllRowsExpanded ? Icons.CollapseAll : Icons.ExpandAll}
                            onClick={table.getToggleAllRowsExpandedHandler()}
                        />
                    );
                },
                cell: ({ row }) => {
                    return !row.getCanExpand() ? null : row.getIsExpanded() ? (
                        <Icons.Collapse />
                    ) : (
                        <Icons.Expand />
                    );
                },
                meta: {
                    cellStyleProps: {
                        width: "32px",
                        paddingRight: "4px",
                        textAlign: "center",
                    },
                    headerStyleProps: {
                        textAlign: "center",
                    },
                },
            }),
        [],
    );
    const actionsColumn = useMemo(
        () =>
            columnHelper.accessor((row) => row, {
                id: ACTIONS_TABLE_COLUMN_KEY,
                cell: (subject) => {
                    const value = subject.getValue();

                    if (isInstrumentRow(value)) {
                        const hasSettingsButton = value.counterparties.some(
                            ({ cpType }) => cpType !== "maker",
                        );

                        if (!hasSettingsButton) return null;

                        return (
                            <IconButton
                                variant="plain"
                                Icon={Icons.Settings}
                                onClick={() =>
                                    onEdit({
                                        defaultValues: {
                                            instruments: [value.instrument.instrumentName],
                                            cps: notMakerCpsIds,
                                        },
                                        isPerInstruments: true,
                                    })
                                }
                            />
                        );
                    }

                    const hasActionCell = value.cpType !== "maker";

                    if (!hasActionCell) return null;

                    return (
                        <CpActionCell
                            onClick={() =>
                                onEdit({
                                    defaultValues: {
                                        instruments: [value.linkedInstrumentName],
                                        cps: [value.id],
                                        bidMarkup: formatPercentValue(value.bidMarkup),
                                        askMarkup: formatPercentValue(value.askMarkup),
                                    },
                                    isPerInstruments: true,
                                    isParticularMarkup: true,
                                })
                            }
                        />
                    );
                },
                header: "",
                enableSorting: false,
                meta: {
                    cellStyleProps: {
                        width: "40px",
                        textAlign: "center",
                        padding: [0, 0, 0, 0],
                    },
                },
            }),
        [onEdit, notMakerCpsIds],
    );

    const memoizedColumns = useMemo(
        () => [expandColumn, ...columns, actionsColumn],
        [expandColumn, actionsColumn],
    );

    const [expanded, setExpanded] = useState<ExpandedState>(true);

    const handleRowClick = (row: Row<InstrumentTableData>, id: string) => {
        if (id === ACTIONS_TABLE_COLUMN_KEY) {
            return;
        }

        const isExpandable = row.getCanExpand();

        if (isExpandable) {
            row.toggleExpanded();
        }
    };

    return (
        <StyledBottomFixTableContainer>
            <InfiniteTable
                tableOptions={{
                    data: instrumentsData,
                    columns: memoizedColumns as ColumnDef<InstrumentTableData, any>[],
                    state: {
                        expanded,
                    },
                    onExpandedChange: setExpanded,
                    getSubRows: (row) =>
                        isInstrumentRow(row)
                            ? (row.counterparties as unknown as InstrumentTableData[])
                            : undefined,
                    getCoreRowModel: getCoreRowModel(),
                    getExpandedRowModel: getExpandedRowModel(),
                }}
                onRowClick={handleRowClick}
                isLoading={isLoading}
            />
        </StyledBottomFixTableContainer>
    );
};

export const InstrumentsTable = memo(InstrumentsTableView);
