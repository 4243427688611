import { useInstruments } from "hooks";
import { useCounterparties } from "hooks/useCounterparties";
import { useMemo } from "react";
import { BankAddress, UseAddressType } from "../types";
import { BANK_CURRENCY_TYPES, getAccountStatus } from "../utils";
import { usePaymentAccountsRules } from "./usePaymentAccountsRules";
import { useSharedAccountsRevisions } from "./useSharedAccountsRevisions";
import { useSharedPaymentAccounts } from "./useSharedPaymentAccounts";

export const useSharedBanksAddresses = (): UseAddressType<BankAddress> => {
    const { sharedAccounts, sharedBanks, isCpDataLoading } = useSharedPaymentAccounts();
    const { sharedAccountsRevisions } = useSharedAccountsRevisions();
    const { currencies } = useInstruments();
    const { paymentAccountsRules } = usePaymentAccountsRules();
    const { counterparties, cpIds } = useCounterparties();

    const data = useMemo(() => {
        return sharedAccounts
            .reduce((acc, curr) => {
                const cpId = curr.ownerId;

                const isValidCp = cpId !== undefined && cpIds.includes(cpId);
                const isValidCurrencyType = BANK_CURRENCY_TYPES.includes(curr.currencyType);

                if (!isValidCp || !isValidCurrencyType) {
                    return acc;
                }

                const accountStatus = getAccountStatus(
                    "shared",
                    curr.id !== undefined ? sharedAccountsRevisions[curr.id] : [],
                );
                const cp =
                    cpId !== undefined
                        ? {
                              id: cpId,
                              name: counterparties[cpId],
                          }
                        : null;
                const currency = Object.values(currencies).find(({ id }) => curr.currencyId === id);
                const bankPrimary = sharedBanks.find((b) => b.id === curr.bankPrimaryId);
                const bankIntermediate = sharedBanks.find((b) => b.id === curr.bankIntermediateId);
                const accountRule = paymentAccountsRules.find(({ paymentAccountId }) => paymentAccountId === curr.id);

                acc.push({
                    rule: {
                        id: accountRule?.id,
                        cp: cp,
                        accountId: curr.id,
                    },
                    accountId: curr.id,
                    currency: currency
                        ? {
                              id: currency.id,
                              name: currency.name,
                          }
                        : null,
                    wallet: curr.data.wallet,
                    privateNote: curr.notes,
                    revisions: curr.id !== undefined ? sharedAccountsRevisions[curr.id] : undefined,
                    status: accountStatus,
                    bankPrimary: bankPrimary
                        ? {
                              id: bankPrimary.id,
                              name: bankPrimary.data.name,
                              address: bankPrimary.data.address,
                          }
                        : null,
                    bankIntermediate: bankIntermediate
                        ? {
                              id: bankIntermediate.id,
                              name: bankIntermediate.data.name,
                              address: bankIntermediate.data.address,
                          }
                        : null,
                    company: {
                        name: curr.data.companyName,
                        address: curr.data.companyAddress,
                    },
                    publicDetails: curr.data.publicDetails,
                    otherDetails: curr.data.otherDetails,
                    memoOrTag: curr.data.memoOrTag,
                } as BankAddress);

                return acc;
            }, [] as BankAddress[])
            .sort((a, b) => {
                if (a?.currency?.name === undefined) {
                    return -1;
                }
                if (b?.currency?.name === undefined) {
                    return 1;
                }

                return a.currency.name.localeCompare(b.currency.name);
            });
    }, [sharedAccountsRevisions, sharedAccounts, currencies, sharedBanks, cpIds, paymentAccountsRules]);

    return {
        data,
        isLoading: isCpDataLoading,
    };
};
