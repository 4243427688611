import { EMPTY_ARRAY } from "@fm-frontend/uikit/src/const";
import { post } from "api";
import { useEffect, useMemo } from "react";
import useSWRImmutable from "swr/immutable";
import { Transaction } from "types";
import { displayError } from "utils";
import { useIsPrimeBrokerUser, usePrimeBrokerViewType, useSnapshot } from "./hooks";

export enum TypeTransaction {
    Transactions = 0,
    Technical = 1,
}

const feed = "N";

export const useTransactionsHistoryEndpoints = () => {
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();

    return useMemo(() => {
        const filterParams = {
            filter: isPrimeBrokerUser && primeBrokerViewType === "subaccounts" ? "subaccounts" : "external",
        };

        return {
            [TypeTransaction.Transactions]: {
                apiMethod: "settlementTransactionHistory",
                filterParams,
            },
            [TypeTransaction.Technical]: {
                apiMethod: "settlementHistory",
                filterParams,
            },
        };
    }, [isPrimeBrokerUser, primeBrokerViewType]);
};

export const useHistoricalSettlementTransactions = (
    type: TypeTransaction = TypeTransaction.Transactions,
    feedId?: number,
    limit = 100,
) => {
    const snapshot: any = useSnapshot({ feed, feedId }).data;
    const endpoints = useTransactionsHistoryEndpoints();
    const nextId = snapshot?.[0];
    const { apiMethod, filterParams } = endpoints[type];
    const fetcher = () =>
        post(apiMethod, {
            till: nextId,
            limit: limit,
            filterTransactions: type === TypeTransaction.Technical,
            ...filterParams,
        });
    const {
        data = EMPTY_ARRAY as Transaction[],
        mutate,
        isLoading,
    } = useSWRImmutable<Transaction[]>(`${apiMethod}-${JSON.stringify(filterParams)}`, fetcher, {
        onError: displayError,
        revalidateOnMount: true,
    });
    useEffect(() => {
        mutate();
    }, [nextId, apiMethod, filterParams]);
    return {
        data,
        isLoading,
    };
};
