import { Book, Level } from "../../../types";

export const NotEnoughLiquidity = -1n;
export const TRADING_LAST_TRADES_LIMIT = 10;

export function weightedAverage({ value, levels, byAsset }: { value: number; levels: Level[]; byAsset: boolean }) {
    return byAsset ? weightedAverageByAsset(value, levels) : weightedAverageByBalance(value, levels);
}

function weightedAverageByAsset(orderSize: number, levels: Level[]): bigint {
    let remainder = BigInt(orderSize);
    let product = 0n;
    let size = 0n;

    for (const [bookPrice, bookSize] of levels) {
        if (remainder <= 0) break;

        const chunk = BigInt(remainder < bookSize ? remainder : bookSize);
        remainder -= BigInt(chunk);
        product += chunk * BigInt(bookPrice);
        size += chunk;
    }

    if (remainder) return NotEnoughLiquidity;
    if (!size) return 0n;

    return product / size;
}

function weightedAverageByBalance(orderVolume: number, levels: Level[]): bigint {
    const volume = BigInt(orderVolume * 1e8);
    let remainingVolume = volume;
    let totalSize = 0n;

    for (const [bookPrice, bookSize] of levels) {
        if (remainingVolume <= 0n) {
            break;
        }

        const levelVolume = BigInt(bookPrice) * BigInt(bookSize);
        const nextRemainingVolume = remainingVolume > levelVolume ? remainingVolume - levelVolume : 0n;
        const usedVolume = remainingVolume - nextRemainingVolume;
        remainingVolume = nextRemainingVolume;

        totalSize += (BigInt(bookSize) * usedVolume) / levelVolume;
    }

    if (remainingVolume > 0) {
        return NotEnoughLiquidity;
    }
    if (!totalSize) {
        return 0n;
    }

    return volume / totalSize;
}

type BookLevel = {
    bidSize?: bigint | number;
    askSize?: bigint | number;
    bidPrice?: bigint | number;
    askPrice?: bigint | number;
    key: string;
};
export const mapper = (book: Book) => {
    const rows: BookLevel[] = [];

    let hasAskLevels = true;
    let hasBidLevels = true;
    let index = 0;
    while ((hasAskLevels || hasBidLevels) && index < 10) {
        const [bid, ask] = book;
        const row: Partial<BookLevel> = {};
        if (bid[index]) {
            [row.bidPrice, row.bidSize] = bid[index];
        } else {
            hasBidLevels = false;
        }
        if (ask[index]) {
            [row.askPrice, row.askSize] = ask[index];
        } else {
            hasAskLevels = false;
        }
        if (bid[index] || ask[index]) {
            rows[index] = {
                ...row,
                key: [index, row.bidSize, row.askSize, row.bidPrice, row.askPrice].join("/"),
            };
        }
        index += 1;
    }
    return rows;
};
export const getWidths = (
    data: { askSize?: number | bigint; bidSize?: number | bigint }[],
    prop: "bidSize" | "askSize",
) => {
    const { sum, items } = data.reduce<{
        sum: bigint;
        items: Array<number | bigint>;
        lastItem: bigint | null;
    }>(
        (acc, item) => {
            if (item[prop]) {
                acc.sum += BigInt(item[prop] || 0);
                acc.items.push(acc.sum);
                acc.lastItem = acc.sum;
            }
            return acc;
        },
        { sum: BigInt(0), items: [], lastItem: null },
    );
    return items.map((item) => {
        if (!sum) return 0;
        return Number((BigInt(item) * 100n) / sum) / 100;
    });
};
