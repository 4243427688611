import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

export type NotificationType = "Limit" | "Request" | "Transaction" | "System";
export interface Notification {
    id: number;
    message: string;
    type: NotificationType;
    timestamp: number;
    isRead: boolean;
}

export type State = {
    notifications: Notification[];
    loading: boolean;
    unreadCount: number;
    totalCount: number;
};

export const wsNotificationsBind = createAction("ws/notifications/bind");
export const wsNotificationsRequestAuth = createAction("ws/notifications/requestAuth");
export const wsNotificationRead = createAction("ws/notifications/read", (notificationId: Notification["id"]) => ({
    payload: notificationId,
}));
export const wsNotificationReadAll = createAction("ws/notifications/readAll");
export const wsNotificationsReset = createAction("ws/notifications/reset");

const initialState: State = {
    notifications: [],
    loading: false,
    unreadCount: 0,
    totalCount: 0,
};

export const wsNotificationsSlice = createSlice({
    name: "wsNotifications",
    initialState,
    reducers: {
        pushNewNotification(
            state,
            {
                payload: { notification, unreadCount, totalCount },
            }: PayloadAction<{
                notification: Notification;
                unreadCount: State["unreadCount"];
                totalCount: State["totalCount"];
            }>,
        ) {
            state.notifications.unshift(notification);
            state.totalCount = totalCount;
            state.unreadCount = unreadCount;
        },
        addNotifications(
            state,
            {
                payload: { notifications, unreadCount, totalCount },
            }: PayloadAction<{
                notifications: Notification[];
                unreadCount?: State["unreadCount"];
                totalCount?: State["totalCount"];
            }>,
        ) {
            state.notifications.push(...notifications);
            if (typeof unreadCount === "number") {
                state.unreadCount = unreadCount;
            }
            if (typeof totalCount === "number") {
                state.totalCount = totalCount;
            }
            state.loading = false;
        },
        readNotification(
            state,
            {
                payload: { notificationIds, unreadCount },
            }: PayloadAction<{ notificationIds: Notification["id"][]; unreadCount: State["unreadCount"] }>,
        ) {
            notificationIds.forEach((notificationId) => {
                const existedNotification = state.notifications.find(({ id }) => id === notificationId);

                if (existedNotification && !existedNotification.isRead) {
                    existedNotification.isRead = true;
                }
                state.unreadCount = unreadCount;
            });
        },
        readAllNotifications(
            state,
            { payload: { unreadCount } }: PayloadAction<{ unreadCount: State["unreadCount"] }>,
        ) {
            state.notifications.forEach((notification) => {
                notification.isRead = true;
            });
            state.unreadCount = unreadCount;
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        wsNotificationsLoadMore(state, payload: PayloadAction<Notification["id"]>) {
            state.loading = true;
        },
    },
});

export const {
    actions: { pushNewNotification, addNotifications, readNotification, readAllNotifications, wsNotificationsLoadMore },
} = wsNotificationsSlice;
