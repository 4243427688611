import {
    HStack,
    IconButton,
    Icons,
    Mark,
    Tab,
    TabContext,
    TabList,
    TabPanel,
    Tooltip,
    VStack,
} from "@fm-frontend/uikit";
import { CpDropdownSelector } from "components/Selectors/CpDropdown";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTheme } from "styled-components";
import { EMPTY_ARRAY } from "const";
import { useAddressContext } from "../AddressesContext";
import { BankAddresses } from "../banks";
import { CryptoAddresses } from "../crypto";
import { useBanksAddresses } from "../hooks/useBanksAddresses";
import { useCryptoAddresses } from "../hooks/useCryptoAddresses";
import { useSharedBanksAddresses } from "../hooks/useSharedBanksAddresses";
import { useSharedCryptoAddresses } from "../hooks/useSharedCryptoAddresses";
import { AccountStatusType, AddressSide, AddressType } from "../types";
import { StatusFilterDropdown } from "./StatusFilterDropdown";
import { use2WayBinding } from "hooks/use2WayBinding";

type TabTitleProps = {
    side: AddressSide;
    needToConfirm: number;
};

export const TabTitle: React.FC<TabTitleProps> = ({ side, children, needToConfirm }) => {
    const { colors } = useTheme();
    const isMarkAvailable = side === "shared" && needToConfirm > 0;

    return (
        <>
            {children} {isMarkAvailable && <Mark color={colors.attention100}>{needToConfirm}</Mark>}
        </>
    );
};

type FilterType = {
    cps: number[];
    statuses: AccountStatusType[];
};

export const Content: React.FC = () => {
    const {
        type,
        side,
        setType,
        cpsIds,
        selectedCps,
        setSelectedCps,
        deleteSelectedCps,
        selectedStatuses,
        setSelectedStatuses,
        deleteSelectedStatuses,
        bankNeedToConfirm,
        cryptoNeedToConfirm,
        setCryptoNeedToConfirm,
        setBankNeedToConfirm,
    } = useAddressContext();

    const cryptoAddresses = useCryptoAddresses();
    const sharedCryptoAddresses = useSharedCryptoAddresses();
    const banksAddresses = useBanksAddresses();
    const sharedBanksAddresses = useSharedBanksAddresses();

    useEffect(() => {
        const needToConfirm = sharedCryptoAddresses.data.filter(
            ({ status }) => status === AccountStatusType.NeedToConfirm,
        ).length;
        setCryptoNeedToConfirm(needToConfirm);
    }, [sharedCryptoAddresses]);

    useEffect(() => {
        const needToConfirm = sharedBanksAddresses.data.filter(
            ({ status }) => status === AccountStatusType.NeedToConfirm,
        ).length;
        setBankNeedToConfirm(needToConfirm);
    }, [sharedBanksAddresses]);

    const cpFilterHidden = type === "bank" && side === "your";

    const {
        control,
        watch,
        setValue,
    } = useForm<FilterType>({
        defaultValues: {
            cps: selectedCps,
            statuses: selectedStatuses,
        },
    });

    const cpsValue = watch("cps");
    use2WayBinding(
        cpsValue,
        (filterValue) => setValue("cps", filterValue),
        selectedCps,
        setSelectedCps,
    );

    const statusesValue = watch("statuses");
    use2WayBinding(
        statusesValue,
        (filterValue) => setValue("statuses", filterValue),
        selectedStatuses,
        setSelectedStatuses,
    );

    const areFiltersDirty = cpsValue.length !== 0 || statusesValue.length !== 0;

    const resetFilter = () => {
        deleteSelectedCps();
        deleteSelectedStatuses();
    };

    return (
        <TabContext value={type} handleClick={(tab: string) => setType(tab as AddressType)}>
            <VStack paddingX={12}>
                <HStack spacing={6} alignItems="center">
                    <HStack width="238px">
                        <TabList size="small">
                            <Tab
                                title={
                                    <TabTitle side={side} needToConfirm={cryptoNeedToConfirm}>
                                        Crypto
                                    </TabTitle>
                                }
                                value={AddressType.Crypto}
                            />
                            <Tab
                                title={
                                    <TabTitle side={side} needToConfirm={bankNeedToConfirm}>
                                        Bank accounts
                                    </TabTitle>
                                }
                                value={AddressType.Bank}
                            />
                        </TabList>
                    </HStack>
                    {!cpFilterHidden && (
                        <CpDropdownSelector
                            control={control}
                            name="cps"
                            cpsIds={cpsIds ?? (EMPTY_ARRAY as number[])}
                        />
                    )}
                    <StatusFilterDropdown control={control} name="statuses" side={side} />
                    {areFiltersDirty && (
                        <Tooltip content="Reset filter" align="center">
                            <IconButton
                                variant="plain"
                                type="button"
                                Icon={Icons.Recent}
                                onClick={resetFilter}
                            />
                        </Tooltip>
                    )}
                </HStack>
            </VStack>
            <TabPanel value={AddressType.Crypto}>
                <CryptoAddresses
                    cryptoAddresses={cryptoAddresses}
                    sharedCryptoAddresses={sharedCryptoAddresses}
                />
            </TabPanel>
            <TabPanel value={AddressType.Bank}>
                <BankAddresses
                    banksAddresses={banksAddresses}
                    sharedBanksAddresses={sharedBanksAddresses}
                />
            </TabPanel>
        </TabContext>
    );
};
