import { SmallButton } from "feature/form/Buttons";
import { MemoTable } from "feature/table";
import { EventContext } from "hooks/useEventEmitter";
import { useContext, useMemo } from "react";
import { useHistory } from "react-router";
import { useLastTrades } from "store/useLastTrades";
import { formatValue } from "utils/EfxTypes";
import { columnsLastTrades } from "./table";
import { TRADING_LAST_TRADES_LIMIT } from "./utils";

export const LastTrades = () => {
    const history = useHistory();
    const { deals, isLoading } = useLastTrades(TRADING_LAST_TRADES_LIMIT);
    const { emit } = useContext(EventContext);
    const clickHander = useMemo(
        () => (e: React.MouseEvent, row: any) => {
            e.preventDefault();
            emit?.({
                instrument: deals,
                type: "setPrice",
                value: formatValue(row.original[13], "price", true),
            });
        },
        [emit],
    );

    const lastTradesGlobalActions = useMemo(
        () => <SmallButton onClick={() => history.push("/trades")}>See History</SmallButton>,
        [history],
    );

    return (
        <MemoTable
            title="Last Trades"
            columns={columnsLastTrades}
            data={deals}
            onRowClick={clickHander}
            globalActions={lastTradesGlobalActions}
            data-test-id="last-trades"
            isLoading={isLoading}
        />
    );
};
