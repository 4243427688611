import { PSBold } from "feature/inboxPopover/styled";
import styled, { useTheme } from "styled-components";
import { Transaction } from "./types";

const StatusContainer = styled.div<{ bgColor: string; color: string }>`
    background-color: ${(p) => p.bgColor};
    color: ${(p) => p.color};
    display: inline-block;
    padding: 0 4px;
    border-radius: 4px;
`;
export const Status = ({ value }: { value: Transaction["status"] }) => {
    const theme = useTheme();
    const settings = {
        received: {
            title: "Received",
            bgColor: theme.colors.process8,
            color: theme.colors.process100,
        },
        committed: {
            title: "Committed",
            bgColor: theme.colors.brand8,
            color: theme.colors.brand100,
        },
        cancelled: {
            title: "Cancelled",
            bgColor: theme.colors.negative8,
            color: theme.colors.negative100,
        },
        sent: {
            title: "Sent",
            bgColor: theme.colors.process8,
            color: theme.colors.process100,
        },
        created: {
            title: "Created",
            bgColor: theme.colors.attention8,
            color: theme.colors.attention100,
        },
    };

    return (
        <StatusContainer bgColor={settings[value].bgColor} color={settings[value].color}>
            <PSBold>{settings[value].title}</PSBold>
        </StatusContainer>
    );
};
