import { StyledAnchor } from "components/Links";
import { IS_TEST_ENV, onHover, onMobile } from "const";
import { Banner } from "feature/makersDashboard/style";
import { NavLink } from "react-router-dom";
import { selectors } from "style/theme";
import styled, { css } from "styled-components";

export const Line = styled.hr`
    width: 180px;
    color: ${(p) => p.theme.colors.menuHrBG};
    border: 1px solid #ebeaea;
`;

export const GreenLink = styled.a`
    ${selectors.mixinBody2}
    font-weight: 600;
    text-decoration: none;
    line-height: 17px;
    color: ${selectors.brandColor};
    cursor: pointer;
`;

export const MenuContainer = styled.nav<{ isOpen: boolean }>`
    padding: 8px;
    padding-right: 6px;
    padding-bottom: 0;
    margin-right: 6px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: ${IS_TEST_ENV ? 24 : 0}px;
    height: ${IS_TEST_ENV ? "calc(100vh - 24px)" : "100vh"};
    width: 206px;
    overflow-y: auto;
    a {
        text-decoration: none;
        cursor: pointer;
        transition: background-color ${(p) => p.theme.transitions.default},
            color ${(p) => p.theme.transitions.default};
        &.active {
            color: ${(p) => p.theme.colors.brand100};
        }
    }
    ${onMobile} {
        position: absolute;
        height: unset;
        top: ${IS_TEST_ENV ? "80px" : "54px"};
        width: 100vw;
        & > *:not(hr) {
            padding: 0.8em 0.3em;
        }
        background: ${(p) => p.theme.colors.mainBG};
        z-index: 4;
        transform: translateX(${(p) => (p.isOpen ? "0" : "-100%")});
        transition: ${(p) => p.theme.transitions.default};
    }
    ${({ isOpen }) =>
        isOpen &&
        css`
            transform: translateX(0);
        `}

    ${Banner} {
        width: 100%;
    }
    ${StyledAnchor} {
        margin-block-start: 35px;
        margin-block-end: 4px;
    }
`;
export const menuLinkStyles = css<{ primeBrokerSubaccountsView?: boolean }>`
    ${selectors.mixinPBold}
    display: block;
    color: ${(p) => p.theme.colors.ui100};
    width: 100%;
    padding: 5px 8px;
    border-radius: 8px;
    user-select: none;
    transition: none;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        background: ${(p) => p.theme.colors.ui4};
        text-decoration: none;
    }

    &:active,
    &.active {
        color: ${(p) =>
            p.primeBrokerSubaccountsView ? p.theme.colors.subpurple100 : p.theme.colors.brand100};
        background: ${(p) =>
            p.primeBrokerSubaccountsView ? p.theme.colors.subpurple8 : p.theme.colors.brand8};
        text-decoration: none;
    }
`;
export const MenuLink = styled(NavLink)`
    ${menuLinkStyles}
`;
export const MenuContent = styled.section<{ primeBrokerSubaccountsView: boolean }>`
    position: relative;
    & > div {
        ${selectors.mixinHeader3}
        color: ${(p) => p.theme.colors.ui32};
        margin-top: 16px;
        padding: 6px 0 6px 8px;
    }
    & > a {
        ${menuLinkStyles}
    }
`;
export const MenuScrollableArea = styled.div`
    overflow: auto;
    display: flex;
    flex-direction: column;
`;
export const FooterContent = styled.div<{ $background: string }>`
    padding: 8px 0 16px 0;
    display: flex;
    flex-direction: column;
    gap: 6px;

    position: sticky;
    bottom: 0;
    z-index: 10;

    background: ${(p) => p.$background};
`;

export const FooterEmail = styled.div`
    display: flex;
    padding: 0 8px;

    a {
        ${selectors.mixinPSmall}
        color: ${(p) => p.theme.colors.ui72};

        ${onHover} {
            color: ${(p) => p.theme.colors.ui100};
            text-decoration: none;
        }
    }
`;

export const FooterLinks = styled.div`
    display: flex;
    gap: 7px;
    padding: 0 8px;

    a {
        ${selectors.mixinPSmall}
        color: ${(p) => p.theme.colors.ui72};

        ${onHover} {
            color: ${(p) => p.theme.colors.ui100};
            text-decoration: none;
        }
    }
`;

export const FooterCopyright = styled.div`
    ${selectors.mixinPSmall}
    color: ${(p) => p.theme.colors.ui72};
    padding: 0 8px;
`;
