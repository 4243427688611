import { FACTION_UNIT_COMPENSATOR } from "@fm-frontend/utils";
import { ORDER_TYPES, PreparedDeal } from "types";
import { TradeHistory } from "../types";

export const mapPreparedDealToTradingHistory = (preparedDeal: PreparedDeal): TradeHistory => {
    return {
        cpId: preparedDeal.counterpartyId,
        orderId: preparedDeal.orderId,
        instrument: preparedDeal.instrument,
        side: preparedDeal.side,
        price: preparedDeal.price,
        size: preparedDeal.size,
        volume: preparedDeal.volume,
        delta: preparedDeal.delta,
        tradeId: preparedDeal.dealId,
        linkedTrade: preparedDeal.linkedDealId,
        date: preparedDeal.dealMoment,
        clientOrderId: preparedDeal.clientOrderId,
        orderType: ORDER_TYPES[preparedDeal.orderType],

        subRows: [],
    } as TradeHistory;
};

export const calculateTotalSizeAndVolume = (tradesInOrder: TradeHistory[]) => {
    return tradesInOrder.reduce<{ totalSize: bigint; totalVolume: bigint; totalDelta: bigint }>(
        (acc, tradeHistory) => {
            return {
                totalSize: acc.totalSize + (tradeHistory.size ?? 0n),
                totalVolume: acc.totalVolume + (tradeHistory.volume ?? 0n),
                totalDelta: acc.totalDelta + (tradeHistory.delta ?? 0n),
            };
        },
        { totalSize: 0n, totalVolume: 0n, totalDelta: 0n },
    );
};

export const calculatePrice = (totalVolume: bigint, totalSize: bigint): bigint => {
    return (totalVolume * FACTION_UNIT_COMPENSATOR) / totalSize;
};

export const createSubRows = (tradesInOrder: TradeHistory[]): TradeHistory[] => {
    return tradesInOrder.map((tradeHistory) => ({
        ...tradeHistory,
        orderId: null,
        instrument: null,
    }));
};

export const createGroupedPreparedDeal = (
    tradesInOrder: TradeHistory[],
    baseTradingHistory: TradeHistory,
): TradeHistory => {
    const { totalSize, totalVolume, totalDelta } = calculateTotalSizeAndVolume(tradesInOrder);
    const price = calculatePrice(totalVolume, totalSize);
    const subRows = createSubRows(tradesInOrder);

    return {
        ...baseTradingHistory,
        delta: totalDelta,
        volume: totalVolume,
        size: totalSize,
        price,
        subRows,
    };
};

export const groupTradeHistoriesByOrder = (tradeHistories: TradeHistory[]): TradeHistory[] => {
    const groupedTradesHistories = tradeHistories.reduce<Record<number, TradeHistory[]>>((acc, tradeHistory) => {
        if (tradeHistory.orderId !== undefined && tradeHistory.orderId !== null) {
            acc[tradeHistory.orderId] ??= [];
            acc[tradeHistory.orderId].push(tradeHistory);
        }

        return acc;
    }, {});

    return Object.values(groupedTradesHistories).map((tradesInOrder) => {
        const baseTradeHistory = tradesInOrder[0];

        return tradesInOrder.length === 1
            ? baseTradeHistory
            : createGroupedPreparedDeal(tradesInOrder, baseTradeHistory);
    });
};
