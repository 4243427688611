export enum ValidationMessages {
    REQUIRED = "Field is required",
    INVALID_LIMIT = "Invalid limit",
    INVALID_MARGIN = "Invalid margin",
    MIN_MAINTENANCE_MARGIN_ERROR = "Maintenance margin must be greater than or equal ${min}%",
    MAX_MAINTENANCE_MARGIN_ERROR = "Maintenance margin must be less than or  equal ${max}%",
    MAX_RESTRICTED_TRADING_ERROR = "Restricted trading must be less than or equal ${max}%",
    MIN_INITIAL_MARGIN_ERROR = "Initial margin must be greater than or equal ${min}%",
    MAX_INITIAL_MARGIN_ERROR = "Initial margin must be less than or equal ${max}%",
    INVALID_MARKUP = "Invalid delta",
    MIN_MARKUP_ERROR = "Delta must be more than or equal ${min}%",
    MAX_MARKUP_ERROR = "Delta must be less than or equal ${max}%",
    INVALID_CP_ID = "Invalid counterparty id",
    MUST_BE_NUMBER = "Must be a number",
    INVALIDEmail = "Invalid email",
    INVALID = "Field is invalid",
    MIN_TURNOVER_ERROR = "Must be greater than ${more} USD",
    INVALID_USERNAME_LENGTH = "Name must be from 3 to 32 characters",
    INVALID_USERNAME_SYMBOLS = "Only digits, letters and . - & _ are allowed",
    MIN_OVERNIGHT_ERROR = "Overnight fee must be more than or equal ${min}%",
    MAX_OVERNIGHT_ERROR = "Overnight fee must be lest than ${max}%",
    EFX_SIZE_OUT_OF_RANGE = "Invalid size",
    POSITIVE_NUMBER = "Must be positive number",
    NOT_NEGATIVE_NUMBER = "Must be 0 or greater",
    FIELD_MAX_LENGTH = "Field length must be less than or equal to ${max} characters",
    NO_CP_LIMIT = "CP limit must be set to select CP",
    INVALID_PERCENT_FORMAT = "Must be a number from 0 to 100 with max 2-decimal digits",
}
