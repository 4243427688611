import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "hooks";
import { LS_VARIABLES } from "hooks/useLSState";
import {
    BankType,
    JurisdictionType,
    MakerInfo,
    OnboardingCurrencyType,
    TakerInfoType,
} from "types";
import { IRootState } from "./index";
import { setLSValue } from "utils/localStorage";

export type OnboardingStepType =
    | "account"
    | "businessAndLegal"
    | "liquidityProviders"
    | "apiIntegration";

export type FilterType = {
    banks: string[];
    assets: string[];
    jurisdiction: string;
    regulation: boolean;
};

export enum ONBOARDING_STEPS {
    ACCOUNT_DATA = 1,
    ACCOUNT_ENABLE_TWO_FA,
    ACCOUNT_CONFIRM_TWO_FA,
    BL_LEGAL_DATA,
    BL_TERMS_AND_CONDITIONS,
    BL_GENERAL_INFO,
    BL_ASSETS,
    BL_API_INTEGRATION,
    PROVIDERS,
    API_INTEGRATION,
}

export enum StatusKYBVariant {
    NotStarted = 0,
    Started = 1,
    Finished = 2,
    Cancelled = 3,
}

type VerificationStatusType = "started" | "canceled";

export interface StatusKYB {
    id: number;
    status: StatusKYBVariant;
}

export interface StepKYB {
    id: number;
    title: string;
    description: string;
    actions: ActionKYB[];
}

export interface ActionKYB {
    id: number;
    title: string;
    url: string;
}

export const OnboardingMap: Record<
    ONBOARDING_STEPS,
    {
        progress: {
            current: number;
            total: number;
        };
        type: OnboardingStepType;
    }
> = {
    [ONBOARDING_STEPS.ACCOUNT_DATA]: {
        progress: {
            current: 1,
            total: 3,
        },
        type: "account",
    },
    [ONBOARDING_STEPS.ACCOUNT_ENABLE_TWO_FA]: {
        progress: {
            current: 2,
            total: 3,
        },
        type: "account",
    },
    [ONBOARDING_STEPS.ACCOUNT_CONFIRM_TWO_FA]: {
        progress: {
            current: 3,
            total: 3,
        },
        type: "account",
    },
    [ONBOARDING_STEPS.BL_LEGAL_DATA]: {
        progress: {
            current: 1,
            total: 5,
        },
        type: "businessAndLegal",
    },
    [ONBOARDING_STEPS.BL_TERMS_AND_CONDITIONS]: {
        progress: {
            current: 2,
            total: 5,
        },
        type: "businessAndLegal",
    },
    [ONBOARDING_STEPS.BL_GENERAL_INFO]: {
        progress: {
            current: 3,
            total: 5,
        },
        type: "businessAndLegal",
    },
    [ONBOARDING_STEPS.BL_ASSETS]: {
        progress: {
            current: 4,
            total: 5,
        },
        type: "businessAndLegal",
    },
    [ONBOARDING_STEPS.BL_API_INTEGRATION]: {
        progress: {
            current: 5,
            total: 5,
        },
        type: "businessAndLegal",
    },
    [ONBOARDING_STEPS.PROVIDERS]: {
        progress: {
            current: 1,
            total: 1,
        },
        type: "liquidityProviders",
    },
    [ONBOARDING_STEPS.API_INTEGRATION]: {
        progress: {
            current: 1,
            total: 1,
        },
        type: "apiIntegration",
    },
};

export const DEFAULT_FILTER: FilterType = {
    banks: [],
    assets: [],
    jurisdiction: "",
    regulation: false,
};

export interface OnboardingStages {
    isLegalDataRequired: boolean;
    isTermsAndConditionsRequired: boolean;
    isTermsAndConditionsDelegated?: boolean;
    isGeneralInfoRequired?: boolean;
    isAssetsRequired?: boolean;
    isApiIntegrationRequired?: boolean;
    isLpsRequired?: boolean;
}

export interface LegalDataInputs {
    companyName: string;
    registrationNumber: string;
    address: string;
    city: string;
    zipCode: string;
    country: string;
}

export type State = {
    onboardingStages: OnboardingStages;
    takerInfo?: TakerInfoType;
    currentStep: ONBOARDING_STEPS | null;
    makerInfoDetailed?: MakerInfo;
    stepsKYB: StepKYB[];
    isStepsLoading: boolean;
    statusKYB: Record<number, StatusKYBVariant>;
    isStatusKYBLoading: boolean;
    jurisdictions: JurisdictionType[];
    banks: BankType[];
    currencies: OnboardingCurrencyType[];
    restartVerificationMakerId?: number;
    verificationStatuses: Record<
        number,
        {
            isStartLoading?: boolean;
            isCancelLoading?: boolean;
            status?: VerificationStatusType;
        }
    >;
};

const initialState: State = {
    onboardingStages: {
        isLegalDataRequired: false,
        isTermsAndConditionsRequired: false,
        isGeneralInfoRequired: false,
        isAssetsRequired: false,
        isApiIntegrationRequired: false,
        isLpsRequired: false,
    },
    currentStep: null,
    statusKYB: {},
    isStatusKYBLoading: false,
    stepsKYB: [],
    isStepsLoading: false,
    jurisdictions: [],
    banks: [],
    currencies: [],
    verificationStatuses: {},
};

export const onboardingSlice = createSlice({
    name: "onboarding",
    initialState,
    reducers: {
        setOnboardingStages(state, { payload: onboardingStages }: PayloadAction<OnboardingStages>) {
            state.onboardingStages = onboardingStages;
        },
        setIsLegalDataRequired(state, { payload: isLegalDataRequired }: PayloadAction<boolean>) {
            state.onboardingStages.isLegalDataRequired = isLegalDataRequired;
        },
        setIsTermsAndConditionsRequired(
            state,
            { payload: isTermsAndConditionsRequired }: PayloadAction<boolean>,
        ) {
            state.onboardingStages.isTermsAndConditionsRequired = isTermsAndConditionsRequired;
        },
        setIsTermsAndConditionsDelegated(
            state,
            { payload: isTermsAndConditionsDelegated }: PayloadAction<boolean>,
        ) {
            state.onboardingStages.isTermsAndConditionsDelegated = isTermsAndConditionsDelegated;
            setLSValue(LS_VARIABLES.TnC_DELEGATED, isTermsAndConditionsDelegated);
        },
        setIsGeneralInfoRequired(
            state,
            { payload: isGeneralInfoRequired }: PayloadAction<boolean>,
        ) {
            state.onboardingStages.isGeneralInfoRequired = isGeneralInfoRequired;
        },
        setIsAssetsRequired(state, { payload: isAssetsRequired }: PayloadAction<boolean>) {
            state.onboardingStages.isAssetsRequired = isAssetsRequired;
        },
        setIsApiIntegrationRequired(
            state,
            { payload: isApiIntegrationRequired }: PayloadAction<boolean>,
        ) {
            state.onboardingStages.isApiIntegrationRequired = isApiIntegrationRequired;
            setLSValue(LS_VARIABLES.API_INTEGRATION_REQUIRED, isApiIntegrationRequired);
        },
        setIsLpsRequired(state, { payload: isLpsRequired }: PayloadAction<boolean>) {
            state.onboardingStages.isLpsRequired = isLpsRequired;
        },
        setCurrentStep(state, { payload: currentStep }: PayloadAction<ONBOARDING_STEPS | null>) {
            state.currentStep = currentStep;
        },
        setTakerInfo(state, { payload: takerInfo }: PayloadAction<TakerInfoType | undefined>) {
            state.takerInfo = takerInfo;
        },
        setMakerInfoDetailed(
            state,
            { payload: makerInfoDetailed }: PayloadAction<MakerInfo | undefined>,
        ) {
            state.makerInfoDetailed = makerInfoDetailed;
        },
        setStatusKYB(
            state,
            { payload: statusKYB }: PayloadAction<Record<number, StatusKYBVariant>>,
        ) {
            const oldState = { ...state.statusKYB };
            state.statusKYB = {
                ...oldState,
                ...statusKYB,
            };
        },
        setIsStatusKYBLoading(state, { payload: isStatusKYBLoading }: PayloadAction<boolean>) {
            state.isStatusKYBLoading = isStatusKYBLoading;
        },
        setStepsKYB(state, { payload: stepsKYB }: PayloadAction<StepKYB[]>) {
            state.stepsKYB = stepsKYB;
        },
        setIsStepsLoading(state, { payload: isStepsLoading }: PayloadAction<boolean>) {
            state.isStepsLoading = isStepsLoading;
        },
        setJurisdictions(state, { payload: jurisdictions }: PayloadAction<JurisdictionType[]>) {
            state.jurisdictions = jurisdictions;
        },
        setBanks(state, { payload: banks }: PayloadAction<BankType[]>) {
            state.banks = banks;
        },
        setCurrencies(state, { payload: currencies }: PayloadAction<OnboardingCurrencyType[]>) {
            state.currencies = currencies;
        },
        setRestartVerificationMakerId(
            state,
            { payload: makerId }: PayloadAction<number | undefined>,
        ) {
            state.restartVerificationMakerId = makerId;
        },
        setVerificationStatus(
            state,
            {
                payload: { id, status },
            }: PayloadAction<{
                id: number;
                status: {
                    isStartLoading?: boolean;
                    isCancelLoading?: boolean;
                    status?: VerificationStatusType;
                };
            }>,
        ) {
            state.verificationStatuses[id] = status;
        },
    },
});

export const {
    actions: {
        setOnboardingStages,
        setCurrentStep,
        setIsLegalDataRequired,
        setIsTermsAndConditionsRequired,
        setIsTermsAndConditionsDelegated,
        setIsGeneralInfoRequired,
        setIsAssetsRequired,
        setIsApiIntegrationRequired,
        setIsLpsRequired,
        setTakerInfo,
        setMakerInfoDetailed,
        setStatusKYB,
        setStepsKYB,
        setJurisdictions,
        setBanks,
        setCurrencies,
        setIsStepsLoading,
        setIsStatusKYBLoading,
        setRestartVerificationMakerId,
        setVerificationStatus,
    },
    reducer,
} = onboardingSlice;

export const isOnboardingActive = (state: IRootState) => state.onboarding.currentStep !== null;

export const useIsOnboardingRequired = () => {
    const { onboardingStages } = useSelector((state) => state.onboarding);

    return Object.values(onboardingStages).some((stage) => stage);
};

export const useIsPostOnboardingRequired = () => {
    const {
        onboardingStages: { isLpsRequired, isGeneralInfoRequired, isAssetsRequired },
    } = useSelector((state) => state.onboarding);

    return Boolean(!isLpsRequired && (isAssetsRequired || isGeneralInfoRequired));
};
