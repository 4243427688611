import { CoinIcon } from "components/CoinIcon";
import styled from "styled-components";

const COIN_ICON_SIZE = 16;
const COIN_LEFT = 12;

const InstrumentIcons = styled.div`
    width: ${COIN_ICON_SIZE + COIN_LEFT}px;
    position: relative;

    img {
        top: 1px;
        position: absolute;
        border: 1px solid white;
        border-radius: 8px;
    }

    img:first-child {
        left: ${COIN_LEFT}px;
    }
`;

export const InstrumentIcon = ({
    assetCurrency,
    balanceCurrency,
    className,
}: {
    assetCurrency: string;
    balanceCurrency: string;
    className?: string;
}) => (
    <InstrumentIcons className={className}>
        <CoinIcon coin={balanceCurrency} size={COIN_ICON_SIZE} />
        <CoinIcon coin={assetCurrency} size={COIN_ICON_SIZE} />
    </InstrumentIcons>
);
