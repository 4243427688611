import { Icons } from "@fm-frontend/uikit";
import { forwardRef } from "react";
import { useTheme } from "styled-components";
import { Badge, NotificationsButton as StyledNotificationsButton } from "./styled";

export const NotificationsButton = forwardRef<
    HTMLButtonElement,
    {
        unreadNotificationsCount: number;
        onClick: () => void;
    }
>(({ onClick, unreadNotificationsCount }, ref) => {
    const { colors } = useTheme();
    const hasUnreadNOtifications = unreadNotificationsCount > 0;

    return (
        <StyledNotificationsButton onClick={onClick} ref={ref}>
            <Icons.Bell color={colors.ui32} />
            {hasUnreadNOtifications && <Badge>{unreadNotificationsCount}</Badge>}
        </StyledNotificationsButton>
    );
});
