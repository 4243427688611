import { createContext, useCallback, useMemo } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { PINNED } from "../../../const";
import { TradingAsset } from "../../../types";

export const SelectedAssetContext = createContext<
    Partial<
        TradingAsset & {
            instrument: string;
            setAsset: (asset: TradingAsset, instrument?: string) => void;
            setInstrument: (instrument: string) => void;
        }
    >
>({});

export const DEFAULT_ASSET = { asset: "BTC", isReversed: false };
export const DEFAULT_COLLECTION = "BTC-";
export const DEFAULT_INSTRUMENT = "BTC-EUR";

export const useSelectedAsset = () => {
    const { instrument, collection } = useParams<{ collection?: string; instrument?: string }>();
    const { asset, isReversed }: TradingAsset = useMemo(() => {
        if (collection === "pinned") return { asset: PINNED, isReversed: false };
        if (!collection) return DEFAULT_ASSET;
        const [one, two] = collection.split("-");
        return {
            asset: one || two,
            isReversed: !one,
        };
    }, [collection]);
    const history = useHistory();
    const setAsset = useCallback(
        ({ asset, isReversed }: TradingAsset, instrument = "") => {
            const collection = asset === PINNED ? "pinned" : isReversed ? `-${asset}` : `${asset}-`;
            history.push(`/trading/${collection}/${instrument}`);
        },
        [location],
    );
    const setInstrument = useCallback(
        (instrument: string) => history.push(`/trading/${collection || DEFAULT_COLLECTION}/${instrument}`),
        [collection],
    );
    return useMemo(() => {
        return { asset, isReversed, instrument, setAsset, setInstrument };
    }, [asset, isReversed, instrument, setAsset, setInstrument]);
};
