import {
    FieldGroup,
    H2,
    HStack,
    PlainButton,
    PrimaryButton,
    Tab,
    TabContext,
    TabList,
    TabPanel,
    VStack,
} from "@fm-frontend/uikit";
import { DropZone } from "@fm-frontend/uikit/src/components/dropZone";
import { DropzoneContextProvider } from "@fm-frontend/uikit/src/components/dropZone/DropzoneContext";
import { useFileContext } from "@fm-frontend/uikit/src/components/dropZone/FileContext";
import { ANIMATION_TIMEOUT } from "@fm-frontend/uikit/src/components/Modal/styled";
import {
    DiffDataResult,
    downloadCsv,
    ExportData,
    parseToCsv,
    parseToJson,
    useFormCloseConfirmer,
} from "@fm-frontend/utils";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { InstrumentsMultipleGroupedDropdown } from "components/customMultipleDropdowns/InstrumentsMultipleGroupedDropdown";
import { format } from "date-fns";
import { useWhitelistingContext } from "feature/assetsControl/components/WhitelistingTabContent/whitelistingContext";
import { useInstruments } from "hooks";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { BULK_EDIT_WHITELISTING_MODAL_KEY } from "../../utils";
import { BulkEditModalHint } from "../BulkEditModalHint";
import { useBulkLogger } from "../useBulkLogger";
import { WhitelistingBulkEditDiffLightBox } from "../WhitelistingBulkEditDiffLightBox";
import { useBulkEditData } from "./hooks/useBulkEditData";
import { whitelistingBulkEditFormSchema } from "./schema";
import {
    CSV_COLUMNS_TO_DATA_TYPE,
    CSV_VALIDATION_ERROR_MESSAGES,
    DATA_TYPE_TO_CSV_COLUMNS,
    formatWhitelisting,
    REQUIRED_CSV_COLUMNS,
    ValidationContext,
    WhitelistingImportType,
    WhitelistingOriginalDataType,
} from "./utils";

enum TabEnum {
    Export = "export",
    Import = "import",
}

type WhitelistingBulkEditFormInputs = {
    instruments: string[];
};

type WhitelistingBulkEditModalProps = {
    onCancel: () => void;
};
export const WhitelistingBulkEditModal: FC<WhitelistingBulkEditModalProps> = ({ onCancel }) => {
    const { logBulkExport, logBulkImport } = useBulkLogger();
    const { instruments } = useInstruments();
    const { isInstrumentInWhitelist } = useWhitelistingContext();
    const bulkEditData = useBulkEditData({ isInstrumentInWhitelist });
    const [tab, setTab] = useState(TabEnum.Export);

    const [isLightboxActive, setIsLightboxActive] = useState(false);
    const { acceptedFiles, resetFiles, setExternalError, externalError } = useFileContext();

    const [diffResult, setDiffResult] = useState<DiffDataResult>({
        hasErrors: false,
        hasDiffs: false,
        isLoading: true,
        diff: [],
    });
    const [importDataResult, setImportDataResult] = useState<WhitelistingImportType[]>([]);
    const [exportHeaders, setExportHeaders] = useState<string[]>([]);

    const originalExportData: WhitelistingOriginalDataType[] = useMemo(
        () =>
            bulkEditData.map((item) => {
                return {
                    instrument: item.instrument.instrumentName,
                    whitelisting: item.whitelisting,
                } as WhitelistingOriginalDataType;
            }),
        [bulkEditData],
    );

    const validationContext: ValidationContext = useMemo(
        () => ({
            originalValues: originalExportData,
            availableInstruments: instruments.map((o) => o.instrumentName),
        }),
        [originalExportData, instruments],
    );

    const {
        control,
        handleSubmit,
        formState: { isSubmitting, errors, isDirty },
    } = useForm<WhitelistingBulkEditFormInputs>({
        defaultValues: {
            instruments: [],
        },
        resolver: yupResolver(whitelistingBulkEditFormSchema),
        mode: "onChange",
    });

    useFormCloseConfirmer(
        BULK_EDIT_WHITELISTING_MODAL_KEY,
        tab === TabEnum.Export ? isDirty : false,
    );

    const handleFileParsing = async () => {
        if (!acceptedFiles.length) {
            setExternalError(CSV_VALIDATION_ERROR_MESSAGES.fileMissed);
            return;
        }

        const importFile = acceptedFiles[0];
        const result = await parseToJson(importFile, {
            header: true,
            transformHeader: (header) => {
                const normalizedHeader = header.replace(/^"|"$/g, "");

                return CSV_COLUMNS_TO_DATA_TYPE[normalizedHeader] ?? header;
            },
        });

        if (result.errors.length) {
            setExternalError(CSV_VALIDATION_ERROR_MESSAGES.invalidCharacters);
            return;
        }

        const csvImportFileColumns = result.meta?.fields ?? [];

        if (
            !Object.keys(REQUIRED_CSV_COLUMNS).every((c) =>
                csvImportFileColumns.some((k) => k === c),
            )
        ) {
            setExternalError(
                CSV_VALIDATION_ERROR_MESSAGES.missingRequiredColumns(
                    Object.values(REQUIRED_CSV_COLUMNS),
                ),
            );
            return;
        }

        if (
            !csvImportFileColumns.every((c) =>
                Object.keys(DATA_TYPE_TO_CSV_COLUMNS).some((k) => k === c),
            )
        ) {
            setExternalError(CSV_VALIDATION_ERROR_MESSAGES.headersMismatchFormat);
            return;
        }

        setExportHeaders(csvImportFileColumns);
        setImportDataResult(result.data as WhitelistingImportType[]);
        setIsLightboxActive(true);
        setExternalError("");

        try {
            const worker = new Worker(
                new URL("./whitelistingBulkEditValidator.worker.ts", import.meta.url),
            );

            worker.postMessage({
                importData: result.data,
                validationContext,
            });

            worker.addEventListener("message", ({ data }: MessageEvent<DiffDataResult>) => {
                worker.terminate();
                setDiffResult(data);
            });
        } catch (e) {
            setExternalError(String(e));

            throw e;
        }
    };

    useEffect(() => {
        if (!isLightboxActive && acceptedFiles.length && !externalError) {
            handleFileParsing();
            logBulkImport();
        }
    }, [isLightboxActive, acceptedFiles, externalError]);

    const handleExportFormSubmit = handleSubmit(async (inputs: WhitelistingBulkEditFormInputs) => {
        const exportData: ExportData[] = bulkEditData
            .filter((item) => inputs.instruments.includes(item.instrument.instrumentName))
            .map((item) => {
                return {
                    [DATA_TYPE_TO_CSV_COLUMNS.instrument]: item.instrument.instrumentName,
                    [DATA_TYPE_TO_CSV_COLUMNS.whitelisting]: formatWhitelisting(item.whitelisting),
                };
            });
        const csv = parseToCsv(exportData, {
            header: true,
        });
        downloadCsv(csv, `whitelisting_bulk_configuration_${format(new Date(), "yyyyMMddHHmm")}`);
        logBulkExport();
    });

    const handleCancel = useCallback(() => {
        setIsLightboxActive(false);
        resetFiles();
        setImportDataResult([]);

        // reset table data after modal closing animation over
        setTimeout(() => {
            setDiffResult({
                hasErrors: false,
                hasDiffs: false,
                isLoading: true,
                diff: [],
            });
        }, ANIMATION_TIMEOUT);
    }, [resetFiles, setImportDataResult, setDiffResult]);

    return (
        <VStack width="360px" asCard>
            <VStack padding={12}>
                <H2>Bulk edit configuration</H2>
            </VStack>
            <VStack paddingX={12} paddingBottom={12}>
                <TabContext value={tab} handleClick={(v) => setTab(v as TabEnum)}>
                    <TabList size="small">
                        <Tab title="New template" value={TabEnum.Export} />
                        <Tab title="Import config" value={TabEnum.Import} />
                    </TabList>
                    <TabPanel value={TabEnum.Export}>
                        <form onSubmit={handleExportFormSubmit}>
                            <FieldGroup paddingBottom={12}>
                                <BulkEditModalHint />
                            </FieldGroup>
                            <FieldGroup paddingBottom={16}>
                                <Controller
                                    control={control}
                                    render={({ field }) => (
                                        <InstrumentsMultipleGroupedDropdown
                                            values={field.value}
                                            onChange={field.onChange}
                                            error={errors.instruments?.message}
                                        />
                                    )}
                                    name="instruments"
                                />
                            </FieldGroup>
                            <VStack spacing={8}>
                                <PrimaryButton
                                    type="submit"
                                    fullWidth
                                    size="large"
                                    disabled={isSubmitting}
                                >
                                    Export .CSV
                                </PrimaryButton>
                                <PlainButton
                                    fullWidth
                                    size="large"
                                    onClick={onCancel}
                                    type="button"
                                >
                                    Cancel
                                </PlainButton>
                            </VStack>
                        </form>
                    </TabPanel>
                    <TabPanel value={TabEnum.Import}>
                        <FieldGroup paddingBottom={12}>
                            <BulkEditModalHint />
                        </FieldGroup>
                        <VStack width="100%" spacing={16}>
                            <HStack height="258px" width="100%">
                                <DropzoneContextProvider
                                    options={{
                                        disabled: false,
                                        accept: { "text/csv": [".csv"] },
                                        multiple: false,
                                    }}
                                >
                                    <DropZone onCancel={handleCancel} />
                                </DropzoneContextProvider>
                            </HStack>
                            <PlainButton
                                fullWidth
                                size="large"
                                onClick={onCancel}
                                type="button"
                                disabled={isSubmitting}
                            >
                                Cancel
                            </PlainButton>
                        </VStack>
                        {isLightboxActive && (
                            <WhitelistingBulkEditDiffLightBox
                                diffTableColumns={exportHeaders}
                                diffResult={diffResult}
                                importData={importDataResult}
                                onClose={onCancel}
                                onBack={handleCancel}
                            />
                        )}
                    </TabPanel>
                </TabContext>
            </VStack>
        </VStack>
    );
};
