import { call, put, take, takeEvery } from "@redux-saga/core/effects";
import { post } from "api";
import { Amplify } from "aws-amplify";
import { awsExports } from "aws-exports";
import { setIsLoadingApp, shutdownApp } from "feature/app";
import { clearSnapshots } from "feature/app/ws";
import { LS_VARIABLES } from "hooks/useLSState";
import { eventChannel } from "redux-saga";
import { getIsUserAuthenticated, refreshSession } from "services/authService";
import { signOut, updateClientData } from "store/auth/actions";
import { logout } from "store/auth/logout";
import { authenticationSlice } from "store/auth/slice";
import { agreementNotSigned, startUserOnboarding } from "store/onboardingActions";
import { OnboardingStages, setOnboardingStages } from "store/onboardingSlice";
import { ClientData } from "types";
import { getLSValue } from "utils/localStorage";

const isLegalDataRequired = (clientData?: ClientData) => {
    if (!clientData) {
        return true;
    }

    const { companyName, registrationNumber, address, city, zipCode, country } = clientData;

    return [companyName, registrationNumber, address, city, zipCode, country].some(
        (prop) => !Boolean(prop),
    );
};

// when T&C is not required, then T&C delegated also not required
// but if T&C is required, T&C is depended on a storage value
const getGeneralOnboardingStages = (clientData?: ClientData) => {
    const isTermsAndConditionsRequired = Boolean(clientData?.needAgreement);
    const isTermsAndConditionsDelegated = isTermsAndConditionsRequired
        ? getLSValue<boolean>(LS_VARIABLES.TnC_DELEGATED) ?? false
        : false;

    return {
        isLegalDataRequired: isTermsAndConditionsRequired && isLegalDataRequired(clientData),
        isTermsAndConditionsRequired,
        isTermsAndConditionsDelegated,
    } as OnboardingStages;
};

async function handleVisibilityChange() {
    if (!document.hidden) {
        await refreshSession();
    }
}

export function* sagaUpdateClientData() {
    try {
        const clientData = (yield call(post, "getClientData", {})) as ClientData;
        const generalOnboardingStages = getGeneralOnboardingStages(clientData);
        yield put(setOnboardingStages(generalOnboardingStages));
        yield put(authenticationSlice.actions.setClientData(clientData));
    } catch (error) {
        console.error(error);
    }
}

function* sagaSetUser() {
    try {
        yield put(
            setIsLoadingApp({
                isLoading: true,
                key: "sagaSetUser",
            }),
        );

        const isUserAuthenticated: boolean = yield call(getIsUserAuthenticated);

        if (!isUserAuthenticated) {
            yield put(setIsLoadingApp({ isLoading: false, key: "sagaSetUser" }));
            return;
        }

        yield put(authenticationSlice.actions.setAuthenticated());
        yield call(sagaUpdateClientData);
        yield put(startUserOnboarding());
    } catch (error) {
        console.error(error);
        return;
    }

    yield put(setIsLoadingApp({ isLoading: false, key: "sagaSetUser" }));
}

function* sagaLogout() {
    // @ts-ignore
    yield put(signOut());
    yield put(clearSnapshots());
}

const visibilityChange = eventChannel((emitter) => {
    document.addEventListener("visibilitychange", emitter);
    return () => document.removeEventListener("visibilitychange", emitter);
});

export function* authSaga() {
    yield takeEvery(logout, sagaLogout);
    yield takeEvery(updateClientData, sagaUpdateClientData);
    yield takeEvery(agreementNotSigned, sagaUpdateClientData);

    Amplify.configure(awsExports);

    yield put(
        setIsLoadingApp({
            isLoading: true,
            key: "authSaga",
        }),
    );

    yield call(sagaSetUser);
    yield put(
        setIsLoadingApp({
            isLoading: false,
            key: "authSaga",
        }),
    );

    yield takeEvery(visibilityChange, handleVisibilityChange);
    yield take(shutdownApp);
}
