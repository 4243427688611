import { post } from "api";
import { EMPTY_ARRAY } from "const";
import { useMemo } from "react";
import useSWR from "swr";
import { Deal } from "types";
import { displayError } from "utils";
import { useIsPrimeBrokerUser, usePrimeBrokerViewType } from "./hooks";

export const DEAL_HISTORY_API_METHOD = "dealHistory";
const DEAL_MOMENT_INDEX = 10;

export const useLastTradesSWRKey = (limit: number) => {
    const sideKey = usePrimeBrokerViewType();

    return `${sideKey}-${limit}`;
};

export const useDealHistoryParams = (limit?: number) => {
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();
    const side = isPrimeBrokerUser ? primeBrokerViewType : "counterparties";

    return useMemo(
        () => ({
            limit,
            filter: side === "counterparties" ? "external" : "subaccounts",
        }),
        [side, limit],
    );
};

export const useLastTrades = (limit: number) => {
    const params = useDealHistoryParams(limit);

    const lastTradesSWRKey = useLastTradesSWRKey(limit);
    const fetcher = () => post(DEAL_HISTORY_API_METHOD, params);

    const { data, isLoading, isValidating, mutate } = useSWR(lastTradesSWRKey, fetcher, {
        onError: displayError,
        fallbackData: EMPTY_ARRAY,
        revalidateOnMount: true,
        revalidateOnFocus: false,
    });

    const deals: Deal[] = useMemo(() => {
        return data
            .concat()
            .sort((a: any, b: any) => b[DEAL_MOMENT_INDEX] - a[DEAL_MOMENT_INDEX])
            .filter((_: unknown, idx: number) => idx < limit);
    }, [data, limit]);

    return {
        deals,
        isLoading,
        isValidating,
        mutate,
    };
};
