import { all, call, put, select, take, takeEvery } from "@redux-saga/core/effects";
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { post } from "api";
import { createNotification, setIsLoadingApp } from "feature/app";
import { sagaCounterpartiesInfoFetcher } from "feature/app/store/counterpartiesInfoFetcher";
import { getIsUserAuthenticated } from "services/authService";
import { IRootState } from "store/index";
import { FavoriteInstruments, Notifications } from "types";

export const URL_GET_SETTINGS = "settings/getNotifications";
export const URL_SET_SETTINGS = "settings/setNotifications";
export const URL_SET_FAVORITE = "settings/setFavoriteInstruments";
export const URL_GET_FAVORITE = "settings/getFavoriteInstruments";

const loadSettings = createAction("settings/load");

export type State = {
    riskManagementInbox: boolean;
    riskManagementEmail: boolean;
    settlementsInbox: boolean;
    settlementsEmail: boolean;
    systemInbox: boolean;
    systemEmail: boolean;
    showRealTimeNotification: boolean;
    enableSound: boolean;

    favoriteInstruments: string[];
    limitsAffinity: Record<number, string>;
};

const initialState: State = {
    riskManagementInbox: false,
    riskManagementEmail: false,
    settlementsInbox: false,
    settlementsEmail: false,
    systemInbox: false,
    systemEmail: false,

    showRealTimeNotification: false,
    enableSound: false,

    favoriteInstruments: [],
    limitsAffinity: {},
};

export const settingSlice = createSlice({
    name: "settings",
    initialState,
    reducers: {
        setSetting(
            state,
            { payload: { key, value } }: PayloadAction<{ key: keyof State; value: any }>,
        ) {
            return {
                ...state,
                [key]: value,
            };
        },
        setFavorites(state, { payload }: PayloadAction<string[]>) {
            state.favoriteInstruments = payload;
        },
        setSettings(state, { payload }: PayloadAction<Partial<State>>) {
            return { ...state, ...payload };
        },
        setLimitAffinity(
            state,
            {
                payload: { counterparty, currency },
            }: PayloadAction<{ counterparty: number; currency: string }>,
        ) {
            state.limitsAffinity[counterparty] = currency;
        },
    },
});

function* sagaSaveSettings() {
    const settings: State = yield select((state: IRootState) => state.settings);

    const notifications: Notifications = {
        NotificationRiskManagementInbox: settings.riskManagementInbox,
        NotificationRiskManagementEmail: settings.riskManagementEmail,
        NotificationSettlementsInbox: settings.settlementsInbox,
        NotificationSettlementsEmail: settings.settlementsEmail,
        NotificationSystemInbox: settings.systemInbox,
        NotificationSystemEmail: settings.systemEmail,
        ShowRealTimeNotification: settings.showRealTimeNotification,
        EnableSound: settings.enableSound,
    };

    try {
        yield call(post, URL_SET_SETTINGS, notifications);
    } catch {
        yield put(
            createNotification({
                type: "error",
                content: "Error save notifications",
            }),
        );
    }
}

function* sagaSaveFavorites() {
    const settings: State = yield select((state: IRootState) => state.settings);

    const favoriteInstruments: FavoriteInstruments = {
        favoriteInstruments: settings.favoriteInstruments,
    };

    try {
        yield call(post, URL_SET_FAVORITE, favoriteInstruments);
    } catch {
        yield put(
            createNotification({
                type: "error",
                content: "Error save favorites",
            }),
        );
    }
}

export function* sagaLoadSettings() {
    try {
        while (true) {
            const isUserAuthenticated: boolean = yield call(getIsUserAuthenticated);

            if (!isUserAuthenticated) {
                return;
            }

            yield put(
                setIsLoadingApp({
                    key: "sagaLoadSettings",
                    isLoading: true,
                }),
            );
            const [dataRequestFavorite, dataRequest]: [FavoriteInstruments, Notifications] =
                yield all([
                    call(post, URL_GET_FAVORITE, null),
                    call(post, URL_GET_SETTINGS, null),
                    call(sagaCounterpartiesInfoFetcher, {}),
                ]);
            const isExistDataFavoritesServer = dataRequestFavorite?.favoriteInstruments.length > 0;

            if (isExistDataFavoritesServer) {
                const settings: Partial<State> = {
                    favoriteInstruments: dataRequestFavorite.favoriteInstruments,
                };
                yield put(settingSlice.actions.setSettings(settings));
            }

            const settings: Partial<State> = {
                riskManagementInbox: dataRequest.NotificationRiskManagementInbox ?? false,
                riskManagementEmail: dataRequest.NotificationRiskManagementEmail ?? false,
                settlementsInbox: dataRequest.NotificationSettlementsInbox ?? false,
                settlementsEmail: dataRequest.NotificationSettlementsEmail ?? false,
                systemInbox: dataRequest.NotificationSystemInbox ?? false,
                systemEmail: dataRequest.NotificationSystemEmail ?? false,
                showRealTimeNotification: dataRequest.ShowRealTimeNotification ?? false,
                enableSound: dataRequest.EnableSound ?? false,
            };
            yield put(settingSlice.actions.setSettings(settings));

            yield put(
                setIsLoadingApp({
                    key: "sagaLoadSettings",
                    isLoading: false,
                }),
            );

            yield take(loadSettings);
        }
    } catch (e) {
        console.error(e);

        yield put(
            setIsLoadingApp({
                key: "sagaLoadSettings",
                isLoading: false,
            }),
        );
    }
}

export function* sagaSettings() {
    yield takeEvery(settingSlice.actions.setSetting, sagaSaveSettings);
    yield takeEvery(settingSlice.actions.setFavorites, sagaSaveFavorites);
}
