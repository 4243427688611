import { PSBold } from "feature/inboxPopover/styled";
import styled from "styled-components";
import { Settlement } from "./types";
import { useTransactionStatusStyles } from "./useTransactionStatusStyles";

const StatusContainer = styled.div<{ bgColor: string; color: string }>`
    background-color: ${(p) => p.bgColor};
    color: ${(p) => p.color};
    display: inline-flex;
    align-items: center;
    padding: 0 4px;
    border-radius: 4px;
`;
export const Status = ({ value, type }: { value: Settlement["status"]; type: Settlement["type"] }) => {
    const transactionsStyles = useTransactionStatusStyles(type);
    const settings = transactionsStyles[value];

    return (
        <StatusContainer bgColor={settings.bgColor} color={settings.color}>
            <PSBold>{settings.title}</PSBold>
        </StatusContainer>
    );
};
