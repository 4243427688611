import { getMatch } from "@fm-frontend/uikit";
import React, {
    MutableRefObject,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useTheme } from "styled-components";
import { IS_TEST_ENV, PINNED } from "const";
import { CoinIcon } from "components/CoinIcon";
import { IconCancel, IconCheckmarkCurrent, IconChevronRight, IconSearch, IconSelector, IconStar14 } from "components/icons";
import { Popover } from "components/Popover";
import { DummyButton } from "feature/form/Buttons";
import { useClickOutside } from "hooks/useClickOutside";
import { DropdownDirection, useDropdownDirection } from "hooks/useDropdownDirection";
import { useIsStarred } from "hooks/useIsStarred";
import { useSelector } from "hooks/useSelector";
import { TransitionState, useItemTransition } from "hooks/useTransition";
import { SelectedAssetContext } from "pages/trade/trading/selectedAsset";
import { Spacer } from "style";
import { TradingAsset } from "types";
import {
    Contents,
    Line,
    List,
    ListBanner,
    ListContainer,
    ListItem,
    ListSectionLabel,
    ListSeparatorItem,
    MatchedPart,
    SearchSelectContainer,
} from "./style";

export const ListSectionSeparator = () => {
    return (
        <ListSeparatorItem>
            <Line />
        </ListSeparatorItem>
    );
};

export const ButtonPinned = ({ instrument }: { instrument: string }) => {
    const { isStarred, starredHandler } = useIsStarred(instrument);
    return (
        <DummyButton onClick={starredHandler}>
            <IconStar14 isActive={isStarred} />
        </DummyButton>
    );
};

type Item = { title: string; icon?: ReactNode; children?: string[] | { base: string[]; quote: string[] } };

type ItemList = Item[][];

const SecondaryPopover = ({
    transitionState,
    horizontal,
    vertical,
    items,
    popoverRef,
    isCompact,
    onSelect,
    currentSubgroup,
    currentGroup,
}: {
    transitionState: TransitionState;
    items: string[] | { title: string; match: ReactNode }[] | { base: string[]; quote: string[] };
    popoverRef?: MutableRefObject<any>;
    isCompact?: true;
    onSelect: (asset: TradingAsset, instrument: string) => void;
    currentSubgroup?: "base" | "quote";
    currentGroup?: string;
} & Partial<DropdownDirection>) => {
    const theme = useTheme();
    const isMobile = useSelector((state) => state.app.isMobile);
    const { instrument } = useContext(SelectedAssetContext);
    const content = useMemo(() => {
        if (Array.isArray(items)) {
            const contentByItems = items.map((item) => {
                const title = typeof item === "string" ? item : item.title;
                return (
                    <Item
                        key={title}
                        isCompact={isCompact}
                        type="base"
                        title={title}
                        onSelect={onSelect}
                        parent={currentGroup}
                        match={typeof item === "string" ? undefined : item.match}
                        isCurrent={currentSubgroup && item === instrument}
                    />
                );
            });
            if (contentByItems.length === 0) return null;
            return contentByItems;
        }
        const contentByItems = [
            ...(items.base.length
                ? [
                      <ListSectionLabel key="label-base" isCurrent={currentSubgroup === "base"}>
                          IS <mark>BASE-</mark>
                          {currentSubgroup === "base" && <IconCheckmarkCurrent color={theme.colors.brand100} />}
                      </ListSectionLabel>,
                      <ListSectionSeparator key={`separator-base`} />,
                  ]
                : []),
            ...items.base.map((item) => (
                <Item
                    key={item}
                    isCompact={isCompact}
                    title={item}
                    type="quote"
                    onSelect={onSelect}
                    isCurrent={currentSubgroup && item === instrument}
                />
            )),
            ...(items.quote.length
                ? [
                      <ListSectionLabel key="label-quote" isCurrent={currentSubgroup === "quote"}>
                          IS <mark>-QUOTE</mark>
                          {currentSubgroup === "quote" && <IconCheckmarkCurrent color={theme.colors.brand100} />}
                      </ListSectionLabel>,
                      <ListSectionSeparator key={`separator-quote`} />,
                  ]
                : []),
            ...items.quote.map((item) => (
                <Item
                    key={item}
                    isCompact={isCompact}
                    title={item}
                    type="base"
                    onSelect={onSelect}
                    isCurrent={currentSubgroup && item === instrument}
                />
            )),
        ];
        if (contentByItems.length === 0) return null;
        return contentByItems;
    }, [items, currentSubgroup, currentGroup, instrument, onSelect, isCompact]);
    return (
        <ListContainer
            isCompact={isCompact}
            ref={popoverRef}
            horizontal={horizontal || "right"}
            vertical={vertical || "center"}
            transitionState={transitionState}
            isMobile={isMobile}
            hasHeader={!Array.isArray(items)}
        >
            {content ? (
                <List>{content}</List>
            ) : (
                <ListBanner>
                    <header>Asset code not found</header>
                    <div>Please, use asset code like “BTC” or “ETH”.</div>
                </ListBanner>
            )}
        </ListContainer>
    );
};

const Item = ({
    title,
    icon,
    children,
    isSelected,
    onHover,
    popoverRef,
    onSelect,
    isCurrent,
    type,
    isCompact,
    parent,
    match,
}: Item & {
    isSelected?: boolean;
    onHover?: () => void;
    popoverRef?: React.MutableRefObject<any>;
    onSelect: (asset: TradingAsset, instrument: string) => void;
    isCurrent?: boolean;
    type?: "base" | "quote";
    isCompact?: boolean;
    parent?: string;
    match?: ReactNode;
}) => {
    const ref = useRef(null);
    const { isActive, transitionState } = useItemTransition(isSelected, { enterDelay: 100, exitDelay: 100 });
    const childItems = useMemo(() => {
        if (Array.isArray(children)) return children.length;
        if (children) return children.base.length + children.quote.length;
        return 0;
    }, [children]);
    const coin = useMemo(() => {
        if (type) {
            const parts = title.split("-");
            return type === "base" ? parts[0] : parts[1];
        }
        return title;
    }, [title, type]);
    const { asset, isReversed } = useContext(SelectedAssetContext);
    const isCurrentItem = useMemo(() => {
        if (!type) return asset?.toLowerCase() === title.toLowerCase();
        return false;
    }, [asset]);
    return (
        <ListItem
            ref={ref}
            isSelected={isSelected || false}
            isCurrent={isCurrent || isCurrentItem}
            onMouseOver={onHover}
            onTouchEnd={onHover}
            onClick={() => {
                if (!type) return;
                if (parent?.toLowerCase() === PINNED) {
                    onSelect?.({ asset: PINNED, isReversed: false }, title);
                    return;
                }
                const parts = title.split("-");
                const reversed = type === "base";
                onSelect?.({ asset: parts[reversed ? 1 : 0], isReversed: reversed }, title);
            }}
            isCompact={isCompact}
        >
            {icon || <CoinIcon coin={coin} />}
            {match ? <MatchedPart>{match}</MatchedPart> : <span>{title}</span>}
            <Spacer />
            <span>
                {children ? (
                    <>
                        {childItems} <IconChevronRight />
                    </>
                ) : (
                    <ButtonPinned instrument={title} />
                )}
            </span>
            {isActive && children && (
                <Popover containerRef={ref}>
                    <SecondaryPopover
                        isCompact
                        transitionState={transitionState}
                        items={children}
                        popoverRef={isSelected ? popoverRef : undefined}
                        onSelect={onSelect}
                        currentSubgroup={isCurrentItem ? (isReversed ? "quote" : "base") : undefined}
                        currentGroup={title}
                    />
                </Popover>
            )}
        </ListItem>
    );
};

const PrimaryPopover = ({
    transitionState,
    horizontal,
    vertical,
    items,
    popoverRef,
    popoverSubRef,
    onSelect,
    onItemHover,
}: {
    transitionState: TransitionState;
    items: ItemList;
    popoverRef: React.MutableRefObject<any>;
    popoverSubRef: React.MutableRefObject<any>;
    onSelect: (asset: TradingAsset, instrument: string) => void;
    onItemHover?: (item: string) => void;
} & DropdownDirection) => {
    const [selectedItem, setSelectedItem] = useState("");
    const isMobile = useSelector((state) => state.app.isMobile);
    const content = useMemo(() => {
        return items.reduce<ReactNode[]>((acc, itemList, groupIdx) => {
            if (groupIdx > 0) acc.push(<ListSectionSeparator key={`separator-${groupIdx}`} />);
            acc.push(
                ...itemList.map((item, idx) => {
                    const itemIdx = `${groupIdx}-${idx}`;
                    return (
                        <Item
                            key={itemIdx}
                            {...item}
                            popoverRef={popoverSubRef}
                            isSelected={selectedItem === itemIdx}
                            onHover={() => {
                                setSelectedItem(itemIdx);
                                onItemHover?.(item.title);
                            }}
                            onSelect={onSelect}
                        />
                    );
                }),
            );
            return acc;
        }, []);
    }, [items, selectedItem]);
    return (
        <ListContainer
            horizontal={horizontal}
            vertical={vertical}
            transitionState={transitionState}
            isMobile={isMobile}
            ref={popoverRef}
        >
            <List>{content}</List>
        </ListContainer>
    );
};

export const SearchSelect: React.FC<{
    items: ItemList;
    searchItems: string[];
    ["data-test-id"]?: string;
    onItemHover?: (item: string) => void;
    onSelect: (asset: TradingAsset, instrument: string) => void;
    selectedOption?: string;
}> = ({ items, "data-test-id": testId, onItemHover, searchItems, onSelect, selectedOption }) => {
    const [isOpen, setIsOpen] = useState(false);
    const asset = useMemo(() => (selectedOption ? selectedOption.split("-")[0] : undefined), [selectedOption]);
    const placeholder = useMemo(() => {
        if (asset && !isOpen) {
            return asset;
        }
        return isOpen ? "Search assets..." : "Select pairs";
    }, [asset, isOpen]);

    const [query, setQuery] = useState("");
    const searchResults = useMemo(() => {
        if (!query) return null;
        const normalizedQuery = query.toLowerCase().trim();

        return searchItems.sort().reduce<{ title: string; match: ReactNode }[]>((acc, item) => {
            const match = getMatch(item, normalizedQuery);
            if (match) acc.push({ title: item, match });
            return acc;
        }, []);
    }, [query, searchItems]);
    useEffect(() => {
        if (!isOpen) {
            setQuery("");
            onItemHover?.("");
        }
    }, [isOpen]);

    const onSelectOption = useCallback(
        (tradingAsset: TradingAsset, instrument: string) => {
            onSelect(tradingAsset, instrument);
            setIsOpen(false);
            setQuery("");
        },
        [onSelect, setIsOpen, setQuery],
    );

    const ref = useRef(null);
    const popoverRef = useRef(null);
    const popoverSubRef = useRef(null);
    useClickOutside(() => setIsOpen(false), ref, popoverRef, popoverSubRef);

    const { isActive, transitionState } = useItemTransition(isOpen, { enterDelay: 100, exitDelay: 100 });
    const { vertical } = useDropdownDirection(ref);

    return (
        <SearchSelectContainer ref={ref} data-test-id={IS_TEST_ENV ? `select-${testId || "search"}` : undefined}>
            <Contents isListOpen={isOpen} isActive={!isOpen && Boolean(selectedOption)} hasQuery={Boolean(query)}>
                <IconSearch />
                <input
                    type="search"
                    value={query}
                    onChange={(e) => {
                        setQuery(e.currentTarget.value);
                    }}
                    placeholder={placeholder}
                    onFocus={() => {
                        setIsOpen(true);
                    }}
                    spellCheck={false}
                />
                {!isOpen && Boolean(selectedOption) && <IconSelector />}
                <DummyButton
                    onClick={(e) => {
                        e.preventDefault();
                        setQuery("");
                    }}
                >
                    <IconCancel />
                </DummyButton>
            </Contents>
            {isActive && (
                <Popover containerRef={ref}>
                    {searchResults ? (
                        <SecondaryPopover
                            horizontal={"center"}
                            vertical={vertical}
                            transitionState={transitionState}
                            items={searchResults}
                            popoverRef={popoverRef}
                            onSelect={onSelectOption}
                        />
                    ) : (
                        <PrimaryPopover
                            horizontal={"center"}
                            vertical={vertical}
                            transitionState={transitionState}
                            items={items}
                            popoverRef={popoverRef}
                            popoverSubRef={popoverSubRef}
                            onSelect={onSelectOption}
                            onItemHover={onItemHover}
                        />
                    )}
                </Popover>
            )}
        </SearchSelectContainer>
    );
};
