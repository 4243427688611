import {
    ClientId,
    CounterpartyIcon,
    DropdownOption,
    getMatch,
    getMatchStartsWith,
    HStack,
    P,
} from "@fm-frontend/uikit";
import React from "react";
import styled from "styled-components";

const Container = styled(HStack)`
    align-items: center;
    gap: 6px;
    overflow: hidden;
`;

type CounterpartiesOptionEssenceProps = {
    option: DropdownOption<number | string> | undefined;
    query?: string;
    groupingOption?: boolean;
};

export const CounterpartiesOptionEssence: React.FC<CounterpartiesOptionEssenceProps> = ({
    option,
    groupingOption,
    query = "",
}) => {
    if (!option) {
        return <></>;
    }

    return (
        <Container>
            {!groupingOption && <CounterpartyIcon name={option.text} size={20} />}
            <P ellipsis>{getMatch(option.text, query) ?? option.text}</P>
            {!groupingOption && (
                <ClientId id={getMatchStartsWith(String(option.value), query) ?? option.value} tooltip={null} />
            )}
        </Container>
    );
};
