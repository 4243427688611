import { ClientId, ELLIPSIS_CSS, HStack, PBold } from "@fm-frontend/uikit";
import { EmDash } from "const";
import React from "react";
import styled from "styled-components";
import { useCpInfoHelpers } from "hooks/useCpInfoHelpers";
import { CopyCell } from "../CopyCell";

const CpName = styled(PBold)`
    ${ELLIPSIS_CSS};
`;

type CpIdColumnProps = {
    cpId?: number;
};

export const CpIdCell: React.FC<CpIdColumnProps> = ({ cpId }) => {
    const { getCpName } = useCpInfoHelpers();

    if (cpId === undefined || cpId === 0) {
        return <>{EmDash}</>;
    }

    const name = getCpName(cpId, "full");

    return (
        <HStack spacing={6}>
            <CpName title={name}>{name}</CpName>
            <CopyCell content={cpId}>
                <ClientId id={cpId} />
            </CopyCell>
        </HStack>
    );
};
