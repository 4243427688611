import { ClientId, HStack, PBold, Skeleton } from "@fm-frontend/uikit";
import { useSelector } from "hooks";

export const CpInfo = ({ cpId }: { cpId: number }) => {
    const allAvailableCounterpartiesInfo = useSelector((state) => state.app.allAvailableCounterpartiesInfo);
    const cpName = allAvailableCounterpartiesInfo[cpId]?.username;

    return (
        <HStack spacing={6}>
            {cpName !== undefined ? <PBold>{cpName}</PBold> : <Skeleton height="18px" width="40px" />}
            <ClientId id={cpId} />
        </HStack>
    );
};
