import {
    Checkbox,
    FieldGroup,
    Form,
    H2,
    HStack,
    Modal,
    P,
    PlainButton,
    PrimaryButton,
    PSmall,
    SimpleInput,
    Stack,
    SwitchField,
    Tab,
    TabContext,
    TabList,
    TabPanel,
    VStack,
} from "@fm-frontend/uikit";
import { ValueParse } from "@fm-frontend/utils";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { CounterpartiesMultipleGroupedDropdown } from "components/customMultipleDropdowns/CounterpartiesMultipleGroupedDropdown";
import { InstrumentsMultipleGroupedDropdown } from "components/customMultipleDropdowns/InstrumentsMultipleGroupedDropdown";
import { InfoBox } from "components/InfoBox";
import { FIXED_MODAL_STYLE } from "const/modal";
import { createNotification } from "feature/app";
import { useFilteredLimits } from "hooks/useFilteredLimits";
import { useWithConfirmationModalDecorator } from "hooks/useWithConfirmationModalDecorator";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setCLimit } from "services/limitService";
import { useExtendedCurrencies } from "store/useExtentedCurrencies";
import styled from "styled-components";
import { CounterpartyLimitIndex } from "types";
import { UpdateMarkupResponse } from "../../types";
import { useCounterpartiesWithTypes } from "../IntsrumentsTable/hooks";
import { useNotMakerCpsIds } from "../IntsrumentsTable/hooks/useCounterpartiesWithTypes";
import { InstrumentTableData } from "../IntsrumentsTable/types";
import { calculateDefaultMarkups } from "../IntsrumentsTable/utils";
import { SetupModalSettings } from "../MakerMasterInstrumentsTabContent";
import { defaultMarkupSchema, markupSchema } from "./schema";
import { convertToRequestBodies, getMarkupsCount, updateMarkups } from "./utils";

enum Tabs {
    Default = "default",
    PerInstrument = "per_instrument",
}

const TabContainer = styled(TabContext<Tabs>)`
    gap: 8px;
`;

const Strong = styled.span`
    color: ${(p) => p.theme.colors.brand100};
`;

const StyledInfoBox = styled(InfoBox)`
    margin: 0 12px 8px 12px;
`;

const FadedText = styled(PSmall)`
    color: ${(p) => p.theme.colors.ui72};
`;

const Error = styled.div`
    margin-top: auto;
    text-align: center;
    width: 100%;
    font-size: 14px;
    padding-top: 8px;
    color: ${(p) => p.theme.colors.negative100};
`;

export type InstrumentMarkupInputs = {
    instruments: string[];
    cps: number[];
    enableMarkups: boolean;
    bidMarkup: string | number;
    askMarkup: string | number;
    defaultMarkup: string | number;
    applySameMarkups: boolean;
};

type Props = {
    onClose: () => void;
    onUpdate: (result: UpdateMarkupResponse[]) => Promise<void>;
    initialSetupModalSettings?: SetupModalSettings;
    instrumentsData: InstrumentTableData[];
};

export const InstrumentMarkupsSetupModal: React.FC<Props> = ({
    onClose,
    onUpdate,
    instrumentsData,
    initialSetupModalSettings: { defaultValues = {}, isPerInstruments = false, isParticularMarkup = false } = {},
}) => {
    const dispatch = useDispatch();
    const { data: limits, mutate } = useFilteredLimits();
    const { currencyGroups } = useExtendedCurrencies();
    const { counterparties: cpsWithTypes } = useCounterpartiesWithTypes();
    const notMakerCpsIds = useNotMakerCpsIds();
    const [tab, setTab] = useState<Tabs>(isPerInstruments ? Tabs.PerInstrument : Tabs.Default);
    const [isSameMarkups, setIsSameMarkups] = useState(false);
    const [apiError, setApiError] = useState<string | null>(null);

    const onCloseWithConfirmationModal = useWithConfirmationModalDecorator({
        title: "Discard Changes?",
        description:
            "Are you sure you want to close without saving changes? Any unsaved data will be lost.",
        confirmButtonTitle: "Yes, discard",
        cancelButtonTitle: "Cancel",
        onConfirm: onClose,
    });

    const { cps, instruments: instrumentsConfig } = defaultValues;
    const defaultEnableMarkups =
        defaultValues?.askMarkup !== undefined &&
        defaultValues?.bidMarkup !== undefined;
    const {
        control,
        formState: { isSubmitting, errors, isDirty },
        watch,
        handleSubmit,
        register,
        setValue,
    } = useForm<InstrumentMarkupInputs>({
        mode: "onSubmit",
        defaultValues: {
            enableMarkups: defaultEnableMarkups,
            ...defaultValues,
            cps,
            instruments: instrumentsConfig,
        },
        resolver: (...props) => {
            if (tab === Tabs.Default) {
                return yupResolver(defaultMarkupSchema)(...props);
            }
            if (tab === Tabs.PerInstrument) {
                return yupResolver(markupSchema)(...props);
            }
            return Promise.reject();
        },
    });
    const handleClose = () => onCloseWithConfirmationModal(isDirty);

    const selectedCps = watch("cps");
    const selectedInstruments = watch("instruments");
    const bidMarkup = watch("bidMarkup");
    const enableMarkups = watch("enableMarkups");

    useEffect(() => {
        const { askMarkup: defaultAskMarkup, bidMarkup: defaultBidMarkup, defaultMarkup: defaultDefaultMarkup } = calculateDefaultMarkups({
            selectedInstruments,
            selectedCps,
            instrumentsData,
        });
        setValue("askMarkup", defaultAskMarkup ?? "");
        setValue("bidMarkup", defaultBidMarkup ?? "");
        setValue("defaultMarkup", defaultDefaultMarkup ?? "");
        setIsSameMarkups(defaultAskMarkup === defaultBidMarkup);
    }, [selectedCps, selectedInstruments, currencyGroups, instrumentsData, setValue]);

    useEffect(() => {
        if (isSameMarkups) {
            setValue("askMarkup", bidMarkup);
        }
    }, [bidMarkup, isSameMarkups, setValue]);

    const touchedMarkupsCount = useMemo(
        () =>
            getMarkupsCount({
                selectedCps,
                selectedInstruments,
            }),
        [selectedCps, selectedInstruments],
    );

    const onSubmit = async (data: InstrumentMarkupInputs) => {
        const { defaultMarkup, ...markupData } = data;

        try {
            setApiError(null);

            if (tab === Tabs.Default) {
                if (!limits) {
                    return;
                }

                await Promise.all(selectedCps.map((counterpartyId) => {
                    const limit = limits.find((l) => l[CounterpartyLimitIndex.counterpartyId] === counterpartyId);

                    if (limit) {
                        return setCLimit({
                            counterpartyId: limit[CounterpartyLimitIndex.counterpartyId],
                            currency: limit[CounterpartyLimitIndex.currencyName],
                            grossLimit: limit[CounterpartyLimitIndex.grossLimit],
                            maintenanceMargin: limit[CounterpartyLimitIndex.maintenanceMargin],
                            restrictedTrading: limit[CounterpartyLimitIndex.marginCall],
                            initialMargin: limit[CounterpartyLimitIndex.initialMargin],
                            takerMarkup: ValueParse.percent(String(defaultMarkup)),
                        });
                    }
                }));

                mutate(limits.map((item) => {
                    if (selectedCps.includes(item[CounterpartyLimitIndex.counterpartyId])){
                        item[CounterpartyLimitIndex.takerMarkup] = ValueParse.percent(String(defaultMarkup));
                    }

                    return item;
                }, limits));
            }

            if (tab === Tabs.PerInstrument) {
                const requestsBodies = convertToRequestBodies(markupData);
                const result = await updateMarkups(requestsBodies);
                await onUpdate(result);
            }

            onClose();
        } catch (err) {
            setApiError(String(err));
            dispatch(
                createNotification({
                    type: "error",
                    content: String(err),
                }),
            );
        }
    };

    const title = isParticularMarkup ? (
        <>
            <Strong>{selectedInstruments[0]}</Strong> for{" "}
            {cpsWithTypes[Number(selectedCps[0])]?.name}
        </>
    ) : (
        "Configure markups"
    );

    return (
        <Modal isOpen onClose={handleClose} style={FIXED_MODAL_STYLE}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <VStack minWidth="360px" asCard>
                    <VStack padding={12}>
                        <H2>{title}</H2>
                    </VStack>
                    <StyledInfoBox title={<P>How <span>Markups</span> work?</P>}>
                        <P>
                            <span>Use default markup for broad consistency, and instrument markup for precise control over specific pairs to meet unique trading needs.</span>
                        </P>
                        <P>
                            <span>Default markup</span> is a standard rate applied to all trading pairs, ensuring consistent markups across all pairs, including new ones. This rate can be zero or even negative.
                        </P>
                        <P>
                            Enable <span>Instrument markup</span> to set custom rates for specific instruments, overriding the default markup. You can tailor different bid and ask quotes for individual instruments or groups like fiat&lt;&gt;crypto.
                        </P>
                    </StyledInfoBox>
                    <FieldGroup direction="column" paddingX={12} paddingBottom={20}>
                        <Controller
                            control={control}
                            render={({ field }) => (
                                <CounterpartiesMultipleGroupedDropdown
                                    values={field.value}
                                    onChange={field.onChange}
                                    cpsIds={notMakerCpsIds}
                                />
                            )}
                            name="cps"
                        />
                    </FieldGroup>
                    <TabContainer value={tab} handleClick={setTab}>
                        <Stack paddingX={12}>
                            <TabList>
                                <Tab value={Tabs.Default} title="Default markup" />
                                <Tab value={Tabs.PerInstrument} title="Per instrument" />
                            </TabList>
                        </Stack>
                        <TabPanel value={Tabs.Default}>
                            <FieldGroup direction="column" paddingX={12}>
                                <SimpleInput
                                    label="Default markup"
                                    placeholder="0.0000"
                                    {...register("defaultMarkup")}
                                    autoFocus
                                    error={errors.defaultMarkup?.message}
                                />
                            </FieldGroup>
                        </TabPanel>
                        <TabPanel value={Tabs.PerInstrument}>
                            <FieldGroup direction="column" paddingX={12} paddingBottom={8}>
                                <Controller
                                    control={control}
                                    render={({ field }) => (
                                        <InstrumentsMultipleGroupedDropdown
                                            caption="Target"
                                            values={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                    name="instruments"
                                />
                            </FieldGroup>
                            <FieldGroup direction="column" paddingX={12} paddingBottom={8}>
                                <SwitchField
                                    variant="simple"
                                    fullWidth
                                    text="Enable instrument markups"
                                    control={control}
                                    {...register("enableMarkups")}
                                />
                                {enableMarkups && (
                                    <>
                                        <SimpleInput
                                            label={isSameMarkups ? "Markup, %" : "Bid, %"}
                                            placeholder="0.0000"
                                            {...register("bidMarkup")}
                                            autoFocus
                                            error={errors.bidMarkup?.message}
                                        />
                                        {!isSameMarkups && (
                                            <SimpleInput
                                                label="Ask, %"
                                                placeholder="0.0000"
                                                {...register("askMarkup")}
                                                error={errors.askMarkup?.message}
                                            />
                                        )}
                                    </>
                                )}
                            </FieldGroup>
                            {enableMarkups && (
                                <HStack alignItems="center" spacing={8} paddingX={12}>
                                    <Checkbox
                                        id="sameMarkup"
                                        onChange={() => setIsSameMarkups(!isSameMarkups)}
                                        size="small"
                                        checked={isSameMarkups}
                                    />
                                    <label htmlFor="sameMarkup">
                                        <FadedText>Same markup for bid and ask</FadedText>
                                    </label>
                                </HStack>
                            )}
                        </TabPanel>
                    </TabContainer>
                    {apiError && <Error>{apiError}</Error>}
                    <VStack padding={12} paddingTop={24} spacing={10}>
                        <PrimaryButton
                            fullWidth
                            size="large"
                            type="submit"
                            disabled={touchedMarkupsCount === 0}
                            loading={isSubmitting}
                        >
                            {tab === Tabs.Default && `Apply ${selectedCps.length}`}
                            {tab === Tabs.PerInstrument && `Apply ${isParticularMarkup ? "" : touchedMarkupsCount}`}
                        </PrimaryButton>
                        <PlainButton fullWidth size="large" type="button" onClick={handleClose}>
                            Cancel
                        </PlainButton>
                    </VStack>
                </VStack>
            </Form>
        </Modal>
    );
};
