import React, { ReactNode } from "react";
import { Badge } from "../badges";
import { DelayMode } from "../Popover";

const DEFAULT_TOOLTIP = "Client ID";

export const ClientId: React.VFC<{ id: ReactNode; tooltip?: string | null; disabled?: boolean }> = ({
    id,
    tooltip = DEFAULT_TOOLTIP,
    disabled,
}) => {
    return <Badge value={id} disabled={disabled} tooltip={tooltip} delayMode={DelayMode.OnOpen} variant="clientId" />;
};
