import { Dispatch, SetStateAction, useCallback, useLayoutEffect, useState } from "react";
import { getLSValue, removeLSValue, setLSValue } from "utils/localStorage";
import { useSelector } from "./useSelector";

export const enum LS_VARIABLES {
    LIMITS_MAKERS_DASH_MESSAGE_DISMISSED = "limits-makers-dash-message-dismissed",
    TRADES_GROUPED_BY = "trades-grouped-by",
    POSITIONS_GROUP_BY = "positions-group-by",
    TESTING_TOOLS = "testing-tools",
    TRADING_RECENT = "trading-recent",
    API_INTEGRATION_REQUIRED = "api-integration-required",
    TnC_DELEGATED = "termsAndConditionsDelegated",
}

/**
 * Like useState, but saves the changes to localStorage
 * @param key localStorage key to write the data to
 * @param defaultValue value that's used if localStorage doesn't contain key yet
 * @return same signature as useState<V>(defaultValue)
 */
export function useLSState<V>(key: string, defaultValue: V): [state: V, setState: Dispatch<SetStateAction<V>>, deleteState: () => void] {
    const takeLSValue = () => {
        const value = getLSValue<V>(key);

        return value === null ? defaultValue : value;
    };

    const [workingKey, setWorkingKey] = useState(key);
    const [state, setState] = useState<V>(takeLSValue);
    const isActive = useSelector((rootState) => rootState.app.isActive);

    // store to ls after updating
    useLayoutEffect(() => {
        setLSValue(key, state);
    }, [state]);

    // pull from ls on regaining focus
    useLayoutEffect(() => {
        if (isActive) {
            setState(takeLSValue);
        }
    }, [isActive]);

    const deleteState = useCallback(
        () => {
            removeLSValue(key);
            setState(defaultValue);
        },
        [key],
    );

    // handle change of key and return actual state
    if (key !== workingKey) {
        const keyState = takeLSValue();

        setWorkingKey(key);
        setState(keyState);

        return [
            keyState,
            setState,
            deleteState,
        ];
    }

    return [
        state,
        setState,
        deleteState,
    ];
}
