import { post } from "api";
import { EMPTY_ARRAY } from "const";
import useSWR from "swr";
import { Deal } from "types";
import { displayError } from "utils";
import { useIsPrimeBrokerUser, usePrimeBrokerViewType } from "./hooks";

type DealHistoryParams = {
    counterpartyIds?: number | number[];
    instrument?: string | string[];
    filter?: string;
    till?: number;
    from?: number;
    to?: number;
    limit?: number;
};

const DEAL_HISTORY_API_METHOD = "dealHistory";
const DEAL_MOMENT_INDEX = 10;

export const useLastTradesSWRKey = (limit: number) => {
    const sideKey = usePrimeBrokerViewType();

    return `${sideKey}-${limit}`;
};

export const useDealHistoryParams = (params: DealHistoryParams) => {
    const isPrimeBrokerUser = useIsPrimeBrokerUser();
    const primeBrokerViewType = usePrimeBrokerViewType();
    const side = isPrimeBrokerUser ? primeBrokerViewType : "counterparties";

    return {
        filter: side === "counterparties" ? "external" : "subaccounts",
        limit: 250,
        ...params,
    };
};

export const historicalTradesFetcher = (requestParams: DealHistoryParams) =>
    post(DEAL_HISTORY_API_METHOD, requestParams).then(
        (data: Deal[]) => data.sort((a, b) => b[DEAL_MOMENT_INDEX] - a[DEAL_MOMENT_INDEX]),
    );

export const useHistoricalTrades = (params: DealHistoryParams) => {
    const requestParams = useDealHistoryParams(params);

    const { data: deals = EMPTY_ARRAY as Deal[], isLoading, isValidating, mutate } = useSWR<Deal[]>(
        requestParams,
        historicalTradesFetcher,
        {
            onError: displayError,
            fallbackData: EMPTY_ARRAY as Deal[],
            revalidateOnMount: true,
            revalidateOnFocus: false,
        },
    );

    return {
        deals,
        isLoading,
        isValidating,
        mutate,
    };
};
