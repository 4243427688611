import { CompactSelectActivator } from "components/select/CompactSelect";
import { onHover, onMobile, onTablet } from "const/index";
import { DummyButton } from "feature/form/Buttons";
import { List, ListContainer, ListItem } from "feature/form/FormSelect";
import { TableContainer } from "feature/table";
import { StyledTableFlex, TableFlexBody, TableFlexCell, TableFlexRow } from "feature/table/style";
import { cover, ellipsis } from "polished";
import { selectors } from "style/theme";
import styled, { css } from "styled-components";

export const FormContainer = styled.section`
    display: flex;
    overflow-x: auto;
    scrollbar-width: none;
    max-width: calc(100vw - 194px);
    align-items: flex-start;
    min-width: 100%;
    min-height: 274px;
    padding: 2px;
    margin: -2px;
    margin-bottom: 8px;

    ::-webkit-scrollbar {
        height: 6px;
        margin: 2px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
        margin: 10px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 6px;
        background: ${(p) => p.theme.colors.selectBG};
        &:hover {
            background: ${(p) => p.theme.colors.selectBgHover};
        }
    }
    & > div {
        position: relative;
    }
    form {
        margin-right: 8px;
        margin-bottom: 8px;
        & > div > button {
            width: 100%;
        }
    }
    ${onMobile} {
        padding-left: 12px;
    }
`;

export const Header = styled.header`
    ${selectors.mixinHeader2}
    display: flex;
    margin: 10px 12px 8px;
    img {
        margin-right: 4px;
    }
    span {
        white-space: nowrap;
    }
`;

export const EmptyThing = styled.div`
    height: 1px;
    width: 4px;
    pointer-events: none;
    user-select: none;
`;

export const BannerContent = styled.div`
    height: 268px;
    text-align: center;
    a {
        pointer-events: all;
        color: ${selectors.brandColor};
    }
`;

export const MarketDepthContainer = styled.div`
    display: block;
    overflow: auto;
    ${TableContainer} {
        margin-bottom: 16px;
    }
    ${TableFlexRow} {
        height: 28px;
        display: flex;
        flex: 1 0 auto;
        min-width: 590;
        ${TableFlexCell}:nth-child(1) {
            padding-left: 4px;
        }
        ${TableFlexCell}:nth-child(2), ${TableFlexCell}:nth-child(3) {
            padding-right: 1px;
            padding-left: 1px;
        }
        ${TableFlexCell}:nth-child(4) {
            padding-right: 4px;
        }
    }
    ${TableFlexBody} {
        ${TableFlexRow} {
            &:before {
                position: absolute;
                pointer-events: none;
                background-color: rgba(50, 149, 136, 0.08);
                content: "";
                width: calc(50% - 1px);
                height: 100%;
                left: 0;
                transform-origin: center right;
                transform: scaleX(var(--ask-percent, 0));
                transition: transform ${(p) => p.theme.transitions.default};
            }
            &:after {
                position: absolute;
                pointer-events: none;
                background-color: rgba(254, 24, 51, 0.08);
                content: "";
                width: calc(50% - 1px);
                height: 100%;
                right: 0;
                transform-origin: center left;
                transform: scaleX(var(--bid-percent, 0));
                transition: transform ${(p) => p.theme.transitions.default};
            }
        }
        ${TableFlexRow}:first-of-type {
            ${TableFlexCell}:nth-child(2),
        ${TableFlexCell}:nth-child(3) {
                ${selectors.fontMedium}
            }
        }
    }
    .marketDepthIndicator {
        ${cover()}
        ${(p) => p.theme.mixins.nonInteractive}
        opacity: 0.08;
        opacity: 0;
        border-bottom: 0;
        margin: 0;
        transition: background ${(p) => p.theme.transitions.default};
    }
    ${StyledTableFlex} {
        height: 308px;
    }
`;

export const Cluster = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: baseline;
    & > * {
        overflow: hidden;
        margin-right: 8px;
        flex: 1 1 590px;
    }

    & > div:first-child {
        margin-bottom: 16px;
    }

    ${TableContainer} {
        min-width: unset;
    }
`;

export const Spread = styled.div`
    ${(p) => p.theme.mixins.body2}
    font-weight: 600;
    background: ${(p) => p.theme.colors.activeBG};
    color: ${(p) => p.theme.colors.textSubdued};
    padding: 4px 8px;
    border: 1px solid #e6e6e6;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    font-variant: tabular-nums;
    mark {
        color: ${({ theme }) => theme.colors.textFG};
    }
`;

export const CoinSelector = styled.div`
    ${CompactSelectActivator} {
        font-size: 24px;
        line-height: 26px;
        font-weight: 400;
        letter-spacing: -0.02em;
        padding: 8px;
    }
    ${ListContainer}, ${ListItem}, ${List} {
        max-width: 50vw;
    }
    ${ListItem} {
        padding-left: 38px;
        position: relative;
        img {
            height: 16px;
            width: 16px;
            position: absolute;
            left: 16px;
            top: 10px;
        }
    }
    ${onMobile} {
        ${CompactSelectActivator} {
            ${ellipsis("30vw")}
        }
    }
`;

export const PrependContainer = styled.div`
    button + button {
        margin-left: 6px;
    }
`;

export const NavigationContainer = styled.section<{ isMaker?: boolean }>`
    display: flex;
    overflow: scroll;
    white-space: nowrap;
    margin-left: -12px;
    ${DummyButton}:first-of-type {
        margin-left: 20px;
    }
    ${DummyButton} + ${DummyButton} {
        margin-left: 8px;
    }
    ${onMobile} {
        max-width: unset;
        margin-left: 8px;
        flex: 1;
        & > div,
        & > div > div,
        input {
            min-width: 100%;
        }
    }
    ${onTablet} {
        button:nth-of-type(3),
        button:nth-of-type(4) {
            display: none;
        }
    }
`;

export const PillContainer = styled.article<{ isActive?: boolean }>`
    padding: 10px 12px;
    border-radius: 8px;
    ${(p) => p.theme.mixins.pSmall};
    line-height: 20px;
    color: ${(p) => (p.isActive ? p.theme.colors.uiWhite100 : p.theme.colors.textFG)};
    background: ${(p) => (p.isActive ? p.theme.colors.brand : "rgba(36, 48, 52, 0.08)")};

    user-select: none;
    cursor: pointer;
    svg {
        margin-bottom: -2px;
    }
    ${(p) =>
        p.isActive &&
        css`
            svg path {
                fill: rgba(255, 255, 255, 0.75);
            }
        `}
    ${onHover} {
        background-color: ${({ theme: { colors }, isActive }) =>
            isActive ? colors.brand : colors.selectBgHover};
    }
`;
