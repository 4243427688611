import { ErrorBoundary } from "components/ErrorBoundary";
import { PINNED } from "const";
import { useInstruments } from "hooks";
import { EventContext, useEventEmitter } from "hooks/useEventEmitter";
import { useSelector } from "hooks/useSelector";
import { useCallback, useEffect, useMemo } from "react";
import { useInstrumentBook } from "store/hooks";
import { Page, Spacer } from "style";
import { EMPTY_BOOK } from "./consts";
import { LastTrades } from "./LastTrades";
import { MarketDepth } from "./MarketDepth";
import { Positions } from "./Positions";
import { DEFAULT_ASSET, DEFAULT_INSTRUMENT, SelectedAssetContext, useSelectedAsset } from "./selectedAsset";
import { Cluster, EmptyThing, FormContainer } from "./style";
import { TradeForm } from "./TradeForm";
import { TradingTopMenu } from "./TradingTopMenu";

const createGroup = () => ({ base: new Set<string>(), quote: new Set<string>() });

export const PageTrading = () => {
    const eventEmitter = useEventEmitter();
    const asset = useSelectedAsset();
    const { instruments } = useInstruments();
    const selectedBook = useInstrumentBook(asset.instrument ?? "");
    const searchList = useMemo(
        () => instruments.map((instrument) => instrument.instrumentName),
        [instruments],
    );
    const instrumentDict = useMemo(() => {
        const dict: { [key: string]: ReturnType<typeof createGroup> } = {};
        for (const instrument of instruments) {
            const { instrumentName, assetCurrency, balanceCurrency } = instrument;
            dict[assetCurrency] ??= createGroup();
            dict[assetCurrency].base.add(instrumentName);
            dict[balanceCurrency] ??= createGroup();
            dict[balanceCurrency].quote.add(instrumentName);
        }
        return Object.fromEntries(
            Object.entries(dict)
                .map<[string, { base: string[]; quote: string[]; title: string }]>(([title, { base, quote }]) => [
                    title,
                    { title, base: [...base].sort(), quote: [...quote].sort() },
                ])
                .sort((a, b) => a[0].localeCompare(b[0])),
        );
    }, [instruments]);
    const pinnedInstruments = useSelector(
        (state) => state.settings.favoriteInstruments,
    );
    const combinedDict: typeof instrumentDict = useMemo(
        () => ({ ...instrumentDict, [PINNED]: { title: "Pinned", base: pinnedInstruments, quote: [] } }),
        [pinnedInstruments, instrumentDict],
    );
    const collection = useMemo(
        () => combinedDict[asset.asset]?.[asset.isReversed ? "quote" : "base"] || [],
        [asset, combinedDict],
    );
    const resetErrors = useCallback(
        () => eventEmitter.emit?.({ type: "reset" }),
        [eventEmitter],
    );

    useEffect(() => {
        if (collection.length && !collection.includes(asset.instrument || "")) {
            asset.setInstrument(collection[0]);
        }
        if (!collection.length && asset.asset === PINNED) {
            asset.setAsset(DEFAULT_ASSET, DEFAULT_INSTRUMENT);
        }
    }, [collection, asset]);

    return (
        <Page>
            <EventContext.Provider value={eventEmitter}>
                <SelectedAssetContext.Provider value={asset}>
                    <TradingTopMenu instrumentDict={instrumentDict} searchList={searchList} />
                    <FormContainer>
                        {collection.map((instrument) => {
                            return (
                                <ErrorBoundary noUI key={instrument}>
                                    <TradeForm
                                        instrument={instrument}
                                        isInstrumentSelected={instrument === asset.instrument}
                                        selectedBook={selectedBook.data ?? EMPTY_BOOK}
                                        resetErrors={resetErrors}
                                    />
                                </ErrorBoundary>
                            );
                        })}
                        <Spacer>
                            <EmptyThing />
                        </Spacer>
                    </FormContainer>
                    <Cluster>
                        <div>
                            <ErrorBoundary noUI>
                                <MarketDepth selectedBook={selectedBook} selectedInstrument={asset.instrument} />
                            </ErrorBoundary>
                            <LastTrades />
                        </div>
                        <div>
                            <ErrorBoundary noUI>
                                <Positions />
                            </ErrorBoundary>
                        </div>
                    </Cluster>
                </SelectedAssetContext.Provider>
            </EventContext.Provider>
        </Page>
    );
};
