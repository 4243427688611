import { useEffect, useState } from "react";

export function useLockableState<T>(state: T, isLocked?: boolean): [T, React.Dispatch<React.SetStateAction<boolean>>] {
    const [lockedState, setLockedState] = useState(state);
    const [_isLocked, setIsLocked] = useState(isLocked || false);
    useEffect(() => {
        if ((typeof isLocked === "boolean" && !isLocked) || !_isLocked) {
            setLockedState(state);
        }
    }, [_isLocked, isLocked, state]);
    return [lockedState, setIsLocked];
}
