import {
    Center,
    ClientId,
    CounterpartyIcon,
    H2,
    HStack,
    Icons,
    PBold,
    PlainButton,
    PrimaryButton,
    PSmallBold,
    Textarea,
    VStack,
} from "@fm-frontend/uikit";
import { useFormCloseConfirmer, when } from "@fm-frontend/utils";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { CurrencyIcon } from "components/CurrencyIcon";
import { FormError } from "feature/form/style";
import { useFormHelpers } from "hooks";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTheme } from "styled-components";
import { addOrUpdateCryptoAddress, deleteAddress } from "../../api";
import { HintCell } from "../../components";
import { ContentCell } from "../../components/ContentCell";
import { useAddressDeleteConfirmation } from "../../hooks/useAddressDeleteConfirmation";
import { editCryptoAddressSchema } from "../../schema";
import { AddressEditInputs, CryptoAddress } from "../../types";
import {
    hasCurrencyMemoOrTag,
    parseCryptoAddress,
    PRIVATE_NOTE_HINT_TEXT,
    SAB_CRYPTO_EDIT_MODAL_KEY,
} from "../../utils";

type EditCryptoAddressModalProps = {
    address: CryptoAddress;
    onCancel: () => void;
    onEditSuccess: () => void;
};

export const EditCryptoAddressModal: React.FC<EditCryptoAddressModalProps> = ({ address, onCancel, onEditSuccess }) => {
    const { colors } = useTheme();
    const { wallet, currency, cpId, cpName, privateNote, memoOrTag } = parseCryptoAddress(address);

    const { error: apiError, setError: setApiError, isLoading, startLoading, stopLoading } = useFormHelpers();
    const memoOrTagRequired = useMemo(() => hasCurrencyMemoOrTag(currency), [currency]);

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isDirty },
    } = useForm<AddressEditInputs>({
        defaultValues: {
            wallet: wallet,
            privateNote: privateNote,
            memoOrTag: memoOrTag,
            memoOrTagRequired: memoOrTagRequired,
        },
        mode: "onSubmit",
        resolver: yupResolver(editCryptoAddressSchema),
    });

    useFormCloseConfirmer(SAB_CRYPTO_EDIT_MODAL_KEY, isDirty);
    const addressDeleteConfirmation = useAddressDeleteConfirmation();

    const onSubmit = async (inputs: AddressEditInputs) => {
        const updatedAddress = {
            ...address,
            wallet: inputs.wallet,
            privateNote: inputs.privateNote,
            memoOrTag: inputs.memoOrTag,
        };

        const apiErrorResponse = await addOrUpdateCryptoAddress(updatedAddress);

        setApiError(apiErrorResponse);

        if (apiErrorResponse === null) {
            onEditSuccess();
        }
    };

    const handleDeleteAddress = () =>
        addressDeleteConfirmation(async () => {
            if (address.accountId === undefined) {
                return;
            }

            startLoading();
            const apiErrorResponse = await deleteAddress(address.accountId, address.rule?.id);
            setApiError(apiErrorResponse);
            stopLoading();
            onEditSuccess();
        });

    return (
        <VStack minWidth="360px" asCard>
            <VStack padding={12}>
                <H2>Edit your address</H2>
            </VStack>
            <form>
                <VStack paddingBottom={12} paddingX={12} spacing={16}>
                    <VStack paddingX={12} asCard>
                        <ContentCell
                            paddingY={6}
                            spacing={4}
                            title="From"
                            content={cpName}
                            renderContent={() => (
                                <HStack spacing={4} alignItems="center">
                                    <CounterpartyIcon size={16} name={String(cpId)} />
                                    <PBold ellipsis>{cpName}</PBold>
                                    <ClientId id={cpId} />
                                </HStack>
                            )}
                        />
                        <ContentCell
                            paddingY={6}
                            spacing={4}
                            title="Asset"
                            content={currency}
                            renderContent={() => (
                                <HStack spacing={4} alignItems="center">
                                    <CurrencyIcon size={16} name={currency} />
                                    <PBold ellipsis>{currency}</PBold>
                                </HStack>
                            )}
                        />
                    </VStack>
                    <VStack spacing={4}>
                        <Textarea
                            label="Address"
                            placeholder="000x"
                            error={errors.wallet?.message}
                            {...register("wallet")}
                        />
                    </VStack>
                    <VStack spacing={4}>
                        <Textarea
                            label={<HintCell text="Private note" hint={PRIVATE_NOTE_HINT_TEXT} />}
                            height="93px"
                            placeholder="Private note"
                            error={errors.privateNote?.message}
                            {...register("privateNote")}
                        />
                    </VStack>
                    {when(
                        memoOrTagRequired,
                        <VStack spacing={4}>
                            <Textarea
                                label="Memo or tag"
                                placeholder="Memo or tag"
                                error={errors.memoOrTag?.message}
                                {...register("memoOrTag")}
                            />
                        </VStack>,
                    )}
                    {when(apiError, <FormError>{apiError}</FormError>)}
                </VStack>
            </form>
            <Center spacing={8} paddingBottom={16}>
                <PlainButton
                    size="small"
                    disabled={isSubmitting || isLoading}
                    loading={isLoading}
                    onClick={handleDeleteAddress}
                >
                    <Icons.Bin color={colors.negative100} />
                    <PSmallBold color={colors.negative100}>Delete address</PSmallBold>
                </PlainButton>
            </Center>
            <VStack paddingX={12} paddingBottom={16} spacing={8}>
                <PrimaryButton
                    fullWidth
                    size="large"
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    loading={isSubmitting}
                    disabled={!isDirty || isLoading}
                >
                    Save
                </PrimaryButton>
                <PlainButton fullWidth size="large" type="button" onClick={onCancel}>
                    Cancel
                </PlainButton>
            </VStack>
        </VStack>
    );
};
