import { Popover } from "@fm-frontend/uikit";
import { useSelector } from "hooks/useSelector";
import { useState } from "react";
import { InboxPopoverContent } from "./InboxPopoverContent/InboxPopoverContent";
import { NotificationsButton } from "./NotificationsButton/NotificationsButton";

export const InboxPopover = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { notifications, unreadCount } = useSelector((state) => state.wsNotifications);
    const closePopover = () => setIsOpen(false);

    return (
        <Popover
            isOpen={isOpen}
            padding={8}
            positions={["bottom"]}
            align="end"
            onClickOutside={closePopover}
            content={
                <InboxPopoverContent
                    closePopover={closePopover}
                    newEventsNumber={unreadCount}
                    notifications={notifications}
                />
            }
        >
            <NotificationsButton unreadNotificationsCount={unreadCount} onClick={() => setIsOpen(!isOpen)} />
        </Popover>
    );
};
