import { getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import { useEffect, useMemo } from "react";
import { TablePaginator } from ".";
import { Skeleton } from "../Skeleton";
import { TableProps } from "./common";
import { TableView } from "./TableView";

type PaginatorTableProps<T extends object> = {
    paginator?: {
        pageSize: number;
    } | null;
} & TableProps<T>;

const DEFAULT_PAGINATOR_OPTIONS = {
    pageSize: 12,
};

export function Table<T extends object = {}>({
    selectedRows = {},
    isLoading = false,
    tableOptions,
    onRowClick,
    className,
    paginator = DEFAULT_PAGINATOR_OPTIONS,
    onInitialize,
    renderEmptyState,
}: PaginatorTableProps<T>) {
    const pageSize = paginator?.pageSize ?? Infinity;
    const withPaginator = pageSize !== Infinity;
    const loadingSize = withPaginator ? paginator?.pageSize : DEFAULT_PAGINATOR_OPTIONS.pageSize;
    const { data, columns } = tableOptions;
    const memoizedData = useMemo(() => (isLoading ? Array(loadingSize).fill({}) : data), [data, isLoading]);
    const preparedColumns = useMemo(() => {
        if (isLoading) {
            return columns.map((column) => ({
                ...column,
                cell: () => <Skeleton height="16px" />,
            }));
        }

        return columns;
    }, [isLoading, columns]);
    const table = useReactTable({
        ...tableOptions,
        ...(withPaginator && {
            getPaginationRowModel: getPaginationRowModel(),
        }),
        data: memoizedData,
        columns: preparedColumns,
    });
    useEffect(() => {
        if (withPaginator) {
            table.setPageSize(pageSize);
        }
    }, [table, pageSize, withPaginator]);
    useEffect(() => {
        onInitialize?.(table);
    }, [table]);

    const paginatorVisible = withPaginator && table.getPageCount() > 1;
    const isEmpty = memoizedData.length === 0 && !isLoading;

    return (
        <>
            <TableView
                table={table}
                isEmpty={isEmpty}
                className={className}
                selectedRows={selectedRows}
                onRowClick={onRowClick}
                renderEmptyState={renderEmptyState}
            />
            {paginatorVisible && <TablePaginator table={table} />}
        </>
    );
}
